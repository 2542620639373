.section-dashboard {
  /*position: relative;*/
  background-color: #eff2f7;
  /*min-height: 100%;*/
  width: 100%;
  /*padding: 1rem;*/ }
  .section-dashboard .flex {
    min-height: 95vh;
    display: grid;
    grid-template-columns: 80px auto;
    gap: 0; }
    .section-dashboard .flex .sidebarContainer {
      position: relative; }
      .section-dashboard .flex .sidebarContainer .sidebar {
        position: fixed;
        background-color: #fff;
        /*box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);*/
        height: 100%;
        min-width: 80px;
        width: 80px;
        border-right: 1px solid #aac8e4;
        z-index: 1;
        -webkit-transition: all 0.35s ease-in-out;
        -o-transition: all 0.35s ease-in-out;
        transition: all 0.35s ease-in-out; }
        .section-dashboard .flex .sidebarContainer .sidebar:hover {
          width: 250px; }
        .section-dashboard .flex .sidebarContainer .sidebar .avoid-clicks {
          pointer-events: none; }
        .section-dashboard .flex .sidebarContainer .sidebar .optionItem {
          margin: 0;
          display: block;
          width: 100%;
          text-align: left;
          position: relative;
          background-color: white;
          border: none;
          height: 50px;
          padding: 0;
          border-radius: 0;
          cursor: pointer; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem:not(:last-child) {
            margin: 0 0 8px; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem:first-child {
            margin-top: 3.5rem; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem:hover {
            background-color: #d4efeb; }
            .section-dashboard .flex .sidebarContainer .sidebar .optionItem:hover i {
              background-color: #d4efeb; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem .muted {
            color: #bcbcbc; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem i {
            width: 79px;
            height: 50px;
            display: inline-block;
            position: absolute;
            /*background-color: white;*/
            z-index: 10;
            font-size: 1.5rem;
            line-height: 50px;
            padding-left: 25px; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem .iconBg {
            width: 70px;
            height: 50px;
            display: inline-block;
            position: absolute;
            background-color: white;
            z-index: 9; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem h3 {
            display: inline-block;
            margin-left: 20px;
            width: 150px;
            height: 50px;
            line-height: 50px;
            -webkit-transform: translateX(-100px);
                -ms-transform: translateX(-100px);
                    transform: translateX(-100px);
            -webkit-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            opacity: 0;
            font-family: 'GT America Standard Medium', sans-serif;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0; }
          .section-dashboard .flex .sidebarContainer .sidebar .optionItem:active, .section-dashboard .flex .sidebarContainer .sidebar .optionItem:focus {
            outline: none; }
            .section-dashboard .flex .sidebarContainer .sidebar .optionItem:active .iconBg, .section-dashboard .flex .sidebarContainer .sidebar .optionItem:focus .iconBg {
              background-color: #00b1a4;
              border-radius: 0 100px 100px 0; }
        .section-dashboard .flex .sidebarContainer .sidebar:hover .optionItem h3 {
          -webkit-transform: translateX(90px);
              -ms-transform: translateX(90px);
                  transform: translateX(90px);
          opacity: 1; }
        .section-dashboard .flex .sidebarContainer .sidebar .blockedOptions h3 {
          color: #a2a2a2; }
        .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option {
          background-color: #004dcf;
          color: #fff; }
          .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option .icon {
            /*background-color: $color-blue-light;*/
            color: #fff;
            background-color: #004dcf; }
          .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option i {
            background-color: #004dcf; }
          .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option:hover, .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option:focus {
            cursor: pointer;
            background-color: #004dcf;
            opacity: 0.8; }
            .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option:hover i, .section-dashboard .flex .sidebarContainer .sidebar .setup-wizard-option:focus i {
              background-color: #004dcf; }
        .section-dashboard .flex .sidebarContainer .sidebar .heartbeat {
          -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
          animation: heartbeat 1.5s ease-in-out infinite both; }
        .section-dashboard .flex .sidebarContainer .sidebar .activeOptionItem {
          cursor: pointer;
          /*background-color: $color-green-light;*/ }
          .section-dashboard .flex .sidebarContainer .sidebar .activeOptionItem i {
            color: white; }
          .section-dashboard .flex .sidebarContainer .sidebar .activeOptionItem .iconBg {
            background-color: #00b1a4;
            border-radius: 0 100px 100px 0; }
          .section-dashboard .flex .sidebarContainer .sidebar .activeOptionItem:hover .iconBg {
            background-color: #00b1a4 !important;
            border-radius: 0 100px 100px 0 !important; }
          .section-dashboard .flex .sidebarContainer .sidebar .activeOptionItem:hover i {
            color: black; }
      .section-dashboard .flex .sidebarContainer .content {
        position: relative;
        height: 100%; }

.contentHeading {
  padding: 1rem 2rem;
  background-color: rgba(0, 82, 204, 0.1); }
  .contentHeading .title {
    margin: 0;
    color: #004dcf;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px; }

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in; }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out; } }
