.radio-group {
  width: auto; }
  .radio-group .radio-input {
    display: -ms-inline-flexbox;
    display: inline-flex;
    width: 100%;
    margin-bottom: 8px;
    cursor: pointer; }
    .radio-group .radio-input .radio-icon {
      width: 30px;
      height: 30px;
      background-color: #fff;
      border-width: 1px;
      border-radius: 50%;
      border-style: solid;
      border-color: #000;
      margin-right: 10px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; }
      .radio-group .radio-input .radio-icon .radio-icon-inner {
        background-color: #fff;
        -webkit-transition: background-color 500ms ease;
        -o-transition: background-color 500ms ease;
        transition: background-color 500ms ease;
        border-radius: 50%;
        width: 18px;
        height: 18px; }
    .radio-group .radio-input .radio-label {
      -ms-flex-item-align: center;
          align-self: center; }
    .radio-group .radio-input:last-child {
      margin-bottom: 0; }
    .radio-group .radio-input.is-checked .radio-icon .radio-icon-inner {
      background-color: #000; }
    .radio-group .radio-input.is-disabled {
      cursor: initial; }
      .radio-group .radio-input.is-disabled .radio-icon {
        border-color: #c6c6c6; }
        .radio-group .radio-input.is-disabled .radio-icon .radio-icon-inner {
          opacity: 0.5;
          border-width: 1px;
          border-radius: 50%;
          border-style: solid;
          border-color: #c6c6c6; }
