/*
	.classOne {
		background-color: #000;
	}
*/
.treeWrapper {
  width: 100%;
  height: 100vh;
  border: 1px dashed #bfbfbf; }

.myLabelComponentInSvg {
  /*height: 70px;*/
  /*width: 180px;*/
  margin: 10px; }

.nodeLabel {
  background: #ffffff;
  /*height: 60px;*/
  border-top: 2px solid #2f80ed;
  text-align: center;
  z-index: 100;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  padding: 4px;
  border-radius: 5px; }
