.timeLineFilter {
  padding: 0.6rem 0.8rem;
  border: 2px solid #ebf2f7;
  /*border-radius: 3px;*/
  -webkit-transition: all 0.02s ease-in-out;
  -o-transition: all 0.02s ease-in-out;
  transition: all 0.02s ease-in-out;
  /*margin: 0.2rem 0;*/
  font-size: 12px;
  color: black;
  height: 38px; }
  .timeLineFilter:last-child {
    border-radius: 0 7px 7px 0;
    /*  border-left: 1px solid #EBF2F7;*/ }
  .timeLineFilter:first-child {
    border-radius: 7px 0 0 7px; }
  .timeLineFilter:hover {
    border: 2px solid #004dcf;
    background-color: rgba(0, 82, 204, 0.1);
    color: #004dcf;
    /*box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;*/
    cursor: pointer; }

.activeTimeLineFilter {
  /*background-color: #eaedff;*/
  /*border: 1px solid #6c5efb;*/
  /*color: #6c5efb;*/
  /* background-color: #004dcf;*/
  /*border: 1px solid #004dcf;*/
  color: #004dcf; }
  .activeTimeLineFilter:hover {
    /* background-color: #004dcf;*/
    /* border: 1px solid #004dcf;*/
    color: #004dcf; }

.disabledTimeLineFilter {
  border: 1px solid #dadada;
  color: #a5a5a5;
  pointer-events: none; }
  .disabledTimeLineFilter:hover {
    border: 1px solid #dadada;
    pointer-events: none;
    /*box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;*/
    cursor: none; }
