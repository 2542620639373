/* parallax forms */
/* size */
.par-s-1 {
  width: 1rem; }

.par-s-2 {
  width: 3rem; }

.par-s-3 {
  width: 7.5rem; }

/* vertical position */
.par-t-5 {
  margin-top: 5%; }

.par-t-10 {
  margin-top: 10%; }

.par-t-15 {
  margin-top: 15%; }

.par-t-20 {
  margin-top: 20%; }

.par-t-25 {
  margin-top: 25%; }

.par-t-30 {
  margin-top: 30%; }

.par-t-35 {
  margin-top: 35%; }

.par-t-40 {
  margin-top: 40%; }

.par-t-45 {
  margin-top: 45%; }

.par-t-50 {
  margin-top: 50%; }

.par-t-55 {
  margin-top: 55%; }

.par-t-60 {
  margin-top: 60%; }

.par-t-65 {
  margin-top: 65%; }

.par-t-70 {
  margin-top: 70%; }

.par-t-75 {
  margin-top: 75%; }

.par-t-80 {
  margin-top: 80%; }

.par-t-85 {
  margin-top: 85%; }

.par-t-90 {
  margin-top: 90%; }

.par-t-95 {
  margin-top: 95%; }

.par-t-100 {
  margin-top: 100%; }

.par-t-105 {
  margin-top: 105%; }

.par-t-110 {
  margin-top: 110%; }

.par-t-115 {
  margin-top: 115%; }

.par-t-120 {
  margin-top: 120%; }

.par-t-125 {
  margin-top: 125%; }

.par-t-130 {
  margin-top: 130%; }

.par-t-135 {
  margin-top: 135%; }

.par-t-140 {
  margin-top: 140%; }

.par-t-145 {
  margin-top: 145%; }

.par-t-150 {
  margin-top: 150%; }

.par-t-155 {
  margin-top: 155%; }

.par-t-160 {
  margin-top: 160%; }

.par-t-165 {
  margin-top: 165%; }

.par-t-170 {
  margin-top: 170%; }

.par-t-175 {
  margin-top: 175%; }

.par-t-180 {
  margin-top: 180%; }

.par-t-185 {
  margin-top: 185%; }

.par-t-190 {
  margin-top: 190%; }

.par-t-195 {
  margin-top: 195%; }

.par-t-200 {
  margin-top: 200%; }
