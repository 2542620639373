/* Button Style Sheet*/
.btn {
  border: none;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  display: inline-block; }
  .btn.is-block {
    width: 100%; }
  .btn:hover {
    text-decoration: none; }
  .btn > div {
    cursor: inherit; }
  .btn:focus {
    outline: none; }

.btn-file-upload {
  display: inline-block; }
  .btn-file-upload .file-upload-label-text {
    display: inline-block;
    max-width: 260px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    vertical-align: sub; }

.btn-primary {
  padding: 8px 20px;
  background-color: #f2f2f2;
  border-radius: 24px; }
  .btn-primary:hover, .btn-primary:active {
    background-color: #000;
    color: #fff; }
  .btn-primary:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-primary.is-ready {
    background-color: #000;
    color: #fff; }
  .btn-primary.is-ready:hover {
    background-color: #7c7b7b;
    color: #fff; }

.btn-company-white {
  padding: 8px 20px;
  background-color: #fff;
  color: #000;
  border: 0;
  border-radius: 24px; }
  .btn-company-white:hover, .btn-company-white:active {
    background-color: #f2f2f2;
    color: #000; }

.btn-border-black {
  padding: 8px 20px;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 24px; }
  .btn-border-black:hover, .btn-border-black:active {
    background-color: #000;
    color: #fff; }

.btn-border-red {
  padding: 8px 20px;
  background-color: #fff;
  color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 24px; }
  .btn-border-red:hover, .btn-border-red:active {
    background-color: #ff0000;
    color: #fff; }
  .btn-border-red:disabled {
    color: #7c7b7b !important;
    background-color: #ffafb0 !important; }
  .btn-border-red.is-ready:hover {
    background-color: #ff0000;
    color: #fff; }

.btn-border-green-special {
  padding: 8px 20px;
  background-color: rgba(0, 177, 164, 0.15);
  color: #44b1a4;
  border: 1px solid #44b1a4;
  border-radius: 5px; }
  .btn-border-green-special:hover, .btn-border-green-special:active {
    background-color: rgba(0, 177, 164, 0.15);
    color: #44b1a4; }
  .btn-border-green-special:disabled {
    color: #44b1a4 !important;
    background-color: rgba(0, 177, 164, 0.15) !important; }
  .btn-border-green-special.is-ready:hover {
    background-color: rgba(0, 177, 164, 0.15);
    color: #44b1a4; }

.btn-secondary {
  position: relative;
  padding: 4px 0;
  background-color: transparent; }
  .btn-secondary:hover:after, .btn-secondary.is-ready:after {
    width: 100%; }
  .btn-secondary:disabled {
    color: #7c7b7b !important;
    cursor: not-allowed !important; }
    .btn-secondary:disabled:after {
      width: 100%;
      background-color: #7c7b7b; }
  .btn-secondary:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 1px;
    background-color: #000;
    left: 50%;
    width: 0;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    -webkit-transition: width 300ms ease-in-out;
    -o-transition: width 300ms ease-in-out;
    transition: width 300ms ease-in-out; }

.btn-tertiary {
  margin: 0;
  position: absolute;
  display: inline-block;
  padding: 15px 17px;
  background-color: #fff;
  color: #000;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  top: 50%;
  right: 0;
  -webkit-transform: rotate(-90deg) translate3d(50%, -100%, 0);
          transform: rotate(-90deg) translate3d(50%, -100%, 0);
  -webkit-transform-origin: right top;
      -ms-transform-origin: right top;
          transform-origin: right top; }
  .btn-tertiary:hover {
    background-color: #000;
    color: #fff; }
  .btn-tertiary:disabled {
    background-color: #fff !important;
    color: #7c7b7b !important;
    cursor: not-allowed !important; }

.btn-danger {
  padding: 8px 20px;
  background-color: #ffafb0;
  border-radius: 24px; }
  .btn-danger:hover, .btn-danger:active {
    background-color: #ff0000;
    color: #fff; }
  .btn-danger:disabled {
    color: #7c7b7b !important;
    background-color: #ffafb0 !important; }
  .btn-danger.is-ready {
    background-color: #ff0000;
    color: #fff; }
  .btn-danger.is-ready:hover {
    background-color: #ffafb0;
    color: #000; }

.btn-company-dark-blue {
  padding: 8px 20px;
  background-color: #f2f2f2;
  border-radius: 24px; }
  .btn-company-dark-blue:hover, .btn-company-dark-blue:active {
    background-color: #2a2498;
    color: #fff; }
  .btn-company-dark-blue:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-dark-blue.is-ready {
    background-color: #2a2498;
    color: #fff; }
  .btn-company-dark-blue.is-ready:hover {
    background-color: rgba(0, 82, 204, 0.1);
    color: #2a2498; }

.btn-company-blue {
  padding: 8px 20px;
  background-color: #f2f2f2;
  border-radius: 24px; }
  .btn-company-blue:hover, .btn-company-blue:active {
    background-color: #2552cb;
    color: #fff; }
  .btn-company-blue:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-blue.is-ready {
    background-color: #2552cb;
    color: #fff; }
  .btn-company-blue.is-ready:hover {
    background-color: rgba(0, 82, 204, 0.1);
    color: #2552cb; }

.btn-metamorph-blue {
  padding: 8px 20px;
  background-color: #0052cc;
  border-radius: 24px; }
  .btn-metamorph-blue:hover, .btn-metamorph-blue:active {
    background-color: #2552cb;
    color: #fff; }
  .btn-metamorph-blue:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-metamorph-blue.is-ready {
    background-color: #2552cb;
    color: #fff; }
  .btn-metamorph-blue.is-ready:hover {
    background-color: rgba(0, 82, 204, 0.1);
    color: #2552cb; }

.btn-company-blue-secondary {
  padding: 8px 20px;
  background-color: #f2f2f2;
  border-radius: 24px;
  border: 1px solid #ffffff00;
  -webkit-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }
  .btn-company-blue-secondary:hover, .btn-company-blue-secondary:active {
    background-color: #2552cb;
    color: #fff; }
  .btn-company-blue-secondary:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-blue-secondary.is-ready {
    background-color: rgba(0, 82, 204, 0.1);
    color: #2552cb;
    /* color: $color-base-white;*/ }
  .btn-company-blue-secondary.is-ready:hover {
    background-color: rgba(0, 82, 204, 0.1);
    color: #2552cb;
    border: 1px solid #2552cb; }
  .btn-company-blue-secondary.is-ready:active {
    -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
            transform: scale(0.95); }

.btn-company-light-blue {
  padding: 8px 20px;
  background-color: rgba(0, 82, 204, 0.1);
  color: #2552cb;
  border-radius: 24px; }
  .btn-company-light-blue:hover, .btn-company-light-blue:active {
    background-color: #2552cb;
    color: #fff; }
  .btn-company-light-blue:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-light-blue.is-ready {
    background-color: #2552cb;
    color: #fff; }
  .btn-company-light-blue.is-ready:hover {
    background-color: #2552cb;
    color: #fff; }

.btn-company-green {
  padding: 8px 20px;
  background-color: #f2f2f2;
  border-radius: 24px; }
  .btn-company-green:hover, .btn-company-green:active {
    background-color: #44b1a4;
    color: #fff; }
  .btn-company-green:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-green.is-ready {
    background-color: #44b1a4;
    color: #fff; }
  .btn-company-green.is-ready:hover {
    background-color: #44b1a4;
    color: #fff; }

.btn-company-purple {
  padding: 8px 20px;
  background-color: rgba(82, 67, 170, 0.1);
  color: #5243aa;
  border-radius: 24px; }
  .btn-company-purple:hover, .btn-company-purple:active {
    background-color: #5243aa;
    color: #fff; }
  .btn-company-purple:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-purple.is-ready {
    background-color: #5243aa;
    color: #fff; }
  .btn-company-purple.is-ready:hover {
    background-color: rgba(82, 67, 170, 0.1);
    color: #5243aa; }

.btn-company-purple-secondary {
  padding: 8px 20px;
  background-color: rgba(82, 67, 170, 0.1);
  color: #5243aa;
  border-radius: 24px;
  border: 1px solid rgba(82, 67, 170, 0);
  -webkit-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s;
  /* &.is-ready {*/
  /*   background-color: rgb(82, 67, 170);*/
  /*   color: $color-base-white;*/
  /* }*/ }
  .btn-company-purple-secondary:hover, .btn-company-purple-secondary:active {
    background-color: #5243aa;
    color: #fff; }
  .btn-company-purple-secondary:disabled {
    color: #f2f2f2 !important;
    background-color: #7c7b7b !important; }
  .btn-company-purple-secondary.is-ready:hover {
    background-color: rgba(82, 67, 170, 0.1);
    color: #5243aa;
    border: 1px solid #5243aa; }
  .btn-company-purple-secondary.is-ready:active {
    -webkit-transform: scale(0.95);
        -ms-transform: scale(0.95);
            transform: scale(0.95); }

.btn-border-black-rect {
  /*padding: $rounded-background-padding;*/
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 4px;
  padding: 0.6rem 1.5rem;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  height: 45px; }
  .btn-border-black-rect:hover, .btn-border-black-rect:active {
    background-color: #000;
    color: #fff; }

.btn-primary-rect {
  padding: 0.6rem 1.5rem;
  background-color: #000;
  border-radius: 4px;
  color: #ffffff;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  height: 45px; }
  .btn-primary-rect:hover, .btn-primary-rect:active {
    background-color: #000;
    color: #fff; }
  .btn-primary-rect:disabled {
    color: #f2f2f2 !important;
    background-color: #585858 !important; }
  .btn-primary-rect.is-ready {
    background-color: #000;
    color: #fff; }
  .btn-primary-rect.is-ready:hover {
    background-color: #7c7b7b;
    color: #fff; }
