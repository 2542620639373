.toggle {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  visibility: visible;
  white-space: nowrap;
  -webkit-transform: scale(1);
      -ms-transform: scale(1);
          transform: scale(1); }

.toggle-label {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /*transition: all 300ms ease-in-out;*/
  cursor: pointer;
  margin: 0; }

.toggle-unchecked:before {
  background-color: #f2f2f2 !important; }

.toggle-appearance {
  position: relative;
  width: 33px; }
  .toggle-appearance:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 10px;
    border-radius: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #8e8c8c;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    cursor: pointer; }
  .toggle-appearance:after {
    content: '';
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    display: block;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 18px;
    height: 18px;
    background-color: #7c7b7b;
    border-radius: 50%;
    border: 1px solid #7c7b7b;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15); }

.toggle-check {
  fill: #f2f2f2;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 1;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  /*transform: scale(0.2);*/ }

.toggle-text-left,
.toggle-text-right {
  /*@include typescale('zeta');*/
  position: relative; }

.toggle-text-left {
  margin-right: 15px; }

.toggle-text-right {
  margin-left: 15px; }

.toggle:checked + .toggle-label .toggle-appearance:after {
  -webkit-transform: translate3d(24px, -50%, 0);
          transform: translate3d(24px, -50%, 0);
  background-color: #000;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-color: #000; }

.toggle:focus + .toggle-label .toggle-appearance:before {
  outline: 1px solid transparent; }

.toggle:focus + .toggle-label .toggle-appearance:after {
  -webkit-box-shadow: 0 0 0 3px #7c7b7b;
          box-shadow: 0 0 0 3px #7c7b7b;
  outline: 1px solid transparent; }

.toggle:disabled + .toggle-label {
  cursor: not-allowed;
  opacity: 0.5; }

.toggle:disabled + .toggle-label .toggle-appearance:before, .toggle:disabled + .toggle-label .toggle-appearance:after {
  cursor: not-allowed;
  -webkit-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out; }
