.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }

.lookBeyond {
  font-size: 23px;
  line-height: 31px; }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.heroCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.title-text {
  font-family: 'GT America Expanded Regular';
  font-size: 30px !important;
  line-height: 50px !important; }
  .title-text span {
    color: #2a2498;
    font-family: 'GT America Expanded Bold'; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 22px !important;
      line-height: 28px !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 0.5rem !important; } }

.margin-top-desktop {
  margin-top: 8em; }

.demoBtn {
  font-family: 'GT America Standard Medium';
  font-size: 20px;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }
