.hero {
  padding-top: 1rem; }
  .hero .heroImg {
    /*width: 580px;*/
    height: 400px; }
    @media (max-width: 990px) {
      .hero .heroImg {
        height: 320px;
        margin: 1.5rem auto 0; } }
  .hero .highlighting {
    color: #291d9b;
    font-family: 'GT America Standard Medium';
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 31px; }
  .hero .title {
    color: #000000;
    width: 645px;
    font-family: 'GT America Extended Regular';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 42px;
    margin: 0;
    margin-top: 1rem; }
    .hero .title .bold {
      font-family: 'GT America Extended Bold';
      color: #291d9b; }
    @media (max-width: 990px) {
      .hero .title {
        max-width: 364px;
        margin: 0 auto;
        font-size: 28px;
        letter-spacing: 0;
        line-height: 37px;
        text-align: center;
        margin-top: 1rem; } }
  .hero .subText {
    color: #000000;
    width: 565px;
    font-family: 'GT America Standard Regular';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 31px;
    margin-top: 1.5rem; }
  .hero .cta {
    margin-top: 2.5rem;
    border-radius: 4px;
    background-color: #000000;
    /*padding: 1rem 2rem;*/
    border: none;
    height: 45px;
    width: 200px;
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    .hero .cta:hover {
      cursor: pointer; }
  .hero .features {
    margin-top: 2.5rem; }
    @media (max-width: 990px) {
      .hero .features {
        max-width: 369px;
        margin: 1.5rem auto 0; } }
    .hero .features .point {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 22px;
      letter-spacing: 0;
      line-height: 31px; }
      @media (max-width: 990px) {
        .hero .features .point {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px;
          text-align: left; } }
    .hero .features .svg {
      width: 12px; }
      @media (max-width: 990px) {
        .hero .features .svg {
          width: 8px; } }
