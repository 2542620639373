.whiteSmokeBorder {
  border-color: whitesmoke; }

.disabledCursor {
  cursor: pointer; }

.noDecoration {
  text-decoration: none; }
  .noDecoration:hover {
    text-decoration: none; }

.duration {
  line-height: 22px;
  text-align: center;
  display: inline-grid;
  padding-bottom: 10px; }

.progressChart {
  line-height: 22px;
  text-align: center; }

.card_border {
  border: 1px solid #3ab6ab !important; }
