.quill-editor div {
  border: none !important;
  padding-top: 0px; }

.chart-title {
  color: #000000;
  font-family: 'GT America Expanded Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 20px; }

.bold-txt {
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
