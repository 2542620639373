.magic-link {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01);
  font-weight: 900;
  border-radius: 10px;
  width: 99%;
  margin-bottom: 3%;
  padding: 15px !important; }
