.step {
  border: 2px solid #6d7278;
  border-radius: 8px;
  background-color: #f2f6fd;
  color: #2552cb;
  padding: 20px 10px;
  width: 375px;
  /*margin: auto;*/
  font-size: 18px; }
  .step .header {
    display: -ms-flexbox;
    display: flex; }
    .step .header .number {
      font-size: 16px;
      border: 1px solid #2552cb;
      border-radius: 50%;
      margin: 0 20px;
      padding: 10px 15px; }
    .step .header p {
      margin: 0.6rem 1rem; }
  .step .details {
    font-size: 16px;
    line-height: 24px; }
  .step:hover {
    cursor: pointer; }
  .step span {
    margin: auto 0 auto 60px; }

.getStarted {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 2rem 0;
  padding: 12px 32px;
  border-radius: 30px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.getStartedSkip {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 2rem 0;
  padding: 9px 30px;
  border-radius: 30px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.getStartedNew {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: 0 0 2rem;
  padding: 18px 36px;
  border-radius: 30px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.pageTitle {
  font-family: 'GT America Expanded Medium';
  font-size: 38px;
  letter-spacing: 0px;
  line-height: 52px;
  text-align: center;
  margin-top: 3rem; }

.pageSubTitle {
  color: #464646;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding: 0 6rem; }

.skillsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0 5rem; }

.actionBtns {
  margin: 0 5rem; }

.partsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0 5rem; }

.featureCard {
  border: 2px solid #6d7278;
  border-radius: 8px; }
  .featureCard:hover {
    cursor: pointer; }

.featureNoneCard {
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-height: 240px; }
  .featureNoneCard:hover {
    cursor: pointer; }
  .featureNoneCard h4 {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #6d7278; }

.featureCardSelected {
  border: 3px solid #00b1a4;
  border-radius: 8px;
  min-height: 240px; }
  .featureCardSelected:hover {
    cursor: pointer; }
  .featureCardSelected h4 {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #6d7278; }

.featureNoneCardSelected {
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  border-radius: 8px;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-height: 240px; }
  .featureNoneCardSelected:hover {
    cursor: pointer; }
  .featureNoneCardSelected h4 {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: #6d7278; }

.check {
  left: 10px;
  top: 10px; }

.unchecked {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  left: 10px;
  top: 10px; }

.img {
  min-height: 6em; }

.heading {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px; }

@media (max-width: 640px) {
  .pageTitle {
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 30px;
    letter-spacing: 0px;
    line-height: 36px;
    text-align: center;
    margin-top: 1rem; }
  .pageSubTitle {
    color: #464646;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 0; }
  .skillsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin: 0 2rem; }
  .actionBtns {
    margin: 0 2rem; }
  .partsGrid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    margin: 0 2rem; }
  .getStarted {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 1rem 0;
    line-height: 24px;
    padding: 0.3rem 1.5rem;
    border-radius: 30px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; } }
