.pagination {
  list-style: none;
  display: -ms-flexbox;
  display: flex; }
  .pagination li {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: bottom;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .pagination li a {
      text-decoration: none;
      font-size: 16px;
      cursor: pointer;
      z-index: 0; }
      .pagination li a:focus {
        outline: none; }
      .pagination li a .icon-wrap {
        margin: 0; }
    .pagination li .icon-wrap svg {
      height: 12px;
      margin-bottom: 2px; }
    .pagination li.break {
      bottom: 1px;
      font-size: 18px;
      margin-left: -10px;
      margin-right: 15px; }
    .pagination li.previous {
      margin-right: 20px; }
    .pagination li.next {
      margin-left: 5px; }
    .pagination li.pagination-page-item {
      margin-right: 15px;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-align: center;
          align-items: center; }
      .pagination li.pagination-page-item:hover {
        cursor: pointer; }
      .pagination li.pagination-page-item.is-active .middle-dot {
        opacity: 1; }
      .pagination li.pagination-page-item:last-of-type {
        margin-right: 0; }
      .pagination li.pagination-page-item .middle-dot {
        opacity: 0;
        font-size: 30px;
        margin-top: 0px; }
