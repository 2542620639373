.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 2.8em; }
  @media (max-width: 575px) {
    .title {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 24px;
      position: relative;
      top: 0em; } }

.subtitle {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 8em; }
  @media (max-width: 575px) {
    .subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      position: relative;
      top: 1em;
      margin-bottom: 2em; } }

.indorse-circle {
  position: absolute;
  z-index: 0;
  height: 1055px;
  width: 1055px;
  left: 0;
  right: 0;
  top: -2em;
  margin: auto; }

.main {
  min-height: 1000px;
  position: relative; }
  @media (max-width: 575px) {
    .main {
      min-height: 100%; } }

.cards-container {
  position: absolute;
  max-width: 1280px;
  top: 29em;
  left: 0;
  right: 0;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 100px; }
  @media (max-width: 575px) {
    .cards-container {
      position: relative;
      grid-template-columns: 1fr;
      top: 0;
      grid-gap: 40px; } }

.issue-management {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 52em; }

.card {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 215px;
  width: 404px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  padding: 30px 35px; }
  .card .card-title {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 7px; }
  .card .card-subtitle {
    font-family: 'GT America Standard Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 25px; }
  .card .logos {
    display: -ms-flexbox;
    display: flex; }
  @media (max-width: 575px) {
    .card {
      width: 350px;
      min-height: 215px;
      max-height: 235px;
      height: auto; }
      .card .card-title {
        font-size: 18px; }
      .card .card-subtitle {
        font-size: 16px; } }
