.title {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 4%; }

.card {
  border-radius: 10px;
  width: 99%;
  margin-bottom: 3%;
  padding: 15px !important; }
