/* .myModal {*/
/*   width: 700px;*/
/*   margin-top: -300px !important;*/
/*   margin-left: -350px !important;*/
/* }*/
/* .myModal .modal-body {*/
/*   max-height: 525px;*/
/* }*/
.card {
  /*  text-align: center;*/ }

.shortlist {
  float: right;
  margin: 13px;
  padding-bottom: 10px;
  padding-top: 10px; }

.headings h2 {
  font-size: 22px;
  color: #2552cb; }

.headings h3 {
  font-size: 16px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.scrollCard {
  overflow-y: auto;
  height: 600px; }

.scrollCard::-webkit-scrollbar {
  width: 6px; }

.scrollCard::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scrollCard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }

.card-background:nth-of-type(even) {
  background-color: #f8f9fb; }
  .card-background:nth-of-type(even) > div:nth-of-type(even) {
    background-color: #f8f9fb; }
