.secondaryCta {
  background-color: #2a2498;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 780px) {
    .secondaryCta {
      padding: 0 0 4rem; } }
  @media (max-width: 575px) {
    .secondaryCta {
      padding: 0 0 2rem !important; } }
  .secondaryCta .heading {
    margin: 4rem 0;
    text-align: center;
    /* .text {*/
    /*   height: 229px;*/
    /*   width: 723px;*/
    /* }*/ }
    @media (max-width: 780px) {
      .secondaryCta .heading {
        margin: 2rem auto 0; } }
    @media (max-width: 575px) {
      .secondaryCta .heading {
        margin: 3rem auto 0; } }
    .secondaryCta .heading .title {
      /* height: 100px;*/
      width: 630px;
      color: #ffffff;
      font-family: 'GT America Extended Regular';
      /* font-weight: 100;*/
      font-size: 42px;
      letter-spacing: 0;
      line-height: 50px;
      text-align: center; }
      @media (max-width: 780px) {
        .secondaryCta .heading .title {
          width: 430px;
          font-size: 28px;
          letter-spacing: 0;
          line-height: 32px; } }
      @media (max-width: 575px) {
        .secondaryCta .heading .title {
          width: 230px;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px; } }
    .secondaryCta .heading .policy {
      margin-top: 0rem;
      font-family: 'GT America Standard Regular';
      font-size: 15px;
      letter-spacing: 0;
      line-height: 23px;
      color: #ffffff; }
      .secondaryCta .heading .policy .svg {
        width: 12px; }
      @media (max-width: 575px) {
        .secondaryCta .heading .policy {
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px; } }
    .secondaryCta .heading .ctaBtn {
      text-align: center; }
      .secondaryCta .heading .ctaBtn .downloadBtn {
        text-align: center;
        margin: 0 1rem;
        /* height: 71px;*/
        /* width: 71px;*/
        /* border-radius: 56.5px;*/
        /* background-color: #ffffff;*/
        /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);*/
        -webkit-transition: ease-in-out 0.15s;
        -o-transition: ease-in-out 0.15s;
        transition: ease-in-out 0.15s;
        cursor: pointer; }
        @media (max-width: 575px) {
          .secondaryCta .heading .ctaBtn .downloadBtn {
            margin: 0 0.5rem;
            /* height: 50px;*/
            /* width: 50px;*/
            /* border-radius: 25px;*/ } }
        .secondaryCta .heading .ctaBtn .downloadBtn img {
          margin: 10px auto;
          height: 70px;
          width: 70px; }
          @media (max-width: 575px) {
            .secondaryCta .heading .ctaBtn .downloadBtn img {
              margin: 7px auto;
              height: 50px;
              width: 50px; } }
        .secondaryCta .heading .ctaBtn .downloadBtn:focus .secondaryCta .heading .ctaBtn .downloadBtn:active {
          outline: none; }
        .secondaryCta .heading .ctaBtn .downloadBtn:hover {
          -webkit-transform: translateY(-2px);
              -ms-transform: translateY(-2px);
                  transform: translateY(-2px);
          opacity: 0.85; }
  .secondaryCta .policy {
    font-family: 'GT America Standard Regular';
    font-size: 15px;
    letter-spacing: 0;
    line-height: 23px;
    color: #ffffff; }
    .secondaryCta .policy .svg {
      width: 12px; }
    @media (max-width: 575px) {
      .secondaryCta .policy {
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px; } }
  @media (max-width: 780px) {
    .secondaryCta .img1 {
      width: 160px; } }
  @media (max-width: 575px) {
    .secondaryCta .img1 {
      width: 120px; } }
  @media (max-width: 780px) {
    .secondaryCta .img2 {
      width: 160px; } }
  @media (max-width: 575px) {
    .secondaryCta .img2 {
      width: 100px; } }
