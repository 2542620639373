.secondary {
  margin: 0 auto;
  padding: 2rem 0 4rem;
  max-width: 1112px;
  /*text-align: center;*/ }
  .secondary .title {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 990px) {
      .secondary .title {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center; } }
  .secondary .faq {
    margin-top: 2rem; }
    .secondary .faq .card {
      margin-bottom: 1rem;
      padding: 2rem 3rem 1rem;
      cursor: pointer; }
      @media (max-width: 990px) {
        .secondary .faq .card {
          margin: 0 1rem 1rem; } }
      .secondary .faq .card .que {
        color: #000000;
        font-family: 'GT America Extended Bold';
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px; }
        @media (max-width: 990px) {
          .secondary .faq .card .que {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px; } }
      .secondary .faq .card .ans {
        font-family: 'GT America Standard Regular';
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
        cursor: default;
        margin-bottom: 1rem; }
