.interface-option-card {
  position: relative;
  width: 16rem;
  height: 16rem;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  border-radius: none !important;
  border: none !important; }
  .interface-option-card img {
    max-width: 10rem;
    height: 10rem; }
  .interface-option-card:hover {
    cursor: pointer; }
    .interface-option-card:hover .dot {
      opacity: 1; }
  @media (max-width: 575.98px) {
    .interface-option-card {
      width: 12rem;
      height: 12rem; }
      .interface-option-card img {
        max-width: 6rem;
        height: 6rem; } }

.dot {
  height: 10px;
  width: 10px;
  background-color: black;
  border-radius: 50%;
  display: inline-block;
  opacity: 0;
  position: absolute;
  left: 48%;
  bottom: -2rem; }

.click-here-link {
  color: #0080d6 !important;
  cursor: pointer; }
  .click-here-link:hover {
    text-decoration: underline; }
