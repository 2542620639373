.card-profile {
  margin: 0 auto;
  min-height: 100vh;
  margin-right: 0;
  width: 100%; }

.title {
  font-size: 32px;
  margin-bottom: 0;
  /*font-family: 'GT America Standard Regular', serif;*/ }

.heading {
  font-size: 24px;
  line-height: 26px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0;
  /*font-family: 'GT America Standard Regular', serif;*/ }

.subTitle {
  font-size: 20px;
  line-height: 30px;
  margin: 2rem 4rem; }

.borderless {
  border: none;
  width: 100%; }

.warningCard {
  height: 5rem; }

.card-header {
  background: none; }

.help-box {
  border: 1px solid #949494;
  border-radius: 5px; }

.help-list {
  color: #737373; }

.upload-box {
  border: 2px dashed #b5b5b5;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
