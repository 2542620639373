/* .blue-background {*/
/*   background-color: #eff3fe;*/
/* }*/
.main-title {
  font-family: 'GT America Extended Bold';
  color: #000000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 28px; } }

.main-subtitle {
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.how-it-works-title {
  color: #2a2498;
  font-size: 28px;
  letter-spacing: -0.08px;
  line-height: 32px; }

.tab-container {
  -ms-flex-align: end;
      align-items: flex-end; }

.tab-item {
  padding: 1rem;
  min-height: 88px;
  text-align: center;
  background-color: #dde3f2;
  color: black;
  font-family: 'GT America Expanded Regular'; }
  .tab-item:hover {
    cursor: pointer; }
  .tab-item .visible {
    background-color: white; }
  .tab-item h2 {
    color: #2a2498;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0; }
  .tab-item:hover {
    cursor: pointer;
    background-color: white; }
  .tab-item.tab-selected {
    height: 127px;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff; }
    .tab-item.tab-selected h2 {
      font-family: 'GT America Expanded Bold';
      color: #2a2498;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      font-weight: 900; }

.card {
  height: 517px;
  width: 1240px;
  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1); }
  .card .content {
    margin: auto 125px;
    height: 100%; }
    .card .content .content-left,
    .card .content .content-right {
      margin: auto; }
    .card .content .content-right .info {
      max-width: 426px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      margin-left: auto; }
    .card .content .content-right .list {
      margin-left: 2em;
      font-family: 'GT America Standard Medium'; }
    .card .content .content-right p {
      font-weight: 900 !important; }
  .card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px; }
  .card p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.28px;
    line-height: 28px; }
  @media (max-width: 500px) {
    .card {
      height: auto;
      min-width: 385px;
      max-width: 100%; }
      .card .content {
        margin: 20px; }
      .card p {
        font-size: 16px;
        font-family: 'GT America Standard Medium'; } }

.large-card {
  max-width: 731px;
  border-radius: 5px;
  background-color: #eff3fe;
  padding: 3em; }
  .large-card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: -0.07px;
    line-height: 32px; }

.heading {
  font-family: 'GT America Extended Bold';
  max-width: 400px;
  color: #2800a0;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 0;
  text-align: center; }
  @media (max-width: 500px) {
    .heading {
      font-size: 22px;
      line-height: 34px; } }

.headingCard {
  border-radius: 5px;
  background-color: #eff3fe; }

.step {
  color: #00b1a4;
  font-family: 'GT America Standard Bold';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  margin-bottom: 0.2em; }

.text {
  max-width: 390px;
  font-family: 'GT America Standard Regular';
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .text {
      font-size: 16px !important;
      line-height: 26px; } }

.line-one {
  position: absolute;
  top: 23em;
  left: 26.7em;
  width: 70px; }

.line-two {
  position: absolute;
  top: 23em;
  right: 26.7em;
  width: 70px; }

.circle {
  position: relative;
  width: 220px;
  margin: 0 auto 2em; }

.survey-title {
  margin-top: 1em;
  color: #2a2498;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }
  @media (max-width: 500px) {
    .survey-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px; } }

.survey-subtitle {
  color: #282828;
  font-family: 'GT America Standard Regular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }
  @media (max-width: 500px) {
    .survey-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.grey-bg {
  width: 950px;
  border-radius: 4px;
  background-color: rgba(241, 246, 249, 0.5);
  padding: 30px 45px;
  margin: 3em auto; }

.comparision-title {
  color: #000000;
  font-family: 'GT America Standard Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 2.5em; }

.comparision-image {
  margin: 3em auto;
  display: block; }
  @media (max-width: 500px) {
    .comparision-image {
      margin: 2em auto; } }

.getStartedBtn {
  border-radius: 4px;
  background-color: #000000;
  padding: 0.7rem 2.5rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 200px;
  border: none;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .getStartedBtn:focus {
    outline: none; }

.placeholder {
  height: 220px;
  width: 320px;
  border-radius: 5px;
  background-color: #dae4ff;
  -webkit-box-shadow: 0 2px 10px 0 #b0bfe8;
          box-shadow: 0 2px 10px 0 #b0bfe8;
  margin: auto; }
