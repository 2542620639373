.viz-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
  padding-left: 1rem;
  padding-right: 1rem; }

.viz {
  position: relative;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.career-start-date,
.career-end-date {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: 1; }

.career-start-date {
  padding-right: 0.5rem; }

.career-end-date {
  padding-left: 0.5rem; }

.timeline {
  position: absolute;
  width: 100%; }

.segment {
  position: absolute;
  height: 100%; }

.segment-inner {
  width: 100%;
  height: 100%;
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  -o-transition: transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  z-index: 1;
  /* cursor: pointer;*/ }

.segment-gray .segment-inner {
  background-color: #a1a1a1; }

.segment-yellow .segment-inner {
  background-color: #ffcc2f; }

.segment-orange .segment-inner {
  background-color: #ffac52; }

.segment-green .segment-inner {
  background-color: #40bb89; }

.segment-blue .segment-inner {
  background-color: #498cd8; }

.segment-purple .segment-inner {
  background-color: #5e62c5; }

.segment-black .segment-inner {
  background-color: #1c1c1c; }

.segment-gray.segment-hovered .segment-inner {
  background-color: #6e6e6e; }

.segment-yellow.segment-hovered .segment-inner {
  background-color: #ffb200; }

.segment-orange.segment-hovered .segment-inner {
  background-color: #ff8c0f; }

.segment-green.segment-hovered .segment-inner {
  background-color: #25a68a; }

.segment-blue.segment-hovered .segment-inner {
  background-color: #2f73bf; }

.segment-purple.segment-hovered .segment-inner {
  background-color: #32368b; }

.segment-black.segment-hovered .segment-inner {
  background-color: black; }

.segment-scale-on-hover .segment-inner {
  -webkit-transform: scale(1.05);
      -ms-transform: scale(1.05);
          transform: scale(1.05); }

.segment-entry-start .segment-inner {
  -webkit-transform-origin: right center;
      -ms-transform-origin: right center;
          transform-origin: right center; }

.segment-entry-mid .segment-inner {
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center; }

.segment-entry-end:not(.segment-entry-last) {
  padding-right: 0.5rem; }
  .segment-entry-end:not(.segment-entry-last) .segment-inner {
    -webkit-transform-origin: left center;
        -ms-transform-origin: left center;
            transform-origin: left center; }

.segment-entry-start.segment-entry-end .segment-inner {
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center; }

.scale {
  position: absolute;
  width: 100%; }

.scale-tick {
  position: absolute;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 0.6rem;
  -webkit-transform-origin: 100% 0;
      -ms-transform-origin: 100% 0;
          transform-origin: 100% 0;
  -webkit-transform: translateX(-100%) rotate(-45deg);
      -ms-transform: translateX(-100%) rotate(-45deg);
          transform: translateX(-100%) rotate(-45deg);
  width: 36px; }

.scale-tick-marker {
  position: absolute;
  width: 0;
  border-left: 0.5px solid #ced4da;
  border-right: 0.5px solid #ced4da; }

.dotted-line {
  position: absolute;
  width: calc(100% - 0.5rem);
  height: 0;
  left: 0;
  top: 50%;
  border-top: 0.5px dashed #6c757d;
  border-bottom: 0.5px dashed #6c757d; }
