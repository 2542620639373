.wrapper {
  /*height: 380px;*/
  border-radius: 5px;
  background-color: #2a2498;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  padding: 2rem 3.5rem; }
  @media (max-width: 990px) {
    .wrapper {
      margin: 0 1rem;
      padding: 2rem; } }

.appForm {
  /*max-width: 1270px;*/
  /*margin: 2rem auto;*/
  /*position: relative;*/
  text-align: center; }
  @media (max-width: 990px) {
    .appForm {
      margin: auto;
      width: 100%; } }
  .appForm h1 {
    color: #ffffff;
    font-family: 'GT America Extended Regular';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 990px) {
      .appForm h1 {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center; } }
  .appForm .grid {
    width: 715px;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: stretch;
        justify-content: stretch;
    gap: 2rem; }
    @media (max-width: 990px) {
      .appForm .grid {
        display: block;
        width: 100%; } }
    .appForm .grid input {
      font-family: 'GT America Standard Regular';
      font-size: 16px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      height: 45px;
      width: 340px;
      border: 1px solid #d1dbe3;
      border-radius: 5px;
      background-color: #ffffff;
      padding: 0 1rem; }
      @media (max-width: 990px) {
        .appForm .grid input {
          margin: 1rem auto;
          width: 100%; } }
  .appForm .submitBtn {
    height: 45px;
    width: 152px;
    margin-top: 2rem;
    border-radius: 4px;
    background-color: #ffffff;
    /*padding: 1rem 2rem;*/
    border: none;
    color: #28129d;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    .appForm .submitBtn:hover {
      cursor: pointer; }
    @media (max-width: 990px) {
      .appForm .submitBtn {
        width: 100%;
        margin-top: 1rem; } }
  .appForm .disabled {
    background-color: #c5c5c5;
    color: #ffffff;
    pointer-events: none; }
    .appForm .disabled:hover {
      cursor: default; }
