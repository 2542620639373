/* TODO: refactor images*/
.icon-error {
  height: 13rem;
  background-repeat: no-repeat;
  background-image: url("./oops.png");
  background-size: 100% auto; }
  @media (max-width: 575.98px) {
    .icon-error {
      height: 17rem;
      background-image: url("./oops_mobile.png");
      background-size: auto; } }

.icon-metamask {
  height: 10rem;
  width: 10rem;
  background-repeat: no-repeat;
  background-image: url("../../../../../ui/ethereum/smart-contracts/send-transaction/meta-mask.png");
  background-size: auto; }
