.wrapper {
  /*background-color: #eff3fe;*/
  /*border: 1px solid red;*/ }

.container {
  max-width: 1270px;
  margin: 0 auto;
  /*padding-top: 2rem;*/ }

.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: 720px;
  /*padding-bottom: 3rem;*/
  max-width: 1270px;
  margin: 0 auto;
  /*padding-top: 2rem;*/
  -webkit-transform: translateY(-4rem);
      -ms-transform: translateY(-4rem);
          transform: translateY(-4rem); }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }

.lookBeyond {
  font-size: 25px; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 4rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.smaller-h2 {
  font-size: 1.3em !important;
  line-height: 1.4em !important;
  margin-top: 0px;
  margin-bottom: 0px; }

.expert-details-name {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
  /*font-size: 1.4em !important;*/
  /*line-height: 1.8em !important;*/
  margin-top: 0px;
  margin-bottom: 0px; }
  @media (max-width: 560px) {
    .expert-details-name {
      font-size: 1em !important;
      line-height: 1.1em; } }

.roleImg {
  width: 5rem; }

.roleImgWrapper {
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  border: 1px solid whitesmoke;
  background: whitesmoke; }

.border-black {
  border: solid 1px #000;
  border-radius: 5px;
  padding: 2em;
  margin-left: 1em;
  margin-right: 1em; }
  @media (max-width: 560px) {
    .border-black {
      padding: 1em; } }

.max-height-desktop {
  max-height: 840px; }

.margin-left-skill-grid {
  margin-left: 29px; }

.verified-icon-placeholder {
  width: 1.5em; }

.expert-details-mobile-placeholder {
  margin: 0 5em; }

.box-shadow {
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.5);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff; }

.profile {
  /*height: 464px;*/
  width: 536px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  padding: 2rem 1rem 1.5rem; }

@media (max-width: 990px) {
  .heroDetails {
    text-align: center; } }

.heroDetails .highlight {
  color: #291d9b;
  font-family: 'GT America Standard Bold';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px; }

.heroDetails .name {
  color: #000000;
  font-family: 'GT America Extended Medium';
  font-size: 42px;
  letter-spacing: 0;
  line-height: 50px;
  margin-top: 1rem; }
  @media (max-width: 990px) {
    .heroDetails .name {
      font-size: 28px;
      letter-spacing: 0;
      line-height: 37px;
      text-align: center; } }

.heroDetails .career {
  margin: 0;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 31px;
  margin-top: 1.5rem; }
  @media (max-width: 990px) {
    .heroDetails .career {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px;
      text-align: center; } }

.heroDetails .cta {
  display: block;
  margin-top: 2.5rem;
  border-radius: 4px;
  background-color: #000000;
  padding: 0.65rem 0;
  border: none;
  height: 45px;
  width: 196px;
  color: #ffffff;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  text-decoration: none; }
  @media (max-width: 990px) {
    .heroDetails .cta {
      margin: 2.5rem auto 0; } }
  .heroDetails .cta:hover {
    cursor: pointer; }

.heading {
  color: #000000;
  font-family: 'GT America Standard Bold';
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px; }

.bio {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 22px; }

.heading2 {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 19px; }
