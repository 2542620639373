.scroll-card {
  width: 100%; }

.title {
  height: 38px;
  width: 384px;
  color: #000000;
  font-size: 30px;
  letter-spacing: 1.88px;
  line-height: 38px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.sub-title {
  height: 25px;
  width: 419px;
  color: #000000;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.2px;
  line-height: 25px; }

.cancel-plan {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 380px;
  width: 450px;
  border: 1px solid #000000;
  border-radius: 4px;
  line-height: 24px; }

.action-button {
  background-color: inherit !important;
  border: 1px solid;
  outline: none; }
  .action-button:hover {
    border: 1px solid;
    color: black;
    background-color: #f5f5f5 !important; }

.cancelFont {
  font-weight: bold;
  font-size: 16px;
  margin-top: 8%; }

.subscription {
  font-weight: bold;
  font-size: 16px;
  margin-top: 0%;
  margin-bottom: 2%;
  font-family: 'GT America Standard Medium'; }

.subscription-thanks {
  font-weight: bold;
  font-size: 16px;
  margin-top: 0%;
  margin-bottom: 2%; }
