.lead-row {
  background-image: url("../../../resources/page/company-landing-page/img/company-landing-banner.svg");
  background-position: top;
  background-size: contain;
  height: 30vh; }
  @media (max-width: 767.98px) {
    .lead-row {
      height: 20vh; } }

/* TODO: use this from home-v2 once finalized*/
.yellow-bg-color {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01); }

.indorseAdv {
  line-height: 22px;
  text-align: center;
  margin: 3% 3% 1%;
  font-size: 14px; }

.firstHire {
  font-weight: 900;
  text-align: center;
  margin-top: 4%;
  font-size: 18px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.reportsBackground {
  background-color: black;
  color: white; }

.heroSection {
  height: 80vh;
  margin: auto; }

.stats {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px; }

.section {
  margin-bottom: 10%;
  margin-top: 10%; }

.testimonalSection {
  margin-bottom: 7%;
  margin-top: 7%; }

.easySection {
  margin-bottom: 7%;
  margin-top: 7%; }

.counter {
  font-size: 3.5rem;
  margin-bottom: 30px; }

@media (max-width: 560px) {
  .counter {
    font-size: 3.5rem;
    margin-bottom: 30px;
    margin-top: 10%; }
  .stats {
    font-size: 16px; }
  .heroTitle {
    font-size: 20px !important;
    text-align: center; } }

.qualify {
  font-size: 1rem;
  margin-top: 15%;
  line-height: 30px; }

.expertSection {
  margin-top: 3%; }

.heroTitle {
  font-size: 34px;
  font-weight: 900;
  letter-spacing: -1.02px;
  line-height: 46px;
  text-align: center; }

.heroSubTitle {
  font-size: 18px;
  text-align: center;
  margin-top: 40px;
  line-height: 30px;
  color: #464646; }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.submitBtn {
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }

.policy {
  margin-top: 5rem; }

.svg {
  width: 12px; }
