.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  /* min-height: calc(100vh - 54px);*/ }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 100%; }

.lookBeyond {
  font-size: 20px; }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.heroCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.title-text {
  margin-top: 1em !important;
  font-size: 36px !important;
  line-height: 48px !important; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 22px !important;
      line-height: 28px !important; } }

.subtitle-text {
  font-size: 20px !important;
  line-height: 26px !important; }
  @media (max-width: 575px) {
    .subtitle-text {
      font-size: 18px !important;
      line-height: 28px !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 0.25rem !important; } }

.margin-top-desktop {
  margin-top: 0em; }

.shapes {
  padding-left: 6.3em; }
  .shapes h3 {
    padding-top: 1rem !important;
    font-size: 18px;
    font-weight: 600px; }

.heroTitle {
  font-family: 'GT America Expanded Regular';
  text-align: center;
  max-width: 800px;
  margin-bottom: 24px !important;
  width: 815px;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 40px; }
  .heroTitle span {
    color: #2a2498;
    font-family: 'GT America Expanded Bold'; }
  @media (max-width: 575px) {
    .heroTitle {
      margin-top: 2.5em;
      width: 100%;
      font-size: 28px;
      letter-spacing: 0.19px;
      line-height: 37px; } }

.heroSubTitle {
  font-family: 'GT America Standard Regular';
  text-align: left;
  margin: 0 auto;
  margin-bottom: 35px;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px; }
  @media (max-width: 575px) {
    .heroSubTitle {
      width: 100%;
      text-align: center;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.22px;
      line-height: 26px; } }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.submitBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0;
  padding: 3px 30px; }

.or {
  max-width: 463px;
  margin: 0 auto; }

.slackBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }

.line {
  width: 275px;
  height: 3px; }

.policy {
  margin-top: 0rem;
  font-family: 'GT America Standard Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 23px; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.svg {
  width: 12px; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  /* min-height: calc(100vh - 54px);*/
  min-height: 100%;
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 100%; }
  @media (max-width: 550px) {
    .hero-company {
      min-height: 1100px; } }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }
