.menu {
  position: unset !important; }
  .menu button:focus {
    outline: 0 !important; }
  .menu button:after {
    width: 0 !important; }
  .menu .dropdown-menu {
    width: 100% !important;
    background-color: white !important;
    position: absolute !important;
    padding: 0;
    border-radius: 0 !important;
    border-right: 0;
    border-left: 0;
    opacity: 0 !important;
    top: 46px !important;
    /* NavItem*/ }
    @media (max-width: 575.98px) {
      .menu .dropdown-menu {
        /* height: 100vh;*/
        /* a,*/
        /* div {*/
        /*   display: block !important;*/
        /* }*/ } }
    .menu .dropdown-menu.showing {
      -webkit-transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
      opacity: 1 !important;
      -webkit-transform: translate3d(0, 3.1rem, 0) !important;
              transform: translate3d(0, 3.1rem, 0) !important; }
    .menu .dropdown-menu a {
      display: inline;
      position: relative;
      text-decoration: none;
      padding: 0.5rem 1.5rem; }
      .menu .dropdown-menu a:hover span:before {
        opacity: 1; }
      .menu .dropdown-menu a.activeLink span:before {
        opacity: 1; }
      .menu .dropdown-menu a span:before {
        content: '';
        height: 5px;
        width: 5px;
        background-color: black;
        border-radius: 50%;
        opacity: 0;
        position: absolute;
        left: 48%;
        bottom: -10px; }
    .menu .dropdown-menu a,
    .menu .dropdown-menu .active {
      background-color: white !important;
      color: black; }
    @media (max-width: 991.98px) {
      .menu .dropdown-menu a {
        padding: 0.5rem 1rem; } }
    @media (max-width: 767.98px) {
      .menu .dropdown-menu a {
        padding: 0; } }
  .menu .dropdown-menu ul {
    z-index: 1001;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    right: -100vw;
    color: black;
    background: white;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin: 0 !important; }
  .menu .showSubMenu ul {
    opacity: 1;
    right: 0;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease; }
  .menu .dropdown-menu,
  .menu .show {
    -webkit-transform: translate3d(0, 0, 0) !important;
            transform: translate3d(0, 0, 0) !important; }

.menu-burger {
  width: 18px;
  height: 15px;
  position: relative; }
  .menu-burger span:first-child {
    display: block;
    height: 2px;
    width: 100%;
    background: black;
    -webkit-transition-timing-function: ease;
         -o-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.3s;
         -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
    border-radius: 0px;
    -webkit-transform-origin: center center 0px;
        -ms-transform-origin: center center 0px;
            transform-origin: center center 0px;
    position: absolute;
    -webkit-transform: translate3d(0px, 0px, 0px) rotate(0deg);
            transform: translate3d(0px, 0px, 0px) rotate(0deg);
    margin-top: -1px; }
  .menu-burger span:last-child {
    display: block;
    height: 2px;
    width: 100%;
    background: black;
    -webkit-transition-timing-function: ease;
         -o-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.3s;
         -o-transition-duration: 0.3s;
            transition-duration: 0.3s;
    border-radius: 0px;
    -webkit-transform-origin: center center 0px;
        -ms-transform-origin: center center 0px;
            transform-origin: center center 0px;
    position: absolute;
    -webkit-transform: translate3d(0px, 15px, 0px) rotate(0deg);
            transform: translate3d(0px, 15px, 0px) rotate(0deg);
    margin-top: -1px; }

.transform span:first-child {
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  border-radius: 0px;
  -webkit-transform-origin: center center 0px;
      -ms-transform-origin: center center 0px;
          transform-origin: center center 0px;
  position: absolute;
  -webkit-transform: translate3d(0px, 7.5px, 0px) rotate(45deg);
          transform: translate3d(0px, 7.5px, 0px) rotate(45deg);
  margin-top: -1px; }

.transform span:last-child {
  -webkit-transition-timing-function: ease;
       -o-transition-timing-function: ease;
          transition-timing-function: ease;
  -webkit-transition-duration: 0.3s;
       -o-transition-duration: 0.3s;
          transition-duration: 0.3s;
  border-radius: 0px;
  -webkit-transform-origin: center center 0px;
      -ms-transform-origin: center center 0px;
          transform-origin: center center 0px;
  position: absolute;
  -webkit-transform: translate3d(0px, 7.5px, 0px) rotate(-45deg);
          transform: translate3d(0px, 7.5px, 0px) rotate(-45deg);
  margin-top: -1px; }
