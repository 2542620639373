.overflow-menu {
  position: relative;
  cursor: pointer;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .overflow-menu:focus {
    outline: none;
    border: none; }
  .overflow-menu .icon-wrap {
    margin: 0; }
  .overflow-menu .overflow-menu-options {
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: start;
        align-items: flex-start;
    position: absolute;
    z-index: 10000;
    background-color: #fff;
    border: 1px solid #f2f2f2;
    width: 240px;
    list-style: none;
    margin-top: 17px;
    left: -202px;
    color: #7c7b7b;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
    .overflow-menu .overflow-menu-options:focus {
      outline: none;
      border: none; }
    .overflow-menu .overflow-menu-options.is-open {
      display: -ms-flexbox;
      display: flex; }
    .overflow-menu .overflow-menu-options:before {
      content: '';
      position: absolute;
      display: block;
      width: 15px;
      height: 15px;
      z-index: -1;
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      background-color: #fff;
      border-top: 1px solid #f2f2f2;
      border-left: 1px solid #f2f2f2;
      top: -9px;
      right: 24px; }
    .overflow-menu .overflow-menu-options .overflow-menu-head {
      border-bottom: 1px solid #f2f2f2;
      width: 100%;
      padding: 2rem 1rem;
      color: #000; }
    .overflow-menu .overflow-menu-options .overflow-menu-bottom {
      margin-bottom: 0;
      padding: 0.5rem 1rem 1rem; }
    .overflow-menu .overflow-menu-options .overflow-menu-option {
      display: -ms-flexbox;
      display: flex;
      background-color: transparent;
      -ms-flex-align: center;
          align-items: center;
      width: 100%;
      padding: 0 1rem;
      margin-bottom: 0; }
      .overflow-menu .overflow-menu-options .overflow-menu-option.is-active {
        background-color: #000;
        border-bottom: 1px solid #000;
        color: #fff; }
        .overflow-menu .overflow-menu-options .overflow-menu-option.is-active:hover {
          background-color: #000;
          border-bottom: 1px solid #000;
          color: #fff; }
      .overflow-menu .overflow-menu-options .overflow-menu-option:hover {
        color: #000;
        background-color: #f2f2f2;
        border-bottom: 1px solid #f2f2f2; }
      .overflow-menu .overflow-menu-options .overflow-menu-option.is-disabled {
        pointer-events: none; }
      .overflow-menu .overflow-menu-options .overflow-menu-option a {
        padding: 11px 5px 5px; }
    .overflow-menu .overflow-menu-options .overflow-menu-option:after {
      content: '';
      padding-top: 49px;
      position: absolute;
      width: 90%;
      border-bottom: 1px solid #f2f2f2; }
