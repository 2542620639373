.container {
  max-width: 1270px;
  margin: 0 auto; }
  @media (max-width: 990px) {
    .container {
      overflow-x: hidden; } }

.card {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem; }
  @media (max-width: 990px) {
    .card {
      padding: 2rem 0; } }

.card-small {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem;
  font-family: 'GT America Standard Regular'; }

.claims-new {
  background-color: #eff3fe; }
  @media (max-width: 575.98px) {
    .claims-new h1 {
      font-size: 1.2rem; } }

.help {
  font-size: 1.5rem;
  color: #000;
  vertical-align: text-top; }
  .help:hover {
    color: #0080d6; }

@media (max-width: 767.98px) {
  .hiddenSm {
    display: none !important; } }

@media (min-width: 768px) {
  .hiddenMd {
    display: none !important; } }

.githubSection {
  margin-top: 100px; }

.list-class {
  list-style: circle !important;
  margin-left: 10%; }

.submit-code {
  font-size: 20px;
  font-family: 'GT America Extended Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: lighter;
  cursor: pointer; }
  .submit-code a {
    text-decoration: none; }
    .submit-code a:hover {
      color: #000; }

@media (max-width: 560px) {
  .submit-code {
    font-size: 14px;
    font-family: 'GT America Extended Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: lighter;
    cursor: pointer; }
  .instruction {
    margin-top: 5%; }
  .tada {
    font-size: 16px; } }

.coding-language {
  font-family: 'GT America Standard Regular' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  padding: 8px 20px !important;
  color: #000 !important; }
  .coding-language input::-webkit-input-placeholder {
    color: #818181 !important; }
  .coding-language input::-ms-input-placeholder {
    color: #818181 !important; }
  .coding-language input::placeholder {
    color: #818181 !important; }

.email {
  padding: 10px; }

.tada {
  font-size: 50px; }

.take-an-assignment {
  background-color: inherit !important;
  border: 1px solid;
  outline: none; }
  .take-an-assignment:hover {
    border: 1px solid;
    color: black;
    background-color: #f5f5f5 !important; }

.ordered-list {
  font-family: 'GT America Standard Regular';
  padding-left: 1rem; }
  .ordered-list > li {
    padding: 6px; }

.resouces p {
  color: #9b9b9b;
  font-family: 'GT America Standard Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px; }

.resouces h3 {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px; }

.resouces a {
  text-decoration: none; }

.cv-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px; }
  @media (max-width: 990px) {
    .cv-text {
      -ms-flex-direction: column;
          flex-direction: column; } }

.drop-zone {
  border: none;
  height: auto;
  cursor: pointer; }

.upload-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px;
  min-width: 121px;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #000000;
  background-color: #fff;
  font-family: 'GT America Standard Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  cursor: pointer; }
  @media (max-width: 500px) {
    .upload-btn {
      min-width: 180px; } }
  .upload-btn:focus {
    outline: 0; }

.tnc {
  max-width: 357px;
  color: #6c757d;
  font-family: 'GT America Standard Regular';
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  margin: 10px auto; }

.text-input {
  font-family: 'GT America Standard Regular' !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  padding: 14px 20px !important;
  color: #000 !important; }
  .text-input input::-webkit-input-placeholder {
    color: #818181 !important; }
  .text-input input::-ms-input-placeholder {
    color: #818181 !important; }
  .text-input input::placeholder {
    color: #818181 !important; }

.submit-btn {
  font-family: 'GT America Standard Medium';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  background-color: #000000;
  color: #fff; }
  .submit-btn:disabled {
    color: #000000 !important;
    background-color: #f1f1f1 !important;
    opacity: 1 !important; }

.blue-background {
  min-height: 300px;
  width: 100%;
  /*background-color: #2a2498;*/
  /*overflow: hidden;*/
  color: #fff;
  /*position: relative;*/ }
  .blue-background .title-content {
    /*border: 1px solid red;*/ }
    .blue-background .title-content h6 {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 22px;
      letter-spacing: 0;
      line-height: 31px;
      text-align: center; }
      @media (max-width: 990px) {
        .blue-background .title-content h6 {
          font-size: 16px;
          max-width: 300px;
          margin: 1em auto; } }
  .blue-background h2 {
    font-family: 'GT America Mono Medium';
    font-size: 40px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center;
    position: absolute;
    left: 38%; }
    .blue-background h2 > span {
      margin-left: 0.3em; }
    @media (max-width: 990px) {
      .blue-background h2 {
        font-size: 24px;
        position: relative;
        left: 0;
        right: 0; }
        .blue-background h2 span {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-pack: center;
              justify-content: center;
          -ms-flex-align: center;
              align-items: center;
          margin-left: 0; } }
  .blue-background h6 {
    font-family: 'GT America Standard Regular';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center;
    margin-top: 3em;
    position: absolute;
    left: 0;
    right: 0; }
    @media (max-width: 990px) {
      .blue-background h6 {
        font-size: 16px;
        max-width: 300px;
        margin: 1em auto; } }
  @media (max-width: 990px) {
    .blue-background {
      min-height: 380px; } }

.highlight {
  /*background: #2a2498;*/
  background-image: url("https://indorse-staging-bucket.s3.amazonaws.com/diwali-2020-claim.png");
  background-repeat: no-repeat;
  background-size: auto 360px; }
  @media (max-width: 550px) {
    .highlight {
      min-height: 360px; } }

.success-body h2 {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px; }
  @media (max-width: 550px) {
    .success-body h2 {
      font-size: 24px; } }

.success-body p {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 32px; }
  @media (max-width: 550px) {
    .success-body p {
      font-size: 16px;
      line-height: 22px; } }

.success-body .btn {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px; }
  @media (max-width: 550px) {
    .success-body .btn {
      font-size: 16px;
      line-height: 22px; } }

.left {
  color: #ffffff;
  font-family: 'GT America Mono Medium';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
