.background {
  background-color: #eff2f7; }

.card-profile {
  margin: 0 auto;
  min-height: 700px;
  margin-right: 0;
  width: 100%; }

.credits {
  display: -ms-flexbox;
  display: flex;
  color: #44b1a4; }
  .credits i {
    margin-top: 11px; }
  .credits h3 {
    margin: auto 5px; }
  .credits a {
    margin-left: auto;
    font-size: 12px; }

.fixed-menu {
  min-height: 700px; }

.highlight-section {
  color: 'green'; }

.menu-item {
  font-size: 16px; }
  .menu-item h5 {
    font-family: 'GT America Standard Medium'; }

.line {
  width: 300px;
  background-color: #ebf2f7;
  height: 2px;
  margin-top: 0; }

.company-name {
  font-family: 'GT America Expanded Medium';
  font-size: 1.35rem;
  line-height: 30px; }

.title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 28px;
  line-height: 48px; }
