.card {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  text-align: center; }

.card:hover {
  -webkit-box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  cursor: pointer; }

.icon {
  display: block;
  margin: auto; }

.hoverText:hover {
  color: black;
  cursor: pointer; }
