/* prettier-ignore */
@font-face {
  font-family: 'GT America Light';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Bold';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Medium';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Medium.otf") format("opentype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Regular';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.ttf") format("truetype"); }

body {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em; }

body,
input,
textarea,
select,
button {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000; }

h1,
h2,
h3,
h4,
h5,
h5,
h6,
p,
span,
a {
  text-rendering: optimizelegibility;
  font-weight: normal;
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0; }

h1.gray,
h2.gray,
h3.gray,
h4.gray,
h5.gray,
h6.gray,
p.gray,
span.gray {
  color: #7c7b7b; }

h1.uppercase,
h2.uppercase,
h3.uppercase,
h4.uppercase,
h5.uppercase,
h6.uppercase,
p.uppercase,
span.uppercase {
  text-transform: uppercase; }

h1.text-centered,
h2.text-centered,
h3.text-centered,
h4.text-centered,
h5.text-centered,
h6.text-centered,
p.text-centered,
span.text-centered {
  text-align: center; }

.test {
  color: pink; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
p,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

button.metamorph-btn {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.pill {
  height: 35px;
  border-radius: 17.5px;
  background-color: #ffffff;
  color: #000000;
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  display: -ms-flexbox;
  display: flex;
  /* justify-content: space-around;*/
  -ms-flex-align: center;
      align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 10px;
  margin-right: 10px;
  -webkit-box-shadow: 0 0 10px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 10px 0 rgba(20, 46, 110, 0.1); }
  .pill.active {
    color: #fff;
    background-color: #0052cc; }
  .pill .pill-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center; }
  .pill .pill-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    margin-left: 10px; }
