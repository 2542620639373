.page {
  height: 100% !important; }

.page-row {
  height: 100% !important; }

.tada-img {
  width: 240px;
  margin: 0 auto; }

.submitBtn {
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0.4rem 2.5rem 0.5rem;
  border-radius: 38px; }

.heading {
  font-family: 'GT America Expanded Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #2a2498;
  font-size: 80px;
  line-height: 80px;
  letter-spacing: 5px;
  font-weight: 900; }

.sub-title {
  color: #202022;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center; }
