.text-large {
  font-size: 15px; }

.poor-great {
  width: 150px; }

.helpButton {
  border: 1px solid #ccc;
  -webkit-box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  padding: 5px;
  min-width: 100px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  /*font-weight: 500;*/
  letter-spacing: 0;
  line-height: 24px; }

.ratingsContainer {
  -ms-flex-direction: column;
      flex-direction: column;
  min-width: 320px; }
