.member-card {
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  display: block;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  padding: 1rem;
  min-height: 5rem; }
  .member-card:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
    color: #000; }

.avatar {
  width: 4rem;
  height: 4rem;
  line-height: 3.8rem; }

.border-top-bottom {
  border-top: 1.2px solid #7c7b7b;
  border-bottom: 1.2px solid #7c7b7b; }

.full-width {
  width: 100%; }

.circle-badge {
  position: absolute;
  width: 6rem;
  height: 6rem;
  line-height: 4.8rem;
  margin: -1.5em;
  top: 0rem;
  right: 0; }

.custom-slider .slick-arrow .slick-next {
  width: 0 !important;
  right: 0 !important; }
