/*
	.classOne {
		background-color: #000;
	}
*/
.pageTitle {
  font-size: 35px; }

.pageDescription {
  font-size: 15px;
  text-align: center;
  padding: 16px;
  line-height: 20px; }

.description {
  line-height: 20px; }

.external_link {
  text-decoration: none; }
  .external_link:hover {
    text-decoration: none; }

.reject {
  font-size: 20px;
  line-height: 25px; }

.email {
  padding: 10px;
  padding-bottom: 10px !important; }

.submitBtn {
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }
