/* section layout */
.title {
  font-family: 'GT America Extended Bold';
  color: #000000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: left;
  max-width: 500px;
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .title {
      max-width: 378px;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 34px;
      margin-left: auto;
      margin-right: auto; } }

.subtitle {
  font-family: 'GT America Standard Regular';
  margin: 0 auto 60px;
  max-width: 731px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px; }
  @media (max-width: 767.98px) {
    .subtitle {
      max-width: 322px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin: 0 auto; } }

.infobox .info {
  max-width: 100%;
  font-family: 'GT America Standard Regular';
  font-size: 21px;
  letter-spacing: 0;
  line-height: 31px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 14px; }

@media (max-width: 767.98px) {
  .infobox .info {
    max-width: 333px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 26px;
    margin: 0 auto;
    /* text-align: center;*/ } }

.placeholder {
  height: 400px;
  width: 80%;
  border-radius: 24px;
  background-color: #dae4ff;
  margin-right: auto; }
  @media (max-width: 500px) {
    .placeholder {
      width: 100%;
      height: 300px; } }

.demoBtn {
  border-radius: 4px;
  background-color: #000000;
  padding: 0.7rem 2rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 200px;
  border: none;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .demoBtn:focus {
    outline: none; }
  @media (max-width: 500px) {
    .demoBtn {
      margin: 0 auto 3em;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: center;
          align-items: center; } }

.blue-box {
  height: 100%;
  width: 100%;
  border-radius: 5px;
  background: -webkit-linear-gradient(315deg, #f6f8fe 0%, #dae4ff 100%);
  background: -o-linear-gradient(315deg, #f6f8fe 0%, #dae4ff 100%);
  background: linear-gradient(135deg, #f6f8fe 0%, #dae4ff 100%);
  margin: auto;
  padding: 45px 35px 0 60px; }
