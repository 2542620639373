.sidebar-container {
  position: fixed;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transform: translateX(calc(-100% + 36px));
      -ms-transform: translateX(calc(-100% + 36px));
          transform: translateX(calc(-100% + 36px));
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  background: white;
  top: 50vh;
  left: 0;
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s;
  transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s;
  -o-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s;
  transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s, -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) 1.5s; }
  .sidebar-container:hover {
    -webkit-transform: translateX(0);
        -ms-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    -o-transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.5s cubic-bezier(0.165, 0.84, 0.44, 1); }

.sidebar-item {
  padding: 10px;
  display: list-item; }
  .sidebar-item:hover {
    background: #f2f2f2;
    cursor: pointer; }

.sidebar-item * {
  display: inline-block; }
