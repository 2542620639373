.heading {
  color: #000000;
  font-size: 60px;
  letter-spacing: 0;
  line-height: 75px;
  text-align: center;
  /*font-weight: 900;*/
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.sub-title {
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.session-title {
  font-size: 24px;
  /*font-weight: 900;*/
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.support-text {
  font-size: 18px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.hero-img {
  height: 284px;
  width: 546px;
  margin: 0 auto; }

.tada-image {
  height: 213px;
  width: 231px;
  margin: 0 auto; }

.start-button {
  margin: 0 0.5rem;
  font-size: 18px;
  letter-spacing: 0;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.profileBtn {
  margin-right: 2rem; }
  .profileBtn h4 {
    font-size: 18px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.challengeBtn {
  margin-bottom: 1rem; }
  .challengeBtn h4 {
    font-size: 18px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

@media (max-width: 640px) {
  .tada-image {
    height: 151px;
    width: 164px;
    margin: 0 auto; }
  .heading {
    color: #000000;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 55px;
    text-align: center;
    /*font-weight: 900;*/
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .sub-title {
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .session-title {
    font-size: 20px;
    /*font-weight: 900;*/
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .hero-img {
    height: 145px;
    width: 280px;
    margin: 0 auto; }
  .tada-image {
    height: 213px;
    width: 231px;
    margin: 0 auto; }
  .start-button {
    margin: 0;
    font-size: 18px;
    letter-spacing: 0;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .profileBtn {
    margin-bottom: 1rem;
    margin-right: 0; }
    .profileBtn h4 {
      font-size: 14px; }
  .challengeBtn {
    margin-bottom: 1rem; }
    .challengeBtn h4 {
      font-size: 14px; } }
