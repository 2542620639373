.logo-container .logo {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  height: auto;
  /*height: 30px;*/
  width: 128px; }
  .logo-container .logo.logo-icon {
    height: 20px;
    width: 20px; }
