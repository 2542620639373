.social-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  width: 280px; }

.google-btn {
  color: #ffffff;
  background-color: #db4437; }
  .google-btn:hover {
    background-color: #db5549; }
