.social-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  width: 280px; }

.facebook-btn {
  color: #ffffff;
  background-color: #3b5998; }
  .facebook-btn:hover {
    background-color: #516ba5; }
