.faq {
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .faq h1,
  .faq h2,
  .faq h3,
  .faq .h1,
  .faq .h2,
  .faq .h3 {
    font-weight: 100; }
    .faq h1 strong,
    .faq h2 strong,
    .faq h3 strong,
    .faq .h1 strong,
    .faq .h2 strong,
    .faq .h3 strong {
      font-weight: 300; }

.hero {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.topic h2 {
  font-weight: 300; }

.yellow-bg-color {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01); }

.question {
  margin: 1em 0 0;
  /* font-weight: 900;*/
  font-family: 'GT America Standard Medium'; }

.answer {
  padding-left: 0.7em; }

.card {
  width: 610px; }
  @media (max-width: 600px) {
    .card {
      width: 380px; } }
