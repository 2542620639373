.slider-container {
  max-width: 37.5rem;
  min-width: 12.5rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 20px; }
  .slider-container .slider {
    position: relative;
    width: 100%; }
    .slider-container .slider:focus {
      outline: none;
      border: 1px solid #f2f2f2; }
    .slider-container .slider .slider-track {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #f2f2f2;
      cursor: pointer;
      -webkit-transform: translate(0%, -50%);
          -ms-transform: translate(0%, -50%);
              transform: translate(0%, -50%); }
    .slider-container .slider .slider-filled-track {
      position: absolute;
      width: 100%;
      height: 1px;
      background: #000;
      -webkit-transform-origin: left;
          -ms-transform-origin: left;
              transform-origin: left;
      pointer-events: none;
      -webkit-transform: translate(0%, -50%);
          -ms-transform: translate(0%, -50%);
              transform: translate(0%, -50%);
      -webkit-transition: height 0.2s ease;
      -o-transition: height 0.2s ease;
      transition: height 0.2s ease; }
    .slider-container .slider .slider-thumb {
      position: absolute;
      height: 10px;
      width: 10px;
      background: #000;
      border-radius: 50%;
      top: 0;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
      -webkit-transition: height 0.2s ease, width 0.2s ease, -webkit-transform 0.2s ease;
      transition: height 0.2s ease, width 0.2s ease, -webkit-transform 0.2s ease;
      -o-transition: transform 0.2s ease, height 0.2s ease, width 0.2s ease;
      transition: transform 0.2s ease, height 0.2s ease, width 0.2s ease;
      transition: transform 0.2s ease, height 0.2s ease, width 0.2s ease, -webkit-transform 0.2s ease;
      -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;
      outline: none; }
  .slider-container:not(.is-disabled):hover .slider-filled-track {
    height: 2px; }
  .slider-container:not(.is-disabled):hover .slider-thumb {
    height: 24px;
    width: 24px; }
