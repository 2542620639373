/*
	.classOne {
		background-color: #000;
	}
*/
.background {
  background-color: #ffffff; }

.close {
  width: 40px;
  height: 40px;
  padding: 6px 0; }

.bold-text {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.sw-title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.boast-banner {
  background-color: #eff3fe;
  border-radius: 5px; }
  .boast-banner .emptySpace {
    width: 100px;
    height: 5px;
    margin: 3rem auto 1rem;
    border-bottom: 2px solid #e0e0e0; }
  .boast-banner img {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%); }

.info {
  border: 0;
  width: 50px;
  height: 50px;
  background: black;
  color: white;
  border-radius: 25px;
  /*padding: 16px 0;*/
  position: fixed;
  bottom: 1.5rem;
  left: 1.5rem;
  z-index: 500;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07); }
  .info:hover {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s;
    -moz-transition: 0.2s;
    cursor: pointer; }
  .info:active {
    -webkit-transform: scale(1.1);
        -ms-transform: scale(1.1);
            transform: scale(1.1); }

.infoPop {
  width: 500px;
  /*margin-top: 4px;*/
  margin-left: 1rem;
  margin-bottom: 0.5rem;
  padding: 2rem;
  z-index: 500;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  font-size: 16px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07); }
  .infoPop p li {
    font-size: 16px;
    line-height: 22px; }

.platformCard {
  margin: 1rem 2rem 1rem 0;
  text-align: center;
  -webkit-box-shadow: rgba(221, 230, 237, 0.5) 0px 16px 32px 0px;
          box-shadow: rgba(221, 230, 237, 0.5) 0px 16px 32px 0px;
  border-radius: 8px;
  width: 190px;
  padding: 2rem 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 2px solid black; }
  .platformCard h5 {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .platformCard:hover {
    -webkit-box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
            box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px; }
