.form-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  width: 100%; }
  .form-item.prefix, .form-item.suffix {
    -ms-flex-direction: row;
        flex-direction: row; }
    .form-item.prefix .text-input-label, .form-item.suffix .text-input-label {
      -webkit-transform: none;
          -ms-transform: none;
              transform: none; }
  .form-item.prefix .text-input-label {
    margin-right: 15px; }
  .form-item.suffix .text-input-label {
    -ms-flex-order: 2;
        order: 2;
    margin-left: 15px; }
  .form-item .text-input {
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border-radius: 0;
    display: -ms-flexbox;
    display: flex;
    padding-right: 25px;
    border: 0;
    padding-bottom: 7px;
    border-bottom: 1px solid #7c7b7b;
    -ms-flex: 1 1 auto;
        flex: 1 1 auto;
    -ms-flex-order: 2;
        order: 2;
    position: relative; }

/* .form-item input:not(:focus):not(.is-valid):not(.is-invalid) {*/
/*   color: transparent;*/
/* }*/
.form-item input,
.form-item label,
.form-requirement {
  -webkit-font-smoothing: antialiased;
  text-shadow: none; }

.text-input-label {
  color: #7c7b7b;
  font-weight: normal;
  opacity: 0.75;
  -ms-flex-order: 1;
      order: 1;
  padding-left: 2px;
  pointer-events: none;
  /*text-transform: capitalize;*/
  -webkit-transform-origin: left top;
      -ms-transform-origin: left top;
          transform-origin: left top;
  -webkit-transform: scale(1) translate3d(0, 22px, 0);
          transform: scale(1) translate3d(0, 22px, 0);
  -webkit-transition: 200ms ease all;
  -o-transition: 200ms ease all;
  transition: 200ms ease all; }

.form-item input:focus + label,
.form-item input.is-valid + label,
.is-invalid + label,
.has-placeholder {
  color: #000;
  opacity: 1;
  -webkit-transform: scale(0.65) translate3d(0, 0, 0);
          transform: scale(0.65) translate3d(0, 0, 0); }

.form-item input:focus + label,
.form-item input.is-valid + label,
.is-invalid + label {
  color: #000;
  opacity: 1;
  -webkit-transform: scale(0.65) translate3d(0, 0, 0);
          transform: scale(0.65) translate3d(0, 0, 0); }

.text-input-bar {
  display: block;
  -ms-flex-order: 3;
      order: 3;
  top: 0; }

.text-input:active,
.text-input:focus,
.text-input.is-valid {
  border-bottom: 1px solid #000; }

.text-input:active,
.text-input:focus,
.text-input-label {
  outline: 0; }

.text-input-bar::after,
.text-input-bar::before {
  bottom: 0;
  content: '';
  height: 2px;
  position: absolute;
  -webkit-transition: 200ms ease all;
  -o-transition: 200ms ease all;
  transition: 200ms ease all;
  width: 0; }

.text-input-bar::before {
  left: 50%; }

.text-input-bar::after {
  right: 50%; }

.text-input:focus ~ .text-input-bar::after,
.text-input:focus ~ .text-input-bar::before,
.is-invalid ~ .text-input-bar::after,
.is-invalid ~ .text-input-bar::before {
  width: 50%; }

.text-input-bar,
.form-requirement {
  position: relative;
  width: inherit; }

.form-requirement {
  bottom: 0;
  display: none;
  font-size: 13px;
  overflow: hidden;
  position: absolute;
  -o-text-overflow: ellipsis;
     text-overflow: ellipsis;
  white-space: nowrap;
  width: 90%;
  word-break: break-all;
  word-wrap: break-word;
  background: #ff0000;
  height: 17px;
  right: 0;
  border-radius: 50%; }

.fl-error-show {
  display: inline-block; }

.is-invalid ~ .text-input-bar::after,
.is-invalid ~ .text-input-bar::before {
  background: #ff0000; }

.form-item .text-input.is-invalid + label,
.form-requirement {
  color: #ff0000; }

input[type='password'] {
  background-color: transparent; }

input[type='email'] {
  background-color: transparent; }
