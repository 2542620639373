.teams .title-bar {
  position: relative;
  margin-bottom: 1rem; }
  .teams .title-bar .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0; }

.listContainer {
  padding: 2rem;
  min-height: 700px;
  overflow: hidden; }
  .listContainer table {
    width: 100%;
    margin-top: 2rem; }
    .listContainer table thead th {
      padding: 0.6rem 1rem;
      color: #fff;
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px;
      text-align: left;
      background-color: #18181c; }
    .listContainer table tbody tr {
      border: none !important; }
    .listContainer table tbody td {
      padding: 0.6rem 1rem;
      color: #000;
      font-family: 'GT America Standard Regular', sans-serif;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px;
      text-align: left; }
    .listContainer table tbody tr:nth-child(2n) {
      /*padding: 0 1rem;*/
      background-color: rgba(0, 82, 204, 0.05); }
    .listContainer table tbody tr:last-child {
      border-bottom: 1px solid rgba(0, 82, 204, 0.6); }

.card {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
  padding: 2rem; }
  .card .main {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px; }
    .card .main .blue {
      color: #0065d6; }
  .card .title {
    color: #000;
    font-family: 'GT America Standard Regular', sans-serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px; }
  .card .creator {
    /* height: 21px;*/
    /* width: 192px;*/
    color: #81898f;
    font-family: 'GT America Standard Regular';
    font-size: 16px;
    letter-spacing: 0;
    line-height: 21px; }
  .card .companyName {
    font-family: 'GT America Standard Medium'; }

.custom-tabs {
  font-family: 'GT America Standard Regular', sans-serif; }

.teamCard {
  margin-bottom: 2rem; }
  .teamCard .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0; }

.emptyState {
  text-align: center;
  padding: 4rem 1rem; }
  .emptyState .placeholderImg {
    margin: 2rem auto 0;
    height: 400px; }
  .emptyState .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: -2rem 0 0; }
  .emptyState p {
    margin: 2rem auto 3rem;
    width: 511px;
    color: #000000;
    font-family: 'GT America Standard Regular', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center; }

.modal .highlight {
  color: #0048d3;
  font-family: 'GT America Standard Bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: 0 auto 1rem; }

.modal .title {
  color: #000000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin: 0; }

.modal .secondary-title {
  color: #000000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  text-align: left; }

.modal .planBox {
  margin: 4rem 0; }
  .modal .planBox .plansGrid {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2rem;
    -ms-flex-pack: justify;
        justify-content: space-between; }
    .modal .planBox .plansGrid .planCard {
      background-color: #f1f6f9;
      border-radius: 5px;
      padding: 2rem 0.5rem; }
      .modal .planBox .plansGrid .planCard .billBox {
        position: relative;
        margin: 0 auto 1.5rem; }
        .modal .planBox .plansGrid .planCard .billBox .bill {
          color: #000000;
          font-family: 'GT America Standard Medium', sans-serif;
          font-size: 42px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 42px;
          text-align: center;
          /*display: inline-block;*/
          margin-bottom: 0.3rem; }
          .modal .planBox .plansGrid .planCard .billBox .bill .small {
            font-size: 20px;
            line-height: 20px;
            display: inline-block;
            position: relative;
            top: -1rem; }
      .modal .planBox .plansGrid .planCard .planTitle {
        color: #000000;
        font-family: 'GT America Standard Medium', sans-serif;
        font-size: 20px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 30px;
        text-align: center;
        margin-bottom: 1rem; }
      .modal .planBox .plansGrid .planCard .getStartedBtn {
        margin-bottom: 2rem;
        /*color: #ffffff;*/
        font-size: 14px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 22px;
        padding: 0.4rem 1.5rem 0.5rem;
        border-radius: 38px; }
      .modal .planBox .plansGrid .planCard .feature {
        font-family: 'GT America Standard Regular', sans-serif;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin: 0 0 0.8rem; }

.modal .sub-title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  /*letter-spacing: 1px;*/
  line-height: 32px; }

.switchLabel {
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px; }

.activeTxt {
  color: #0052cc; }

.billingCard {
  /*background-color: rgba(0, 82, 204, 0.1);*/
  /*border-radius: 5px;*/ }
  .billingCard .grey {
    color: #94a0a4; }
  .billingCard table {
    width: 100%; }
    .billingCard table tbody tr {
      border: none !important; }
    .billingCard table tbody td {
      padding: 0.5rem 0;
      color: #000;
      font-family: 'GT America Standard Regular', sans-serif;
      font-size: 15px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 22px; }
      .billingCard table tbody td:nth-child(2n + 1) {
        width: 150px;
        /*border: 1px solid red;*/ }
      .billingCard table tbody td:nth-child(2n) {
        /*font-family: 'GT America Standard Medium', sans-serif;*/
        text-align: right; }

.detailsGrid {
  margin: 2rem;
  display: grid;
  grid-template-columns: 400px 1fr;
  gap: 1rem; }

.thankYou {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  /*letter-spacing: 1px;*/
  line-height: 32px; }
