.blue-background {
  background-color: #eff3fe; }

.main-title {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 28px; } }

.main-subtitle {
  font-family: 'GT America Standard Regular';
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.how-it-works-title {
  color: #2a2498;
  font-size: 28px;
  letter-spacing: -0.08px;
  line-height: 32px; }

.tab-container {
  -ms-flex-align: end;
      align-items: flex-end; }

.tab-item {
  padding: 1rem;
  min-height: 88px;
  text-align: center;
  background-color: #dde3f2;
  color: black;
  font-family: 'GT America Expanded Regular'; }
  .tab-item:hover {
    cursor: pointer; }
  .tab-item .visible {
    background-color: white; }
  .tab-item h2 {
    color: #2a2498;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0; }
  .tab-item:hover {
    cursor: pointer;
    background-color: white; }
  .tab-item.tab-selected {
    height: 127px;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff; }
    .tab-item.tab-selected h2 {
      font-family: 'GT America Expanded Bold';
      color: #2a2498;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      font-weight: 900; }

.card {
  height: 517px;
  width: 1240px;
  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1); }
  .card .content {
    margin: auto 125px;
    height: 100%; }
    .card .content .content-left,
    .card .content .content-right {
      margin: auto; }
    .card .content .content-right .info {
      max-width: 426px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      margin-left: auto; }
    .card .content .content-right .list {
      margin-left: 2em;
      font-family: 'GT America Standard Medium'; }
    .card .content .content-right p {
      font-weight: 900 !important; }
  .card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px; }
  .card p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.28px;
    line-height: 28px; }
  @media (max-width: 500px) {
    .card {
      height: auto;
      max-width: 100%; }
      .card .content {
        margin: 20px; }
      .card p {
        font-size: 16px;
        font-family: 'GT America Standard Medium'; } }

.large-card {
  max-width: 731px;
  border-radius: 5px;
  background-color: #eff3fe;
  padding: 3em; }
  .large-card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: -0.07px;
    line-height: 32px; }

.heading {
  color: #2a2498;
  font-family: 'GT America Expanded Bold';
  margin-top: 1em;
  margin-bottom: 0;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 19px; }

.headingCard {
  border-radius: 5px;
  background-color: #eff3fe; }
