.slackBtn {
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0;
  font-family: 'GT America Standard Medium';
  background-color: #ffffff !important;
  color: black !important;
  border: 2px solid black;
  padding: 3px 25px; }

.btn-text {
  font-size: 18px;
  font-weight: 500; }
