/*
	.classOne {
		background-color: #000;
	}
*/
.heading-details {
  background-color: #f1f6f9; }
  .heading-details h5 {
    color: #000000;
    font-family: 'GT America Bold', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.2px;
    line-height: 22px;
    margin: 0 1rem; }

.data-container {
  padding: 1rem;
  min-width: 200px; }
  .data-container h3 {
    color: #000000;
    font-size: 16px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: 500;
    margin-bottom: 1rem; }
  .data-container p {
    color: #000000;
    font-family: 'GT America', sans-serif;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px; }

@media (max-width: 640px) {
  .data-container {
    padding: 0.5rem;
    min-width: 200px; }
    .data-container h3 {
      color: #000000;
      font-size: 14px;
      font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-weight: 500;
      margin-bottom: 1rem; }
    .data-container p {
      color: #000000;
      font-size: 14px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 16px; } }
