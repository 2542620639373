/* section layout */
.home {
  overflow-x: hidden;
  /* layout*/
  /*typography*/ }
  .home .mt-l {
    margin-top: 7rem; }
  .home .mb-l {
    margin-bottom: 7rem; }
  .home .mt-xl {
    margin-top: 10rem; }
  .home .mb-xl {
    margin-bottom: 10rem; }
  .home .typography-p-large {
    font-size: 20px;
    line-height: 1.4em; }
  .home .typography-hl-extra {
    font-size: 52px;
    line-height: 1.1em; }

.auto-greyscale-img-wrapper img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.55; }
  .auto-greyscale-img-wrapper img:hover {
    -webkit-filter: none;
            filter: none;
    opacity: 1; }
