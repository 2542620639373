/*
	.classOne {
		background-color: #000;
	}
*/
.chart-heading {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  line-height: 32px; }

.survey-chart-title {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  /*font-weight: 500;*/
  letter-spacing: 0.23px;
  line-height: 22px; }

.chart-category .title {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.21px;
  line-height: 12px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #0052cc; }

.chart-category .chart-heading-mobile {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 22px; }

.dummy-banner {
  border: 1px solid #ffc800;
  border-radius: 5px;
  background-color: rgba(255, 200, 0, 0.21);
  padding: 0.3rem 1rem; }
