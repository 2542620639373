/*
	.classOne {
		background-color: #000;
	}
*/
.panel-card {
  min-height: 52em; }

.header {
  font-size: 24px;
  font-weight: 600;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.table td {
  vertical-align: middle; }
