/*
	.classOne {
		background-color: #000;
	}
*/
.url {
  font-size: 16px; }

.submission-link {
  display: -ms-flexbox;
  display: flex; }
  @media (max-width: 560px) {
    .submission-link {
      display: unset; } }
