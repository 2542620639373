.community-section {
  text-align: center;
  padding-top: 3rem; }
  .community-section .title {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 575px) {
      .community-section .title {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 24px; } }
  .community-section .subTitle {
    margin: 0 auto;
    max-width: 720px;
    color: #000000;
    font-family: 'GT America Standard Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 575px) {
      .community-section .subTitle {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        width: 344px; } }
  .community-section .globeBg {
    background-image: url("https://indorse-staging-bucket.s3.amazonaws.com/globe.png");
    /*background-size: cover;*/
    background-size: 100% auto;
    height: 581px;
    width: 1189px;
    margin: 3rem auto;
    padding: 5rem 0; }
    @media (max-width: 990px) {
      .community-section .globeBg {
        background-image: none;
        height: auto;
        width: auto;
        padding: 0; } }
    .community-section .globeBg .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10rem;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      @media (max-width: 990px) {
        .community-section .globeBg .grid {
          grid-template-columns: 1fr;
          gap: 2rem; } }
      .community-section .globeBg .grid .item {
        /*border: 1px solid red;*/ }
        .community-section .globeBg .grid .item .title {
          color: #2a2498;
          font-family: 'GT America Extended Bold';
          font-size: 60px;
          letter-spacing: 0;
          line-height: 60px; }
          .community-section .globeBg .grid .item .title .minor {
            font-size: 24px;
            line-height: 24px; }
        .community-section .globeBg .grid .item .desc {
          color: #2a2498;
          font-family: 'GT America Extended Bold';
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px; }
          @media (max-width: 990px) {
            .community-section .globeBg .grid .item .desc {
              width: 250px;
              margin: 0 auto; } }
