.subtitle {
  font-size: 20px;
  line-height: 25px; }

.product-card {
  padding: 2%;
  text-align: center; }

.how-it-works {
  font-size: 25px; }

.h3 {
  font-size: 16px;
  line-height: 22px; }

.min-height-desktop {
  min-height: 500px !important; }
