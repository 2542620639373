.help {
  margin-top: 3rem;
  padding-bottom: 2rem; }
  .help .slider {
    width: 754px; }
  .help .heading {
    text-align: center; }
    .help .heading .title {
      color: #000000;
      font-family: 'GT America Extended Bold';
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center; }
      @media (max-width: 575px) {
        .help .heading .title {
          font-size: 20px;
          letter-spacing: 0;
          line-height: 24px; } }
    .help .heading .subTitle {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center; }
      @media (max-width: 575px) {
        .help .heading .subTitle {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px; } }
  .help .featureList .selectedBox {
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.192);
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.192); }
  .help .featureList .collapsableImg {
    text-align: center;
    margin: 0 auto; }
    @media (max-width: 1280px) {
      .help .featureList .collapsableImg {
        width: 600px; } }
    @media (max-width: 575px) {
      .help .featureList .collapsableImg {
        max-width: 300px; } }
  .help .featureList .featureBox {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    background-color: #ffffff;
    width: 431px;
    margin: 3rem 0;
    padding: 1.5rem 2rem;
    text-align: center;
    -webkit-transition: ease-in-out 0.2s;
    -o-transition: ease-in-out 0.2s;
    transition: ease-in-out 0.2s; }
    @media (max-width: 575px) {
      .help .featureList .featureBox {
        max-width: 300px;
        margin: 2rem auto;
        padding: 0.75rem 1rem; } }
    @media (max-width: 1280px) {
      .help .featureList .featureBox {
        margin: 2rem auto; } }
    .help .featureList .featureBox:hover {
      border: blue;
      -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
              box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
    .help .featureList .featureBox .info {
      text-align: left; }
      .help .featureList .featureBox .info h3 {
        font-family: 'GT America Extended Bold';
        color: #000000;
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px; }
      .help .featureList .featureBox .info p {
        color: #000000;
        font-family: 'GT America Light';
        font-size: 15px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 22px; }
      @media (max-width: 575px) {
        .help .featureList .featureBox .info h3 {
          font-family: 'GT America Extended Bold';
          color: #000000;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 20px; }
        .help .featureList .featureBox .info p {
          color: #000000;
          font-family: 'GT America Light';
          font-size: 12px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 20px; } }

.features {
  margin: 0 auto;
  padding: 5rem 0; }
  @media (max-width: 575px) {
    .features {
      padding: 2rem 0; } }
  .features .container {
    width: 1300px;
    margin: 0 auto; }
    @media (max-width: 1280px) {
      .features .container {
        width: 100%; } }
    .features .container .slider {
      width: 754px; }
    .features .container .heading {
      text-align: center; }
      .features .container .heading .title {
        color: #000000;
        font-family: 'GT America Extended Bold';
        font-size: 32px;
        letter-spacing: 0;
        line-height: 38px;
        text-align: center; }
        @media (max-width: 575px) {
          .features .container .heading .title {
            font-size: 20px;
            letter-spacing: 0;
            line-height: 24px; } }
      .features .container .heading .subTitle {
        color: #000000;
        font-family: 'GT America Standard Regular';
        font-size: 18px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center; }
        @media (max-width: 575px) {
          .features .container .heading .subTitle {
            width: 322px;
            margin: 0 auto;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px; } }
    .features .container .featureList .selectedBox {
      -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.192);
              box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.192); }
    .features .container .featureList .collapsableImg {
      text-align: center;
      margin: 0 auto; }
      @media (max-width: 1280px) {
        .features .container .featureList .collapsableImg {
          width: 600px; } }
      @media (max-width: 575px) {
        .features .container .featureList .collapsableImg {
          width: 300px; } }
    .features .container .featureList .featureBox {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-pack: justify;
          justify-content: space-between;
      cursor: pointer;
      border-radius: 5px;
      background-color: #ffffff;
      width: 431px;
      margin: 3rem 0;
      padding: 1.5rem 2rem;
      text-align: center;
      -webkit-transition: ease-in-out 0.2s;
      -o-transition: ease-in-out 0.2s;
      transition: ease-in-out 0.2s; }
      @media (max-width: 575px) {
        .features .container .featureList .featureBox {
          width: 300px;
          margin: 2rem auto;
          padding: 0.75rem 1rem; } }
      @media (max-width: 1280px) {
        .features .container .featureList .featureBox {
          margin: 2rem auto; } }
      .features .container .featureList .featureBox:hover {
        border: blue;
        -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
      .features .container .featureList .featureBox .info {
        text-align: left; }
        .features .container .featureList .featureBox .info h3 {
          font-family: 'GT America Extended Bold';
          color: #000000;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 26px; }
        .features .container .featureList .featureBox .info p {
          color: #000000;
          font-family: 'GT America Light';
          font-size: 15px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 22px; }
        @media (max-width: 575px) {
          .features .container .featureList .featureBox .info h3 {
            font-family: 'GT America Extended Bold';
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 20px; }
          .features .container .featureList .featureBox .info p {
            color: #000000;
            font-family: 'GT America Light';
            font-size: 12px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20px; } }
