@media (max-width: 767.98px) {
  .profile {
    margin-left: 0;
    margin-right: 0;
    /* stylelint-disable selector-pseudo-class-no-unknown */
    /* h1,*/
    /* :global(.h1) {*/
    /*   font-size: 2rem;*/
    /* }*/
    /* h2,*/
    /* :global(.h2) {*/
    /*   font-size: 1.5rem;*/
    /* }*/
    /* h3,*/
    /* :global(.h3) {*/
    /*   font-size: 1.25rem;*/
    /* }*/
    /* :global(.lead) {*/
    /*   font-size: 1rem;*/
    /* }*/
    /* stylelint-enable selector-pseudo-class-no-unknown */ } }

.bgImage {
  /* background-image: url('resources/common/header.svg');*/
  background-size: auto 100%;
  background-repeat: repeat-x;
  height: 26vh; }

.header {
  background-color: #f8f9fa; }

.wordWrap {
  word-break: break-all; }

.verticalAlign {
  vertical-align: middle; }

.edit-basic-details-button-wrapper {
  float: right;
  position: absolute;
  right: 0.5rem;
  top: -0.5rem; }

.timelines-viz {
  background-color: #f8f9fa; }

.launch {
  /* background: linear-gradient(45deg, $cta-section-bg-gradient-start, $cta-section-bg-gradient-end);*/
  -webkit-box-shadow: 0px 5px 20px #888888;
          box-shadow: 0px 5px 20px #888888;
  border-style: solid;
  border-color: white;
  border-top: 2px;
  border-bottom: 2px;
  border-left: 0;
  border-right: 0; }
  .launch h1,
  .launch h2,
  .launch h3,
  .launch h4,
  .launch h5,
  .launch p {
    font-weight: 400;
    color: white; }

.badge-custom {
  color: #000;
  background-color: #f2f2f2;
  padding: 1rem 1.2rem; }

.badge-item {
  position: relative;
  text-align: center; }
  .badge-item .badge-image {
    position: relative;
    max-height: 10rem;
    max-width: 10rem; }
  .badge-item .badge-overlay {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.5); }
  .badge-item:hover .badge-overlay {
    display: -ms-flexbox;
    display: flex; }

.pending-badge img {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  filter: grayscale(100%); }

.click-here {
  font-size: inherit !important;
  font-weight: inherit !important;
  padding: 0 !important; }

.xl-btn {
  padding: 3rem; }

.social-links-list {
  list-style: none; }

.overflow-hidden {
  overflow: hidden; }

.validatorBadgeCover {
  width: 75px;
  height: 75px;
  margin: 21px 0px;
  float: right;
  position: absolute;
  top: -20px;
  right: 0px; }

.name {
  margin-right: 64px;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 32px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.headerItem {
  min-width: 230px;
  max-width: 230px; }

@media (max-width: 560px) {
  .headerTab {
    margin: auto;
    width: 70%; } }

/*p {*/
/*  font-size: 16px;*/
/*  line-height: 24px;*/
/*}*/
.head-box {
  background-color: #fff;
  border-radius: 5px;
  /*padding: 0 1rem;*/ }
  .head-box .challenge-box {
    background-color: #0052cc;
    border-radius: 0 5px 5px 0; }
    .head-box .challenge-box h1 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      color: #ffffff;
      font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
    .head-box .challenge-box p {
      color: #ffffff; }
  .head-box .challenge-box-mobile {
    background-color: #0052cc;
    border-radius: 0 0 5px 5px; }
    .head-box .challenge-box-mobile h1 {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      color: #ffffff;
      font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
    .head-box .challenge-box-mobile p {
      color: #ffffff; }

.ems-profile {
  background-color: #eff2f7;
  padding: 3rem 0 2rem; }
  .ems-profile .ems-header {
    width: 95%;
    max-width: 1220px;
    margin: 0 auto 2rem; }
    .ems-profile .ems-header .company-info {
      display: inline-block;
      border: 1px solid #cdd4d9;
      background-color: #f1f6f9;
      min-width: 100px; }
      @media (max-width: 640px) {
        .ems-profile .ems-header .company-info h3 {
          font-size: 10px;
          font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          line-height: 14px; } }
      @media (min-width: 640px) {
        .ems-profile .ems-header .company-info h3 {
          font-size: 14px;
          font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
          line-height: 20px; } }
  .ems-profile .ems-data {
    width: 95%;
    max-width: 1220px;
    margin: 0 auto 2rem; }

.ems-score {
  /*border-left: 1px solid #d6d6d6;*/
  padding-left: 1rem;
  height: 100%;
  margin: 0 1rem; }
  .ems-score h2 {
    font-size: 20px;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 0; }
  .ems-score .league {
    border-radius: 4px;
    background-color: rgba(0, 177, 164, 0.15);
    /*padding: 0.5rem 0;*/
    height: 100px;
    margin: 0.3rem 0;
    border: 1px solid #00b1a4; }
    .ems-score .league .score {
      color: #00b1a4;
      font-family: 'GT America Expanded Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 50px;
      letter-spacing: 0.28px;
      line-height: 63px;
      border-right: 1px solid #00b1a4;
      margin: 0; }
    .ems-score .league .status {
      color: #00b1a4;
      font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 24px; }
      .ems-score .league .status b {
        font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .ems-score .bold-button {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
