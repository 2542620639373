/*
	.classOne {
		background-color: #000;
	}
*/
.heading {
  font-size: 22px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center; }

.comment-text {
  font-size: 18px;
  line-height: 24px;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
