.title {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-align: center;
  margin: 4%;
  line-height: normal; }

.vertical-line {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  height: 200px;
  margin-top: 1%;
  /* margin-left: 3%;*/ }

@media (max-width: 560px) {
  .vertical-line {
    display: none; } }
