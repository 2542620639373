.title {
  font-size: 16px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.results {
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.message {
  font-size: 12px; }

.card-body {
  background: #f9fbfd;
  padding: 1em; }

.magicLinkDetails {
  display: flow-root; }

.assignmentRow {
  display: -ms-flexbox;
  display: flex; }
  .assignmentRow span h3 {
    font-size: 14px; }
  .assignmentRow span p {
    font-size: 12px; }
  .assignmentRow span .duration {
    color: #2552cb; }
  .assignmentRow .assignmentToggle {
    margin: 0 10px auto auto; }

.details {
  background-color: #f8f9fb; }
  .details p {
    font-size: 12px; }
