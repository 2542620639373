.ctaSection {
  /*height: 550px;*/
  /*width: 1211px;*/
  border-radius: 5px;
  background-color: #2a2498;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  padding: 2rem 4rem; }
  @media (max-width: 990px) {
    .ctaSection {
      padding: 2rem 1rem;
      margin: 1rem; } }
  .ctaSection .info {
    width: 420px; }
    @media (max-width: 990px) {
      .ctaSection .info {
        text-align: center;
        width: 300px;
        margin: auto; } }
    .ctaSection .info h1 {
      color: #ffffff;
      font-family: 'GT America Extended Bold';
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px; }
      @media (max-width: 990px) {
        .ctaSection .info h1 {
          font-size: 22px;
          letter-spacing: 0;
          line-height: 28px;
          text-align: center; } }
    .ctaSection .info p {
      /*height: 96px;*/
      /*width: 397px;*/
      color: #ffffff;
      font-family: 'GT America Standard Regular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px; }
      @media (max-width: 990px) {
        .ctaSection .info p {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
          text-align: center; } }
    .ctaSection .info button {
      height: 45px;
      width: 199px;
      border-radius: 4px;
      background-color: #ffffff;
      margin-top: 2.5rem;
      /*padding: 1rem 2rem;*/
      border: none;
      color: #28129d;
      font-family: 'GT America Standard Medium';
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center; }
      .ctaSection .info button:hover {
        cursor: pointer; }
  .ctaSection .img img {
    width: 540px; }
    @media (max-width: 990px) {
      .ctaSection .img img {
        width: 356px;
        margin: 2rem auto 0; } }
