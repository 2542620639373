.hero {
  text-align: center; }
  .hero .title {
    color: #000000;
    font-family: 'GT America Extended Regular';
    font-size: 42px;
    letter-spacing: 0;
    line-height: 50px;
    text-align: center; }
    .hero .title .bold {
      font-family: 'GT America Extended Bold';
      color: #291d9b; }
    @media (max-width: 990px) {
      .hero .title {
        font-size: 28px;
        letter-spacing: 0;
        line-height: 37px;
        text-align: center; } }
  .hero .subText {
    color: #000000;
    width: 565px;
    margin: 0 auto;
    font-family: 'GT America Standard Regular';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 31px;
    margin-top: 1.5rem;
    text-align: center; }
    @media (max-width: 990px) {
      .hero .subText {
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        width: 316px; } }
  .hero .cta {
    margin-top: 2.5rem;
    border-radius: 4px;
    background-color: #000000;
    /*padding: 1rem 2rem;*/
    border: none;
    height: 45px;
    width: 200px;
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    .hero .cta:hover {
      cursor: pointer; }
  .hero .features {
    margin: 3rem auto;
    font-family: 'GT America Standard Regular';
    color: #000000;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 23px; }
    .hero .features .svg {
      width: 12px; }
