.social-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  width: 280px; }

.slack-btn {
  color: #000000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1.5px solid #000000;
  border-radius: 20px;
  background-color: #ffffff; }
