.validate {
  height: 100vh; }
  @media (max-width: 575.98px) {
    .validate h1 {
      font-size: 1.5rem !important;
      line-height: 1.7rem; } }

.page {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.page-centered {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: inherit;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }
