.members-list .member-item {
  display: inline;
  margin-left: -20px;
  -webkit-transition-property: margin-left;
  -o-transition-property: margin-left;
  transition-property: margin-left;
  -webkit-transition-duration: 0.5s;
       -o-transition-duration: 0.5s;
          transition-duration: 0.5s; }

.members-list .member-item:first-child {
  margin-left: 0; }

.members-list:hover .member-item:not(:first-child) {
  margin-left: 5px; }

.company-name-link {
  text-decoration: none;
  font-size: 1rem; }

.w-15 {
  width: 15% !important; }
