.mtb-m {
  margin: 4rem 0; }

.mb-m {
  margin-bottom: 4rem; }

@media (min-width: 576px) {
  .sign-up-header {
    -ms-flex-order: 1;
        order: 1; }
  .sign-up-form {
    -ms-flex-order: 3;
        order: 3; }
  .sign-up-social {
    -ms-flex-order: 2;
        order: 2; } }

@media (min-width: 768px) {
  .sign-up-header {
    -ms-flex-order: 1;
        order: 1; }
  .sign-up-form {
    -ms-flex-order: 2;
        order: 2; }
  .sign-up-social {
    -ms-flex-order: 3;
        order: 3; } }
