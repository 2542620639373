.disabled {
  pointer-events: none;
  opacity: 0.4; }

.icon-wrap {
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px;
  position: relative; }
  .icon-wrap:last-child {
    margin-right: 0; }
  .icon-wrap.with-background {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 16px;
    background: #f2f2f2;
    margin: 0;
    cursor: pointer; }
    .icon-wrap.with-background.inverted {
      background: #000; }
    .icon-wrap.with-background svg,
    .icon-wrap.with-background .children {
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0); }
    .icon-wrap.with-background .icon-tooltip {
      bottom: calc(100% + 8px + 2px); }
    .icon-wrap.with-background:hover .icon-tooltip {
      opacity: 1;
      display: block; }
  .icon-wrap.inverted svg {
    fill: #fff; }
  .icon-wrap svg,
  .icon-wrap .children {
    display: block;
    cursor: pointer; }
    .icon-wrap svg:hover + .icon-tooltip,
    .icon-wrap .children:hover + .icon-tooltip {
      opacity: 1;
      display: block; }
  .icon-wrap .icon-tooltip {
    position: absolute;
    bottom: calc(100% + 8px + 2px);
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 8px 20px;
    background: #000;
    color: #fff;
    border-radius: 20px;
    pointer-events: none;
    opacity: 0;
    display: none;
    /* min-width: 150px;*/
    font-size: 13px;
    line-height: 15px; }

.is-theme-dark svg {
  fill: #fff; }
