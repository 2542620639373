.hashNode {
  color: #3b3d45; }

.indorsedVote {
  color: #56e39f; }

.flaggedVote {
  color: #ef6f6c; }

.indorsedClaim {
  color: #88ccf1; }

.flaggedClaim {
  color: #fabfd3; }

.noConsensusClaim {
  color: #ffeb3b; }

.treeVizContainer {
  /* parent div*/
  position: relative;
  /* height: 35rem;*/ }

.treeViz {
  /* svg class*/
  border: 1px solid whitesmoke;
  width: 100%;
  /* height: 100%;*/ }

.mainGroup {
  /* parent group of all nodes and path*/
  border: 1px solid whitesmoke; }

.zoomBaseTarget {
  fill: none;
  pointer-events: all; }

.nodes {
  /* parent g of nodes*/ }

.node {
  /* has circle & text inside*/ }

.node.hasChildren {
  cursor: pointer; }

.node.hasChildren circle {
  fill: #3b3d45;
  stroke: #3b3d45; }

.node.isCollapsed circle {
  fill: #f9f9f9;
  /* stroke: #3B3D45;*/ }

.node circle {
  /* each node*/
  fill: #f9f9f9;
  stroke: #f9f9f9; }

.node text {
  display: none;
  text-shadow: #f9f9f9 10px 0 10px; }

.links {
  /* parent g of links*/ }

.link {
  /* each path link*/
  fill: none;
  stroke: gray;
  stroke-opacity: 0.2; }

.link.selectedPath {
  stroke-opacity: 0.9; }

.node.vote circle {
  fill: #ef6f6c;
  stroke: #ef6f6c; }

.node.vote.endorsed circle {
  fill: #56e39f;
  stroke: #56e39f; }

.node.claim.endorsed circle {
  fill: #88ccf1;
  stroke: #88ccf1; }

.node.claim.rejected circle {
  fill: #fabfd3;
  stroke: #fabfd3; }

.node.claim.noConsensus circle {
  fill: #ffeb3b;
  stroke: #ffeb3b; }

.node.claim circle {
  fill: #fabfd3;
  stroke: #fabfd3; }

.node.selected circle {
  stroke-width: 0.5em; }

.node:hover circle {
  stroke-width: 0.5em;
  cursor: pointer; }

.node:hover text {
  display: block; }

.nodeInfo .nodeHash {
  overflow-wrap: break-word; }

.nodeInfo .plainTree {
  overflow: scroll;
  background: #f9f9f9;
  max-height: 10rem; }
