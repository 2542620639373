#scene {
  /* overflow: hidden;*/
  /* position: relative;*/
  /* width: 100%;*/
  /* height: calc(100vh - 54px);*/ }

.mobile-hero-svg svg {
  position: relative;
  top: -1.5rem; }

.hero-container {
  background: white;
  width: 100%;
  height: 768px;
  max-width: 1366px;
  overflow: hidden;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .hero-container svg {
    position: absolute;
    opacity: 0;
    overflow: visible;
    display: block;
    width: 86px;
    height: 86px; }
    .hero-container svg.line .path {
      stroke-width: 4px;
      stroke: black;
      stroke-miterlimit: 10;
      fill: none; }
  .hero-container #c50-purple {
    left: 6%;
    top: -6.5%;
    width: 172px;
    height: 172px; }
  .hero-container #c50-orange {
    right: 6.5%;
    bottom: 6.5%; }
  .hero-container #t-pink {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    left: 1.5%;
    top: 4%; }
  .hero-container #t-yellow {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    left: 6%;
    top: 45%; }
  .hero-container #line-square {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    right: 8%;
    top: 15%; }
  .hero-container #line-circle {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
    left: 15%;
    bottom: 13%; }
  .hero-container #line-diagon {
    right: 6.5%;
    top: 6.5%; }
  .hero-container #s-green {
    right: 7%;
    top: 42%; }
