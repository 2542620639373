.dropdown button:after {
  all: unset; }

.dropdown button:focus {
  outline: unset; }

.dropdown-menu {
  border-radius: unset !important;
  padding: 0; }

.dropdown-item {
  padding: 0.5rem; }
  .dropdown-item:active {
    background-color: #000; }

.on-click:hover {
  background-color: #f2f2f2;
  cursor: pointer; }
