/* section layout */
.media-and-partner {
  padding-top: 0; }

/*desktop*/
/*mobile*/
/*desktop*/
/*top*/
.sec-pt-0 {
  padding-top: 0; }

.sec-pt-1 {
  padding-top: 3rem; }

.sec-pt-2 {
  padding-top: 7.5rem; }

.sec-pt-3 {
  padding-top: 18.75rem; }

/*bottom*/
.sec-pb-0 {
  padding-bottom: 0; }

.sec-pb-1 {
  padding-bottom: 3rem; }

.sec-pb-2 {
  padding-bottom: 7.5rem; }

.sec-pb-3 {
  padding-bottom: 18.75rem; }

/*top and bottom*/
.sec-py-0 {
  padding: 0; }

.sec-py-1 {
  padding: 3rem 0; }

.sec-py-2 {
  padding: 7.5rem 0; }

.sec-py-3 {
  padding: 18.75rem 0; }

@media (max-width: 991.98px) {
  .sec-pt-m-0 {
    padding-top: 0; }
  .sec-pt-m-1 {
    padding-top: 1.562rem; }
  .sec-pt-m-2 {
    padding-top: 2.187rem; }
  .sec-pt-m-3 {
    padding-top: 3.125rem; }
  .sec-pt-m-4 {
    padding-top: 6.25rem; }
  /*bottom*/
  .sec-pb-m-0 {
    padding-bottom: 0; }
  .sec-pb-m-1 {
    padding-bottom: 1.562rem; }
  .sec-pb-m-2 {
    padding-bottom: 2.187rem; }
  .sec-pb-m-3 {
    padding-bottom: 3.125rem; }
  .sec-pb-m-4 {
    padding-bottom: 6.25rem; }
  /*top and bottom*/
  .sec-py-m-0 {
    padding: 0; }
  .sec-py-m-1 {
    padding: 1.562rem 0; }
  .sec-py-m-2 {
    padding: 2.187rem 0; }
  .sec-py-m-3 {
    padding: 3.125rem 0; }
  .sec-py-m-4 {
    padding: 6.25rem 0; } }

.company {
  overflow-x: hidden;
  /* layout*/
  /*typography*/ }
  .company .mt-m {
    margin-top: 4rem; }
  .company .mb-m {
    margin-bottom: 4rem; }
  .company .mb-l {
    margin-bottom: 7rem; }
  .company .my-l {
    margin: 7rem 0; }
  .company .mb-xl {
    margin-bottom: 14rem; }
  .company .typography-p-large {
    font-size: 20px;
    line-height: 1.4em; }
  .company .typography-hl-extra {
    font-size: 52px;
    line-height: 1.1em; }
  @media (max-width: 575.98px) {
    .company .mb-xl {
      margin-bottom: 2rem; }
    .company .mb-l {
      margin-bottom: 1rem; }
    .company .my-l {
      margin: 1rem 0; }
    .company .typography-p-large {
      font-size: 14px;
      line-height: 1.3em; }
    .company .typography-hl-extra {
      font-size: 24px;
      line-height: 1.2em; } }
