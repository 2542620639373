.started-button {
  border-radius: 4px;
  background-color: #000000;
  padding: 0.7rem 1rem;
  border: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .started-button:focus {
    outline: none; }

.title {
  font-family: 'GT America Extended Regular';
  text-align: center;
  margin-bottom: 24px !important;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 42px; }

.subtitle {
  font-family: 'GT America Standard Regular';
  text-align: center;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 36px; }
