.blueBackground {
  padding: 70px 0;
  background-color: #eff3fe; }

.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 575px) {
    .title {
      font-size: 24px;
      line-height: 30px; } }

.subtitle {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 10px auto 70px; }
  @media (max-width: 575px) {
    .subtitle {
      font-size: 18px;
      line-height: 28px;
      margin: 10px auto 40px; } }

.videoContainer {
  margin: auto; }

.videoWrapper {
  position: relative;
  padding-bottom: 40%;
  height: 0;
  max-width: 840px;
  margin: auto; }
  @media (max-width: 575px) {
    .videoWrapper {
      padding-bottom: 56.25%; } }

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
