.statBox .title {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap; }

.statBox .count {
  color: #0052cc;
  font-family: 'GT America Standard Bold';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px;
  white-space: nowrap; }
  .statBox .count .supp {
    color: #0052cc;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px; }
