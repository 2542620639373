.main-title {
  font-family: 'GT America Extended Bold';
  color: #000000;
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 28px; } }

.main-subtitle {
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .main-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.how-it-works-title {
  color: #2a2498;
  font-size: 28px;
  letter-spacing: -0.08px;
  line-height: 32px; }

.tab-container {
  -ms-flex-align: end;
      align-items: flex-end; }

.tab-item {
  padding: 1rem;
  min-height: 88px;
  text-align: center;
  background-color: #dde3f2;
  color: black;
  font-family: 'GT America Expanded Regular'; }
  .tab-item:hover {
    cursor: pointer; }
  .tab-item .visible {
    background-color: white; }
  .tab-item h2 {
    color: #2a2498;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 25px;
    margin-bottom: 0; }
  .tab-item:hover {
    cursor: pointer;
    background-color: white; }
  .tab-item.tab-selected {
    height: 127px;
    border-radius: 10px 10px 0 0;
    background-color: #ffffff; }
    .tab-item.tab-selected h2 {
      font-family: 'GT America Expanded Bold';
      color: #2a2498;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
      font-weight: 900; }

.card {
  height: 517px;
  width: 1240px;
  border-radius: 0 0 10px 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1); }
  .card .content {
    margin: auto 125px;
    height: 100%; }
    .card .content .content-left,
    .card .content .content-right {
      margin: auto; }
    .card .content .content-right .info {
      max-width: 426px;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
      margin-left: auto; }
    .card .content .content-right .list {
      margin-left: 2em;
      font-family: 'GT America Standard Medium'; }
    .card .content .content-right p {
      font-weight: 900 !important; }
  .card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 26px; }
  .card p {
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0.28px;
    line-height: 28px; }
  @media (max-width: 500px) {
    .card {
      height: auto;
      min-width: 385px;
      max-width: 100%; }
      .card .content {
        margin: 20px; }
      .card p {
        font-size: 16px;
        font-family: 'GT America Standard Medium'; } }

.large-card {
  max-width: 731px;
  border-radius: 5px;
  background-color: #eff3fe;
  padding: 3em; }
  .large-card h2 {
    color: #2a2498;
    font-size: 24px;
    letter-spacing: -0.07px;
    line-height: 32px; }

.heading {
  font-family: 'GT America Extended Bold';
  max-width: 400px;
  color: #2800a0;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .heading {
      font-size: 22px;
      line-height: 34px; } }

.headingCard {
  border-radius: 5px;
  background-color: #eff3fe; }

.step {
  color: #00b1a4;
  font-family: 'GT America Standard Bold';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center; }

.text {
  max-width: 390px;
  font-family: 'GT America Standard Regular';
  font-size: 18px !important;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .text {
      font-size: 16px !important;
      line-height: 26px; } }

.line-one {
  position: absolute;
  top: 23em;
  left: 26.5em;
  width: 70px; }

.line-two {
  position: absolute;
  top: 23em;
  right: 26.2em;
  width: 70px; }

.circle {
  position: relative;
  width: 220px;
  margin: 0 auto 2em; }

.survey-title {
  color: #2a2498;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }
  @media (max-width: 500px) {
    .survey-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 26px; } }

.survey-subtitle {
  color: #282828;
  font-family: 'GT America Standard Regular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }
  @media (max-width: 500px) {
    .survey-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.grey-bg {
  width: 950px;
  border-radius: 4px;
  background-color: rgba(241, 246, 249, 0.5);
  padding: 30px 45px;
  margin: 3em auto; }

.comparision-title {
  color: #000000;
  font-family: 'GT America Standard Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin-bottom: 2.5em; }

.comparision-image {
  margin: 3em auto;
  display: block; }
  @media (max-width: 500px) {
    .comparision-image {
      margin: 2em auto; } }

.getStartedBtn {
  border-radius: 4px;
  background-color: #000000;
  padding: 0.7rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 225px;
  border: none;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .getStartedBtn:focus {
    outline: none; }

.template-card {
  border-radius: 24px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.02);
          box-shadow: 0 24px 48px 0 rgba(0, 24, 52, 0.02);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 5px 24px 0;
  min-width: 400px;
  position: relative;
  height: 350px; }
  .template-card .template-img {
    width: 350px;
    height: 230px; }
  .template-card .template-name {
    font-family: 'GT America Extended Bold';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
    padding: 10px 15px 0; }
  .template-card .expandBtn {
    position: absolute;
    top: -21px;
    right: -21px;
    background-color: #00b1a4;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center; }
    .template-card .expandBtn:hover {
      cursor: pointer; }
    @media (max-width: 500px) {
      .template-card .expandBtn {
        display: none; } }
  .template-card .expand-icon {
    color: #fff; }
  @media (max-width: 500px) {
    .template-card {
      padding: 20px 0;
      min-width: 225px;
      height: 225px; }
      .template-card .template-img {
        width: 200px;
        height: 100%; }
      .template-card .template-name {
        font-family: 'GT America Extended Bold';
        font-size: 14px;
        line-height: unset; } }

.macroContainer h2 {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center; }

.macroContainer .macro {
  color: #291d9b;
  font-family: 'GT America Standard Bold';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  text-transform: uppercase; }

.template-title {
  color: #000000;
  max-width: 803px;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin: 0 auto 12px; }

.template-subtitle {
  max-width: 1000px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: auto;
  margin-bottom: 21px; }

.template-desc {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 500px) {
    .template-desc {
      font-size: 12px; } }

.learnMoreBtn {
  border-radius: 4px;
  background-color: #000000;
  padding: 0.7rem 1rem;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  max-width: 225px;
  border: none;
  margin: 0 auto 35px;
  display: -ms-flexbox;
  display: flex;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .learnMoreBtn:focus {
    outline: none; }

.fullImg {
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  min-height: 530px; }
