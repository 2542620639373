.secondary {
  background-image: url("https://indorse-staging-bucket.s3.amazonaws.com/pattern-small.png");
  background-repeat: no-repeat;
  /*background-size: cover;*/
  background-size: 100% auto;
  margin: 0 auto;
  padding: 2rem 0 4rem;
  text-align: center; }
  @media (max-width: 990px) {
    .secondary {
      background-size: contain;
      background-position: center; } }
  .secondary .title {
    /*width: 1030px;*/
    margin: 0 auto;
    color: #ffffff;
    font-family: 'GT America Extended Regular', serif;
    font-size: 42px;
    letter-spacing: 0;
    line-height: 50px; }
    @media (max-width: 990px) {
      .secondary .title {
        max-width: 350px;
        margin: 0 auto;
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center; } }
  .secondary .cta {
    margin-top: 2.5rem;
    border-radius: 4px;
    background-color: #ffffff;
    /*padding: 1rem 2rem;*/
    border: none;
    height: 45px;
    width: 186px;
    color: #28129d;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    .secondary .cta:hover {
      cursor: pointer; }
