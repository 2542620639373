.teams {
  margin: 2rem; }
  .teams .title-bar {
    position: relative;
    margin-bottom: 3rem; }
    .teams .title-bar .title {
      color: #000;
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0; }
  .teams .infoBox {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 1px solid #5243aa;
    /*opacity: 0.6;*/
    border-radius: 10px;
    background-color: rgba(220, 217, 238, 0.6);
    margin: 0 0 1rem;
    padding: 1rem; }
    .teams .infoBox .info {
      background-color: #5243aa;
      color: white;
      height: 22px;
      padding-top: 4px;
      width: 22px;
      border-radius: 11px;
      opacity: 1; }
    .teams .infoBox .title {
      color: #000;
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
      margin: 0; }

.card {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
  padding: 1rem 2rem; }

.teamCard {
  margin-bottom: 2rem; }
  .teamCard .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0; }

.scopeCard {
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
  padding: 1rem 2rem; }

.filterCard {
  /* margin-bottom: 2rem;*/ }
  .filterCard .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0; }

.emptyState {
  text-align: center;
  padding: 4rem 1rem; }
  .emptyState .placeholderImg {
    margin: 2rem auto 0;
    height: 400px; }
  .emptyState .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: -2rem 0 0; }
  .emptyState .subtitle {
    margin: 1rem auto 2rem;
    width: 601px;
    color: #000000;
    font-family: 'GT America Standard Regular', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center; }
  .emptyState .actionBtn {
    color: #ffffff;
    font-size: 14px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0.4rem 2.5rem 0.5rem;
    border-radius: 38px; }

.actionBtn {
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0.4rem 2.5rem 0.5rem;
  border-radius: 38px; }

.modal .highlight {
  color: #0048d3;
  font-family: 'GT America Standard Bold', sans-serif;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: 0 auto 1rem; }

.modal .title {
  color: #000000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin: 0; }

.modal .secondary-title {
  color: #000000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
  text-align: left; }

.modal input {
  font-family: 'GT America Standard Regular', sans-serif;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 45px;
  /*width: 340px;*/
  width: 100%;
  border: 2px solid #ebf2f7;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0 1rem; }
  @media (max-width: 990px) {
    .modal input {
      margin: 1rem auto;
      width: 100%; } }

.modal .highlightedBox {
  height: 276px;
  width: 100%;
  overflow: auto;
  border-radius: 5px;
  background: rgba(183, 202, 249, 0.1);
  padding: 1rem; }

.modal .memberList {
  height: 375px;
  width: 100%;
  border: 1px solid #d1dbe3;
  border-radius: 5px;
  background-color: #ffffff;
  /*padding: 1rem;*/
  overflow: auto; }
  .modal .memberList .memberItem {
    display: block;
    width: 100%;
    padding: 1rem 1.5rem;
    background-color: transparent;
    border: 1px solid transparent; }
    .modal .memberList .memberItem span {
      color: #000000;
      font-family: 'GT America Standard Regular', sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 20px; }
    .modal .memberList .memberItem .checkbox,
    .modal .memberList .memberItem .selectedBox {
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      width: 20px;
      border: 1px solid #dee8f7;
      border-radius: 2px;
      background-color: #dee8f7;
      color: #0052cc; }
    .modal .memberList .memberItem .selectedBox {
      border: 1px solid #0052cc;
      background-color: #0052cc;
      color: white; }
    .modal .memberList .memberItem:hover {
      cursor: pointer;
      background: rgba(183, 202, 249, 0.1); }
      .modal .memberList .memberItem:hover .checkbox {
        border: 1px solid #0052cc; }
    .modal .memberList .memberItem:focus {
      outline: none;
      background: rgba(183, 202, 249, 0.3); }
      .modal .memberList .memberItem:focus .checkbox {
        border: 1px solid #0052cc; }
    .modal .memberList .memberItem:active {
      -webkit-transform: scale(0.99);
          -ms-transform: scale(0.99);
              transform: scale(0.99); }

.modal .sub-title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  /*letter-spacing: 1px;*/
  line-height: 32px; }

.aboutPage {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px; }

.aboutPoints {
  margin: 1rem;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 18px; }

.deleteTitle {
  color: #000000;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center; }
  .deleteTitle span {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
