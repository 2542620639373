.title {
  width: 200px; }

.forgotPasswordBody {
  padding-top: 40%;
  height: 80vh; }

.floatRight {
  float: right; }

.marginLeft {
  margin-left: 40px; }

.verticalAlign {
  vertical-align: middle; }

.top100px {
  margin-top: 100px; }

.top50px {
  margin-top: 50px; }

.footer {
  bottom: 10; }
