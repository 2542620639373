.cover-container {
  position: relative;
  height: 16rem; }
  @media (max-width: 575.98px) {
    .cover-container {
      height: 8rem; } }

.cover {
  height: 100%;
  background-color: #f8f9fa;
  background-position: center center;
  background-size: cover; }

.pull-up {
  position: relative;
  top: -90px; }
  @media (max-width: 991.98px) {
    .pull-up {
      top: -50px; } }

.member-container .member {
  height: 23rem;
  -webkit-box-shadow: 0px 10px 15px rgba(197, 197, 197, 0.5);
          box-shadow: 0px 10px 15px rgba(197, 197, 197, 0.5); }
  .member-container .member img.avatar {
    width: 10rem;
    height: 10rem; }
  .member-container .member .info {
    max-height: 15rem;
    overflow-y: scroll; }
  .member-container .member .indorse-logo {
    height: 1.5rem; }
  .member-container .member .verified {
    color: #28a745; }
  .member-container .member .pending {
    color: #ffc107; }

.social-link-btn {
  padding: 8px 10px !important; }
