.itemPanel {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  /*background: #fafafa;*/
  /*padding-top: 1rem;*/
  /*margin-bottom: 4rem;*/ }
  .itemPanel :global .ant-card {
    background: #fafafa; }
  .itemPanel :global .ant-card-body {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center; }
    .itemPanel :global .ant-card-body > div {
      margin-bottom: 16px; }
