.modal-header, .modal-footer-between, .modal-footer-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding: 2rem; }

.modal-dialog > div {
  border-radius: 8px; }

.modal-dialog {
  margin-top: 10vh; }

.modal-header {
  border-bottom: unset;
  padding-bottom: 0; }
  .modal-header > button {
    display: none; }

.modal-header.only-close-icon {
  -ms-flex-pack: end;
      justify-content: flex-end; }

.modal-body {
  padding: 1rem 2rem 0; }

.modal-footer-between {
  border-top: unset; }

.modal-footer-right {
  margin-left: auto;
  border-top: unset; }

.modal-alert {
  background: rgba(255, 255, 255, 0.5); }
