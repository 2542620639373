.checkbox-wrapper.form-item {
  width: auto;
  -ms-flex-direction: inherit;
      flex-direction: inherit;
  margin-bottom: 15px; }

.checkbox-wrapper.is-disabled {
  cursor: not-allowed; }
  .checkbox-wrapper.is-disabled .checkbox-input,
  .checkbox-wrapper.is-disabled .checkbox-label {
    pointer-events: none; }

.checkbox-wrapper .checkbox-input {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer; }

.checkbox-wrapper .checkbox-icon {
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: #000;
  margin-right: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer; }
  .checkbox-wrapper .checkbox-icon .checkbox-icon-inner {
    background-color: #f2f2f2;
    -webkit-transition: background-color 500ms ease;
    -o-transition: background-color 500ms ease;
    transition: background-color 500ms ease;
    border-radius: 50%;
    width: 18px;
    height: 18px; }

.checkbox-wrapper .checkbox-label {
  -ms-flex-item-align: center;
      align-self: center;
  cursor: pointer; }

.checkbox-wrapper:last-child {
  margin-bottom: 0; }

.checkbox-wrapper.is-checked .checkbox-icon .checkbox-icon-inner {
  background-color: #000 !important; }

.checkbox-wrapper.is-disabled .checkbox-icon {
  cursor: not-allowed;
  border-color: #7c7b7b; }
  .checkbox-wrapper.is-disabled .checkbox-icon .checkbox-icon-inner {
    cursor: not-allowed;
    background-color: #7c7b7b;
    background-color: #f2f2f2; }
