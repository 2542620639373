.form-card {
  width: 99%; }

.form-header {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #5243a9; }

.inline-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .inline-textp {
    display: -ms-flexbox;
    display: flex; }

.inline-form {
  margin: -6px 6px 0 6px;
  width: 350px; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.copy-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  margin: auto;
  width: 350px; }

.fields {
  margin: 0 auto;
  display: table; }
