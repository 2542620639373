/*
	.classOne {
		background-color: #000;
	}
*/
.questionBox {
  padding: 1rem;
  border: solid 1px #fcfbfb;
  margin-bottom: 2rem;
  font-size: 20px;
  width: 100%; }

.question {
  font-size: 20px;
  /*margin: 1rem 0;*/ }

.errorNotification {
  font-size: 16px;
  margin-top: 1rem;
  color: red; }

.solution {
  font-size: 16px; }

.boldTitle {
  font-size: 14px;
  font-weight: bolder;
  background: #e0e0e0;
  padding: 4px 0 4px 1rem; }

.htmlEditor {
  width: 100%; }

.reviewForm {
  font-size: 14px;
  margin-top: 2rem; }
  .reviewForm span {
    font-weight: bolder; }

.userAnswerBox {
  padding: 0 0 8px;
  margin: 0 8px;
  border-radius: 5px;
  width: 100%;
  /*background: #f3f3f3;*/ }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }
