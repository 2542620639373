.tab-header {
  border: unset !important;
  border-top: 1px solid #f2f2f2; }

.tab-item {
  position: relative; }

.tab-link {
  color: lightgray !important; }
  .tab-link:hover {
    border: unset !important; }

.active.tab-link {
  color: #000 !important; }

.active.tab-link:before {
  content: '';
  height: 5px;
  width: 5px;
  background-color: black;
  border-radius: 50%;
  opacity: 1;
  position: absolute;
  left: 48%;
  bottom: 0px; }

.inverted .tab-link {
  /* background: palegreen;*/
  color: #000 !important;
  border: 1px solid #f2f2f2;
  border-radius: unset; }
  .inverted .tab-link:hover {
    border: 1px solid #f2f2f2 !important; }

.inverted .active.tab-link {
  color: #fff !important;
  background: #000;
  border-radius: unset; }

.inverted .active.tab-link:before {
  all: unset; }

.fillWithGreen .tab-link {
  background: #fff;
  color: black !important;
  border-radius: unset;
  height: 50px;
  width: 105px;
  text-align: center;
  padding: 1rem 0 !important; }

.fillWithGreen .active.tab-link {
  color: #00b1a4 !important;
  /*background: $black;*/
  border-radius: unset;
  height: 50px;
  width: 105px;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(#d9fbf7));
  background: -webkit-linear-gradient(bottom, #ffffff 0%, #d9fbf7 100%);
  background: -o-linear-gradient(bottom, #ffffff 0%, #d9fbf7 100%);
  background: linear-gradient(0deg, #ffffff 0%, #d9fbf7 100%);
  border-bottom: 4px solid #00b1a4; }
  .fillWithGreen .active.tab-link:hover {
    border-bottom: 4px solid #00b1a4 !important; }

.fillWithGreen .active.tab-link:before {
  all: unset; }

.fillWithPurple .tab-link {
  background: #fff;
  color: black !important;
  border-radius: unset;
  height: 50px;
  width: 105px;
  text-align: center;
  padding: 1rem 0 !important; }

.fillWithPurple .active.tab-link {
  color: #5243aa !important;
  /*background: $black;*/
  border-radius: unset;
  height: 50px;
  width: 105px;
  background: -webkit-gradient(linear, left bottom, left top, from(#ffffff), to(rgba(82, 67, 170, 0.2)));
  background: -webkit-linear-gradient(bottom, #ffffff 0%, rgba(82, 67, 170, 0.2) 100%);
  background: -o-linear-gradient(bottom, #ffffff 0%, rgba(82, 67, 170, 0.2) 100%);
  background: linear-gradient(0deg, #ffffff 0%, rgba(82, 67, 170, 0.2) 100%);
  border-bottom: 4px solid #5243aa; }
  .fillWithPurple .active.tab-link:hover {
    border-bottom: 4px solid #5243aa !important; }

.fillWithPurple .active.tab-link:before {
  all: unset; }
