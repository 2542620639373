.img-wrapper img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%); }
  .img-wrapper img:hover {
    -webkit-filter: none;
            filter: none; }

.overflow-hidden {
  overflow: hidden; }

.slick-dots li button:before {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  background-color: #000;
  border-radius: 50%; }

.slick-dots li.slick-active button:before {
  background-color: #000;
  opacity: 1; }

.slick-dots li.slick-non button:before {
  background-color: #b4b4b4 !important;
  opacity: 1; }
