.field {
  padding: 10px !important; }

.page {
  margin-top: 5rem; }

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem 3rem;
  padding: 2rem; }

.welcome-msg p {
  font-size: 20px;
  line-height: 28px; }

.welcome-msg h1 {
  font-size: 42px;
  font-weight: 900;
  line-height: 50px; }

.welcome-msg .heading {
  font-size: 32px;
  /*font-weight: 900;*/
  line-height: 40px;
  color: #2552cb;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.welcome-msg .anyQuestions {
  margin-top: 3rem; }

.border {
  border-right: 2px solid #ececec; }

.bold-text {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

@media (max-width: 640px) {
  .page {
    margin: 2rem auto; }
  .welcome-msg p {
    font-size: 20px;
    line-height: 28px; }
  .welcome-msg h1 {
    font-size: 42px;
    font-weight: 900;
    line-height: 50px; }
  .welcome-msg .heading {
    font-size: 20px;
    line-height: 34px;
    color: #2552cb;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .welcome-msg .sub-text {
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 26px;
    /*text-align: center;*/ }
  .welcome-msg .anyQuestions {
    margin-top: 2rem; }
    .welcome-msg .anyQuestions p,
    .welcome-msg .anyQuestions h5 {
      font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; }
  .border {
    padding-bottom: 3rem;
    border-bottom: 2px solid #ececec;
    border-right: none; }
  .bold-text {
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 20px !important;
    line-height: 32px; } }
