.v-divider {
  border-right: solid 0.5px #eee; }

@media (max-width: 575.98px) {
  .v-divider {
    border-right: none; }
  .divider {
    border-bottom: solid 0.5px #eee;
    margin-bottom: 3rem; }
  .padding-x {
    padding: 1rem !important; } }

@media (max-width: 991.98px) {
  .v-divider {
    border-right: none; }
  .divider {
    border-bottom: solid 0.5px #eee;
    margin-bottom: 3rem; }
  .padding-x {
    padding: 0 2rem; } }

@media (max-width: 575.98px) {
  .title {
    font-size: 1.2rem !important;
    line-height: 1.5rem;
    padding: 2rem 0 0 2rem;
    width: 9rem; } }

.footer {
  padding-top: 35vh; }
  @media (max-width: 575.98px) {
    .footer {
      padding: 10rem 1rem 1rem; }
      .footer p {
        width: 9rem; } }

.btn {
  margin: 0 !important; }

.bgImageSmall {
  background-image: url("./bg-small.png");
  background-size: 100% auto;
  height: 100vh; }

.bgImageBig {
  background-image: url("./bg-big.png");
  background-size: 100% auto;
  height: 100vh; }

.bgMobile {
  background-image: url("./mobile_bg1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 70vh; }

.logo {
  padding-top: 5%;
  padding-left: 5%; }
  @media (max-width: 767.98px) {
    .logo {
      width: 5rem !important; } }

.container {
  padding: 40vh 12vw 0; }
  @media (max-width: 767.98px) {
    .container {
      padding: 1rem 2rem 0; }
      .container h1 {
        font-size: 1rem;
        line-height: 1.5rem !important; }
      .container p {
        font-size: 0.7rem; } }
