.container {
  max-width: 1270px;
  margin: 0 auto;
  padding-top: 2rem;
  /* border: 1px solid black;*/ }

.customers {
  margin-top: 1rem; }
  @media (max-width: 575px) {
    .customers {
      margin-top: 0;
      padding-top: 0; } }

.help {
  /*background: #eff3fe;*/ }

.secondaryCta {
  background: #eff3fe; }

.whiteBg {
  height: 250px; }

.blueBg {
  height: 250px;
  background-color: #eff3fe; }

.connectForm {
  /* margin: 0*/ }
