/*
	.classOne {
		background-color: #000;
	}
*/
.submitBtn {
  font-size: 20px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }

.divider {
  background: #d3d3d3;
  width: 500px;
  height: 4px; }
