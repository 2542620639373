.white-background {
  background-color: #ffffff;
  padding: 8em 0; }
  @media (max-width: 550px) {
    .white-background {
      padding: 6em 0; } }

.main-title {
  color: #2a2498;
  font-family: 'GT America Expanded Bold';
  font-size: 38px;
  letter-spacing: 0;
  line-height: 48px;
  max-width: 450px; }
  .main-title span {
    color: #00b1a4; }
  @media (max-width: 550px) {
    .main-title {
      color: #2a2498;
      font-family: 'GT America Expanded Bold';
      font-size: 28px;
      letter-spacing: 0;
      line-height: 37px; } }

.main-subtitle {
  font-family: 'GT America Standard Regular';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 34px; }
  @media (max-width: 550px) {
    .main-subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.legend {
  font-family: 'GT America Standard Medium';
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 36px; }
  @media (max-width: 550px) {
    .legend {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px; } }

.purplebox {
  height: 26px;
  width: 26px;
  background-color: #2a2498; }
  @media (max-width: 550px) {
    .purplebox {
      height: 17px;
      width: 17px; } }

.greenbox {
  height: 26px;
  width: 26px;
  background-color: #00b1a4; }
  @media (max-width: 550px) {
    .greenbox {
      height: 17px;
      width: 17px; } }

.statCard {
  max-width: 333px;
  height: 324px;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  padding: 2em; }
  .statCard .number {
    font-family: 'GT America Expanded Bold';
    font-size: 25px;
    letter-spacing: 0;
    line-height: 50px;
    margin-top: 1em; }
  .statCard .title {
    font-family: 'GT America Expanded Bold';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 34px; }
  .statCard .subtitle {
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
    color: #000000; }
    .statCard .subtitle span {
      font-size: 16px; }
    @media (max-width: 550px) {
      .statCard .subtitle {
        margin: 0 auto; } }
  .statCard.blue-text {
    color: #2a2498; }
  .statCard.green-text {
    color: #00b1a4; }

.line-one {
  position: absolute;
  top: 25em;
  left: 19em;
  width: 200px;
  opacity: 0.2; }

.line-two {
  position: absolute;
  top: 25em;
  right: 19.5em;
  width: 200px;
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
  opacity: 0.2; }
