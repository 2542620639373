.header {
  /*margin-left: 2em;*/ }
  .header h1 {
    color: #ffffff;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', 'GT America', sans-serif;
    font-size: 22px;
    line-height: 32px; }

.highlight {
  background: #2a2498; }

.divider {
  width: 200px;
  background-color: #00b1a4;
  height: 4px;
  margin-left: 0; }

.heading {
  font-family: 'GT America Expanded Medium';
  font-size: 22px;
  letter-spacing: 1px;
  line-height: 32px; }

.heading-mobile {
  font-family: 'GT America Expanded Medium';
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 26px; }

.text {
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 28px; }

.btn {
  font-family: 'GT America Standard Medium';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 25px; }

.last h3 {
  color: #ffffff;
  font-family: 'GT America Standard Medium';
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 35px; }

.unverifiedBody p {
  font-size: 20px;
  letter-spacing: 0;
  line-height: 32px; }

.unverifiedBody .btn {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px; }

.unverifiedBodyMobile p {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px; }

.unverifiedBodyMobile .btn {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px; }

.content {
  padding: 0 2rem; }
  .content h1 {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px; }
  .content p {
    margin-top: 2rem;
    color: #000000;
    font-family: 'GT America Standard Regular';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 40px; }
  @media (max-width: 990px) {
    .content {
      padding-bottom: 2rem; } }
