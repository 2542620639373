.more-padding {
  padding-top: 5em;
  padding-bottom: 5em; }

.hiring {
  background-color: inherit !important;
  border: 1px solid;
  font-size: '16px'; }
  .hiring:hover {
    color: white;
    background-color: black !important;
    border: 1px solid black; }

.title {
  max-width: 882px;
  color: #ffffff;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin: 0 auto; }
  @media (max-width: 575px) {
    .title {
      font-size: 22px;
      line-height: 28px; } }

.subTitle {
  margin-top: 1rem;
  color: #ffffff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }

.blue-background {
  background-color: #2a2498; }

.container {
  max-width: 1270px;
  margin: 0 auto;
  padding-top: 2rem; }

.demoBtn {
  border-radius: 4px;
  background-color: #ffffff;
  /*padding: 0.7rem 2.5rem;*/
  border: none;
  height: 45px;
  width: 186px;
  color: #2800a0;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .demoBtn:focus {
    outline: none; }

.cta {
  background-image: url("https://indorse-staging-bucket.s3.amazonaws.com/pattern-small.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: 2rem 0 4rem; }
  @media (max-width: 575px) {
    .cta {
      background-size: contain;
      background-position: center; } }
