table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0; }
  table caption {
    border-bottom: 1px solid;
    padding-bottom: 10px; }
  table tr {
    color: inherit;
    display: table-row;
    height: 48px;
    vertical-align: middle;
    border-bottom: 1px solid #000; }
  table thead tr {
    height: 20px;
    text-align-last: left; }
    table thead tr th {
      font-size: 10px;
      text-transform: uppercase; }
      table thead tr th:first-child {
        padding-left: 5px; }
      table thead tr th:last-child {
        padding-right: 5px; }
  table tbody tr td,
  table tbody tr th {
    display: table-cell;
    vertical-align: inherit;
    text-align: left;
    padding-right: 30px; }
    table tbody tr td.is-faded,
    table tbody tr th.is-faded {
      color: #7c7b7b; }
    table tbody tr td:first-child,
    table tbody tr th:first-child {
      padding-left: 5px; }
    table tbody tr td:last-child,
    table tbody tr th:last-child {
      padding-right: 5px; }
  table tbody tr th + td {
    text-align: right; }
  table tbody tr.is-active {
    background: #000;
    color: #fff; }
  table tbody tr.has-hover:hover {
    background: #000;
    color: #fff; }
