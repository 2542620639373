.choose-plan {
  font-size: 14px !important; }

.trial-container {
  color: white;
  font-weight: 900;
  font-size: 14px; }

.trial-box {
  border-radius: 4px;
  background-color: rgba(0, 82, 204, 0.15);
  color: #0052cc;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center; }
  .trial-box span {
    font-weight: 900; }
  .trial-box span > span {
    font-family: 'GT America Standard Medium'; }

.boldText {
  font-family: 'GT America Standard Bold'; }

.reminder p {
  font-size: 20px;
  line-height: 30px; }
