.boundary {
  height: 220px;
  padding: 60px 20px 0; }

.container {
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  background: -webkit-gradient(linear, left top, right top, from(#f83737), color-stop(25%, #ffa621), color-stop(50%, #fff837), color-stop(75%, #9be83a), to(#29e251));
  background: -webkit-linear-gradient(left, #f83737 0%, #ffa621 25%, #fff837 50%, #9be83a 75%, #29e251 100%);
  background: -o-linear-gradient(left, #f83737 0%, #ffa621 25%, #fff837 50%, #9be83a 75%, #29e251 100%);
  background: linear-gradient(90deg, #f83737 0%, #ffa621 25%, #fff837 50%, #9be83a 75%, #29e251 100%);
  border-radius: 5px;
  margin: 0 auto; }

.box {
  margin-top: 50px;
  position: relative;
  height: 120px;
  min-width: 50px;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-order: 1;
      order: 1;
  border-left: 2px dashed rgba(0, 0, 0, 0.349);
  text-align: center;
  /* font-family: 'Roboto', sans-serif;*/
  /* border-right: 1px solid black; */ }

.box:first-child {
  border-left: none; }

.box > p {
  position: absolute;
  margin: 0;
  width: 90%;
  height: 100px;
  bottom: -80px;
  text-align: center;
  font-weight: 500;
  -webkit-transform: translateX(5%);
      -ms-transform: translateX(5%);
          transform: translateX(5%); }

.marker {
  position: absolute;
  height: 160px;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%);
  text-align: center;
  /* font-family: 'Roboto', sans-serif;*/ }

.marker > p {
  margin: 0 auto;
  width: 100px;
  border: 1px solid #1f69d8;
  padding: 5px;
  border-radius: 5px;
  background-color: #1f69d8;
  color: white; }

.dash {
  position: absolute;
  height: 85px;
  width: 0;
  left: 50%;
  border: 1px solid #1f69d8; }
