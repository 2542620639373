/*
	.classOne {
		background-color: #000;
	}
*/
.submit-code {
  font-size: 20px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: lighter;
  cursor: pointer; }
