.toolbar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  /*justify-content: space-evenly;*/ }
  .toolbar :global .command i {
    display: inline-block;
    width: 27px;
    height: 27px;
    margin: 0 6px;
    padding-top: 6px;
    text-align: center;
    border: 1px solid #fff;
    cursor: pointer; }
    .toolbar :global .command i:hover {
      border: 1px solid #e6e9ed; }
  .toolbar :global .disable i {
    color: rgba(0, 0, 0, 0.25);
    cursor: auto; }
    .toolbar :global .disable i:hover {
      border: 1px solid #fff; }

.tooltip :global .ant-tooltip-inner {
  font-size: 12px;
  border-radius: 0; }
