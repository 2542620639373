.editor {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 80vh;
  background: #fff; }

.editorSetup {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 80vh;
  background-color: #f8f9fb; }

.editorHd {
  padding: 8px;
  border: 1px solid #e6e9ed; }

.viewContent {
  /*padding: 8px;*/
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  /*border: 1px solid #e6e9ed;*/
  min-height: 3rem; }

.editorCardBd {
  min-height: 700px; }

.editorBd {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%; }

.editorSidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
  border: 1px solid #e6e9ed;
  min-height: 3rem; }

.editorContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  border: 1px solid #e6e9ed;
  min-height: 3rem; }

.detailsPanel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 3rem; }

.editorSidebar {
  /*background: #fafafa;*/ }
  .editorSidebar:first-child {
    border-right: 1px solid #e6e9ed; }
  .editorSidebar:last-child {
    border-left: 1px solid #e6e9ed; }

.flow {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  height: inherit; }

.saveBtn {
  margin: 1rem auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }
