.lightblue {
  padding: 70px 0;
  background-color: #eff3fe; }

.macro {
  color: #291d9b;
  font-family: 'GT America Standard Bold';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px; }
  @media (max-width: 991px) {
    .macro {
      text-align: center; } }

.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 42px;
  letter-spacing: 0;
  line-height: 38px;
  margin-bottom: 40px; }
  @media (max-width: 991px) {
    .title {
      text-align: center;
      font-size: 24px;
      line-height: 30px; } }

.text {
  color: #000000;
  font-family: 'GT America Light';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
  width: 540px; }
  @media (max-width: 991px) {
    .text {
      max-width: 300px;
      margin: 0 auto;
      font-size: 16px; } }

.cards {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 90px; }
  @media (max-width: 991px) {
    .cards {
      -ms-flex-direction: column;
          flex-direction: column;
      margin-top: 20px; } }

.card {
  height: 425px;
  width: 400px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 38px 20px;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12); }
  @media (max-width: 991px) {
    .card {
      width: 80%;
      margin: 25px auto 0;
      min-height: 350px; } }

.cardImage {
  /* width: 160px;*/
  max-height: 200px; }
  .cardImage img {
    margin: auto;
    height: 250px; }

.cardTitle {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 12px;
  margin-top: 20px; }

.cardText {
  max-width: 327px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0 auto; }

.infoContainer {
  /* padding: 0 5rem;*/ }
  @media (max-width: 992px) {
    .infoContainer {
      padding: 0; } }
