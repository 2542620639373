.container {
  max-width: 1270px;
  margin: 0 auto;
  /* border: 1px solid black;*/ }

.customers {
  margin-top: 0;
  /*transform: translateY(-4rem);*/ }

.community {
  /*margin-top: 3rem;*/
  /*transform: translateY(-2rem);*/ }

.cta {
  padding-bottom: 6rem;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #ffffff), color-stop(50%, #eff3fe));
  background-image: -webkit-linear-gradient(#ffffff 50%, #eff3fe 50%);
  background-image: -o-linear-gradient(#ffffff 50%, #eff3fe 50%);
  background-image: linear-gradient(#ffffff 50%, #eff3fe 50%); }
  @media (max-width: 990px) {
    .cta {
      padding-bottom: 3rem;
      background-image: none; } }

.report {
  background-color: #f1f5fe; }
