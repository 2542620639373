.arrowKey {
  background-color: #ebf2f7;
  padding: 0.34rem 1rem;
  border: none;
  /* border-bottom: 3px solid #0052cc00;*/
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }
  .arrowKey .button {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    background-color: white;
    -webkit-transition: ease-in-out 0.1s;
    -o-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    color: black;
    outline: none;
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 5px 0 #e2e2e2;
            box-shadow: 0 2px 5px 0 #e2e2e2; }
    .arrowKey .button:hover {
      cursor: pointer;
      -webkit-box-shadow: 0 5px 5px 0 #c0c0c0;
              box-shadow: 0 5px 5px 0 #c0c0c0; }
    .arrowKey .button:focus {
      outline: none; }
  .arrowKey .arrowKeyDisabled {
    background-color: #bcc0c5;
    pointer-events: none;
    color: white; }
