/* section layout */
.faq-container {
  background-color: #eff3fe;
  padding: 4rem 0;
  margin: 0 auto; }
  .faq-container li {
    padding-bottom: 1em; }

.collapse {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
  margin-bottom: 1rem;
  padding: 2rem 3rem 1rem; }
  .collapse .collapse-header-title {
    cursor: pointer;
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px; }
    @media (max-width: 600px) {
      .collapse .collapse-header-title {
        font-size: 16px;
        line-height: 22px; } }
  .collapse p {
    font-size: 15px;
    font-family: 'GT America Standard Regular';
    line-height: 25px;
    letter-spacing: 0;
    margin-bottom: 1rem; }

.title {
  font-family: 'GT America Extended Bold';
  margin: 0 auto;
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 38px;
  text-align: center;
  padding-bottom: 2rem; }
  @media (max-width: 990px) {
    .title {
      font-size: 20px;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center; } }
