/*
	.classOne {
		background-color: #000;
	}
*/
.promo-card {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  text-align: center;
  background-color: #f0f3f8; }
  .promo-card h2,
  .promo-card h3,
  .promo-card p {
    color: #2552cb; }
  .promo-card input {
    padding: 5px; }
  .promo-card button {
    width: 10em;
    margin: 0 auto; }
  .promo-card .divider {
    background: #2552cb;
    width: 120px;
    height: 4px;
    margin: 0 auto; }

.promo-card:hover,
.special-promo-card:hover {
  cursor: pointer; }

.special-promo-card {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  text-align: center;
  background-color: #2552cb;
  color: white; }
  .special-promo-card input {
    padding: 5px; }
  .special-promo-card button {
    background-color: white;
    color: #2552cb;
    width: 10em;
    margin: 0 auto; }
  .special-promo-card .divider {
    background: white;
    width: 120px;
    height: 4px;
    margin: 0 auto; }
  .special-promo-card input {
    color: white;
    border: 1px solid white !important; }
  .special-promo-card input::-webkit-input-placeholder {
    color: white;
    opacity: 1;
    /* Firefox */ }
  .special-promo-card input::-ms-input-placeholder {
    color: white;
    opacity: 1;
    /* Firefox */ }
  .special-promo-card input::placeholder {
    color: white;
    opacity: 1;
    /* Firefox */ }

.icon {
  display: block;
  margin: auto; }

.hoverText:hover {
  color: black;
  cursor: pointer; }

.styledButton {
  background-color: inherit !important;
  border: 1px solid;
  outline: none; }
  .styledButton:hover {
    border: 1px solid;
    color: black;
    background-color: #f5f5f5 !important; }

.text {
  text-decoration: line-through; }

.price {
  font-size: 18px; }
