.voteButton {
  cursor: pointer;
  background: none;
  border: none; }
  .voteButton i:active {
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2); }

.pointerEventNone {
  pointer-events: none; }

.hideTooltip {
  opacity: 0 !important; }
