/*
	.classOne {
		background-color: #000;
	}
*/
.courseBackground {
  background: #00b1a4;
  height: 200px; }

.head {
  height: 300px; }

.sampleCard {
  -webkit-box-shadow: 0 -2px 0 #d6c87f, 0 10px 20px #dedede !important;
          box-shadow: 0 -2px 0 #d6c87f, 0 10px 20px #dedede !important; }
