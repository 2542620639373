.carousel-caption {
  position: static;
  color: black;
  padding: 1rem; }

.segment-gray-marker {
  color: #a1a1a1; }

.segment-yellow-marker {
  color: #ffcc2f; }

.segment-orange-marker {
  color: #ffac52; }

.segment-green-marker {
  color: #40bb89; }

.segment-blue-marker {
  color: #498cd8; }

.segment-purple-marker {
  color: #5e62c5; }

.segment-black-marker {
  color: #1c1c1c; }
