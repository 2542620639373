.progress {
  border-radius: 0rem;
  border: 0.2px solid #f1f1f1;
  height: 0.7rem;
  background-color: #fff;
  position: relative; }

.progress-bar {
  background-image: -webkit-gradient(linear, left top, left bottom, from(#3ab6ab), to(#20b6c7));
  background-image: -webkit-linear-gradient(#3ab6ab, #20b6c7);
  background-image: -o-linear-gradient(#3ab6ab, #20b6c7);
  background-image: linear-gradient(#3ab6ab, #20b6c7); }
