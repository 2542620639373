.session-heading {
  font-size: 22px;
  line-height: 26px;
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 0; }

.featureCard {
  border: 1px solid #b5b5b5;
  border-radius: 5px; }
  .featureCard:hover {
    cursor: pointer; }

.featureNoneCard {
  background: #f6f6f6;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .featureNoneCard:hover {
    cursor: pointer; }

.featureCardSelected {
  border: 1px solid #00b1a4;
  border-radius: 5px; }
  .featureCardSelected:hover {
    cursor: pointer; }

.featureNoneCardSelected {
  background: #f6f6f6;
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none; }
  .featureNoneCardSelected:hover {
    cursor: pointer; }

.check {
  left: 10px;
  top: 10px; }

.unchecked {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  left: 10px;
  top: 10px; }

.img {
  min-height: 6em; }

.categoriesGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
  margin: 0 3rem; }

.htmlEditor {
  width: inherit;
  font-size: 14px !important; }
  .htmlEditor * {
    font-size: 14px !important; }
  .htmlEditor:hover {
    cursor: pointer; }

.quill-editor div {
  border: none !important;
  padding-top: 0;
  padding-bottom: 0; }

.quill-editor * {
  overflow-y: hidden; }

.quill-editor:hover {
  cursor: pointer; }

/*p {*/
/*  font-size: 14px;*/
/*  line-height: 16px;*/
/*}*/
.unselectedQuestionBox {
  background-color: #fff; }

.selectedQuestionBox {
  background-color: #ebf2f7; }

.scroll-card {
  width: 100%;
  overflow-y: auto;
  max-height: 700px;
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr 1fr;*/ }

.scroll-card::-webkit-scrollbar {
  width: 6px; }

.scroll-card::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scroll-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }
