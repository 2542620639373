.navbar .nav .nav-link, .navbarContainer .nav .nav-link {
  width: 98px;
  height: 100%; }

.banner {
  height: 85px;
  background-color: #eff3fe;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1040; }
  .banner .lamp1Stick {
    height: 77.4px;
    width: 1.23px;
    background-color: #2d368e;
    position: absolute;
    left: 11.3%;
    top: -58px; }
  .banner .lamp2Stick {
    height: 77.4px;
    width: 1.23px;
    background-color: #2d368e;
    position: absolute;
    left: 18%;
    top: -70px; }
  .banner .lamp3Stick {
    height: 77.4px;
    width: 1.23px;
    background-color: #2d368e;
    position: absolute;
    right: 18%;
    top: -70px; }
  .banner .lamp4Stick {
    height: 77.4px;
    width: 1.23px;
    background-color: #2d368e;
    position: absolute;
    right: 11.3%;
    top: -58px; }
  .banner .largeLamp1 {
    position: absolute;
    left: 10%;
    top: -40px;
    width: 45px; }
  .banner .largeLamp2 {
    position: absolute;
    left: 17%;
    top: -40px;
    width: 35px; }
  .banner .largeLamp3 {
    position: absolute;
    right: 17%;
    top: -40px;
    width: 35px; }
  .banner .largeLamp4 {
    position: absolute;
    right: 10%;
    top: -40px;
    width: 45px; }
  .banner .largeFirework1 {
    height: 41px;
    width: 41px;
    position: absolute;
    left: 22%;
    top: 30px; }
  .banner .largeFirework2 {
    height: 29px;
    width: 29px;
    position: absolute;
    left: 27%;
    top: 15px; }
  .banner .largeFirework3 {
    height: 29px;
    width: 29px;
    position: absolute;
    right: 27%;
    top: 15px; }
  .banner .largeFirework4 {
    height: 41px;
    width: 41px;
    position: absolute;
    right: 22%;
    top: 30px; }
  @media (max-width: 500px) {
    .banner {
      position: relative; }
      .banner .lamp2Stick,
      .banner .lamp3Stick,
      .banner .largeLamp2,
      .banner .largeLamp3,
      .banner .largeFirework2,
      .banner .largeFirework3 {
        display: none; }
      .banner .lamp1Stick {
        left: 16%; }
      .banner .lamp4Stick {
        right: 16%; }
      .banner .largeFirework1 {
        top: 90px;
        left: 16%; }
      .banner .largeFirework4 {
        top: 90px;
        right: 16%; } }
  .banner .banner-container {
    width: 740px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    margin: auto;
    -ms-flex-align: center;
        align-items: center; }
    .banner .banner-container .banner-title {
      font-family: 'GT America Extended Bold';
      width: 266px;
      color: #2a2498;
      font-size: 30px;
      letter-spacing: 0;
      line-height: 52px;
      text-align: center;
      margin-bottom: 0; }
      .banner .banner-container .banner-title span {
        font-family: 'GT America Extended Regular'; }
    .banner .banner-container a {
      text-decoration: none; }
    .banner .banner-container .banner-button {
      width: 160px;
      border-radius: 4px;
      background-color: #ffffff;
      color: #2700a6;
      font-family: 'GT America Standard Medium';
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center;
      padding: 8px 0; }
    @media (max-width: 800px) {
      .banner .banner-container {
        -ms-flex-direction: column;
            flex-direction: column; }
        .banner .banner-container .banner-title {
          margin-bottom: 10px;
          font-size: 16px; }
          .banner .banner-container .banner-title span {
            font-size: 16px; } }
  @media (max-width: 500px) {
    .banner {
      min-height: 150px; } }

.navbar {
  height: 100px;
  border-bottom: 1px solid #f6f6f6;
  /*box-shadow: 0 10px 20px 0 rgba(0,0,0,0.05);*/
  position: -webkit-sticky;
  position: sticky;
  max-width: 100%;
  z-index: 1040; }
  @media (max-width: 500px) {
    .navbar {
      top: 0 !important; } }
  .navbar a {
    text-decoration: none; }
    .navbar a:hover {
      color: black;
      text-decoration: none; }
  .navbar .nav {
    /*position: absolute;*/
    height: 70px;
    /* width: 196px;*/
    /*right: 0;*/ }
    .navbar .nav .nav-link {
      display: inline-block;
      background-color: #f2f2f2; }
      .navbar .nav .nav-link.active span:before {
        bottom: 9px; }
      .navbar .nav .nav-link span {
        display: inline-block;
        /*padding-top: 1rem;*/
        padding-top: calc((54px / 2) - 0.5rem); }
      .navbar .nav .nav-link:hover {
        background-color: black;
        color: white; }
  .navbar .active {
    position: relative; }
    .navbar .active span:before {
      content: '';
      height: 5px;
      width: 5px;
      background-color: black;
      border-radius: 50%;
      opacity: 1;
      position: absolute;
      left: 48%;
      bottom: -10px; }

.special {
  height: 60px; }

.nav-btn a:hover {
  color: white !important; }

.modal-title {
  font-family: 'GT America Expanded Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.bold-button {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.product-item {
  /*height: 120px;*/
  width: 240px;
  text-align: left; }
  .product-item:hover {
    cursor: pointer; }
  .product-item h3 {
    color: #2a2498;
    font-family: 'GT America Standard Bold';
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 18px; }
  .product-item p {
    /*min-height: 40px;*/
    color: #000000;
    font-family: 'GT America Standard Regular';
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px; }

.navbarContainer {
  max-width: 1270px;
  margin: 0 auto; }
  .navbarContainer a {
    text-decoration: none; }
    .navbarContainer a:hover {
      color: black;
      text-decoration: none; }
  .navbarContainer .nav {
    /*position: absolute;*/
    height: 70px;
    /* width: 196px;*/
    /*right: 0;*/ }
    .navbarContainer .nav .nav-link {
      display: inline-block;
      background-color: #f2f2f2; }
      .navbarContainer .nav .nav-link.active span:before {
        bottom: 9px; }
      .navbarContainer .nav .nav-link span {
        display: inline-block;
        /*padding-top: 1rem;*/
        padding-top: calc((54px / 2) - 0.5rem); }
      .navbarContainer .nav .nav-link:hover {
        background-color: black;
        color: white; }
  .navbarContainer .active {
    position: relative; }
    .navbarContainer .active span:before {
      content: '';
      height: 5px;
      width: 5px;
      background-color: black;
      border-radius: 50%;
      opacity: 1;
      position: absolute;
      left: 48%;
      bottom: -10px; }
  .navbarContainer .mobile-nav-container {
    max-width: 300px;
    text-align: left;
    margin: auto; }

.specialContainer {
  max-width: 100%; }
