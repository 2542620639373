.skill-wrapper {
  position: relative;
  height: 100%;
  /* width: 100%;*/
  -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  -o-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1); }
  .skill-wrapper.fixed {
    height: 200px; }
    .skill-wrapper.fixed .skill-level-wrapper {
      margin: unset; }
  .skill-wrapper.onHover {
    padding: 20px 20px 0;
    overflow: hidden; }
    .skill-wrapper.onHover .overlay {
      -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      position: absolute;
      width: inherit;
      height: inherit;
      right: 0;
      bottom: 0;
      left: -110%;
      z-index: 1; }
    .skill-wrapper.onHover .skill-label-small {
      -webkit-transform: translateX(-100px);
          -ms-transform: translateX(-100px);
              transform: translateX(-100px);
      opacity: 0; }
    .skill-wrapper.onHover:hover {
      /* padding: 10px 10px 0;*/
      -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      -o-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
      -webkit-transform: scale(1.05, 1.05);
          -ms-transform: scale(1.05, 1.05);
              transform: scale(1.05, 1.05);
      -webkit-box-shadow: 1px 1px 1.5px 1.5px #dcdcdc;
              box-shadow: 1px 1px 1.5px 1.5px #dcdcdc;
      border: none; }
      .skill-wrapper.onHover:hover .overlay {
        -webkit-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
        position: absolute;
        width: inherit;
        height: inherit;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1; }
  .skill-wrapper .skill-label-small {
    opacity: 1; }
  .skill-wrapper .skill-level-wrapper {
    position: relative;
    width: 86px;
    height: 86px;
    overflow: hidden;
    padding-top: 5px;
    padding-left: 5px;
    /* align-items: center;*/
    margin: 0 auto; }
    .skill-wrapper .skill-level-wrapper .skill-level.skill-level-beginner {
      width: calc(141.42% / 3);
      height: calc(141.42% / 3); }
      .skill-wrapper .skill-level-wrapper .skill-level.skill-level-beginner.validated {
        width: calc(200% / 3);
        height: calc(200% / 3); }
    .skill-wrapper .skill-level-wrapper .skill-level.skill-level-intermediate {
      width: calc(141.42% / 3 * 2);
      height: calc(141.42% / 3 * 2); }
      .skill-wrapper .skill-level-wrapper .skill-level.skill-level-intermediate.validated {
        width: calc(200% / 3 * 2);
        height: calc(200% / 3 * 2); }
    .skill-wrapper .skill-level-wrapper .skill-level.skill-level-professional {
      width: 141.42%;
      height: 141.42%; }
      .skill-wrapper .skill-level-wrapper .skill-level.skill-level-professional.validated {
        width: 200%;
        height: 200%; }
    .skill-wrapper .skill-level-wrapper .add-skill-icon {
      position: absolute;
      top: 58%;
      left: 42%;
      margin: 0;
      -webkit-transform: translate3d(-50%, -50%, 0);
              transform: translate3d(-50%, -50%, 0); }
  .skill-wrapper .skill-label {
    color: #7c7b7b;
    padding: 10px 0;
    text-align: left; }
  .skill-wrapper .lvl-decrement {
    position: absolute;
    top: 60px;
    left: calc(50% - 84px); }
  .skill-wrapper .lvl-increment {
    position: absolute;
    top: 60px;
    right: calc(50% - 84px); }

.skill-level,
.skill-level-background {
  position: absolute;
  left: 0;
  top: 100%;
  -webkit-transform: translate3d(-50%, -50%, 0) rotate(45deg);
          transform: translate3d(-50%, -50%, 0) rotate(45deg);
  -webkit-transform-origin: center center;
      -ms-transform-origin: center center;
          transform-origin: center center;
  width: 200%;
  height: 200%;
  background: #f2f2f2; }

.skill-level-background,
.validated,
.no-skill-level {
  border-radius: 100%; }
