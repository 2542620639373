/* section layout */
.subtitle {
  max-width: 480px;
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px; }
  @media (max-width: 500px) {
    .subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px; } }

.product-card {
  padding: 2%;
  text-align: center; }

.how-it-works {
  font-size: 25px; }

.min-height-desktop {
  min-height: 650px !important; }

.bold-title {
  font-family: 'GT America Standard Medium';
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px; }
  @media (max-width: 767.98px) {
    .bold-title {
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px; } }

.image-container {
  height: 70px;
  width: 70px; }

.blue-background {
  background-color: #eff3fe;
  padding-top: 3em; }

.main-title {
  font-family: 'GT America Expanded Bold';
  font-weight: 900;
  color: #2a2498;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 48px; }
  @media (max-width: 500px) {
    .main-title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 48px; } }

.option-card {
  font-family: 'GT America Standard Medium';
  max-height: 92px;
  width: 445px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1); }
  @media (max-width: 767.98px) {
    .option-card {
      height: 9em;
      width: 300px; } }

.inactive {
  font-weight: 500;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 26px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }

.description {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 26px; }

.fixed-height-mobile {
  height: 11em !important; }

.fixed-height-ipad {
  height: 7em !important; }

.fixed-height-desktop {
  height: 8em !important; }
