.avatar {
  font-family: "GT America Expanded Light", "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  height: 33px;
  width: 33px;
  background-color: #000;
  color: #fff; }
  .avatar img {
    width: 100%;
    height: 100%;
    text-align: center;
    -o-object-fit: 'cover';
       object-fit: 'cover'; }
  .avatar.large {
    margin: 10px 20px 12px 10px;
    width: 66px;
    height: 66px;
    font-size: 20px;
    line-height: 24px; }
