.container {
  max-width: 1270px;
  margin: 0 auto; }

.customers {
  margin-top: 5rem; }

.bg-large {
  position: absolute;
  top: 1450px;
  z-index: -1;
  height: 1316px;
  width: 94%;
  border-radius: 71px 71px 0 0;
  background: -webkit-gradient(linear, left top, left bottom, from(#eff3fe), to(#ffffff));
  background: -webkit-linear-gradient(top, #eff3fe 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #eff3fe 0%, #ffffff 100%);
  background: linear-gradient(180deg, #eff3fe 0%, #ffffff 100%);
  margin: 0 3%;
  overflow: auto; }

.skills {
  /*margin-top: 2rem;*/
  /*border-radius: 0 24px 100px 0;*/
  /*background-color: #dae4ff;*/
  /*//background: linear-gradient(135deg, #f6f8fe 20%, #dae4ff 100%) no-repeat;*/
  /*//background-size: 300px 500px;*/
  /*padding: 2rem;*/
  margin-top: -500px;
  margin-bottom: 15rem; }

.highlighted {
  margin-top: 2rem;
  /*background: linear-gradient(135deg, #f6f8fe 0%, #dae4ff 20%) no-repeat;*/
  height: 580px;
  width: 95vw;
  border-radius: 0 24px 100px 0;
  margin-right: -8rem;
  background: -webkit-linear-gradient(315deg, #f6f8fe 20%, #dae4ff 100%) no-repeat;
  background: -o-linear-gradient(315deg, #f6f8fe 20%, #dae4ff 100%) no-repeat;
  background: linear-gradient(135deg, #f6f8fe 20%, #dae4ff 100%) no-repeat;
  background-size: 95vw 580px; }

/* .skillGrid {*/
/*   //display: grid;*/
/*   //grid-template-columns: 1fr auto 1fr;*/
/* }*/
.absContainer {
  /*position: absolute;*/
  z-index: -1;
  overflow: hidden; }

.testimonialsBg {
  /*position: absolute;*/
  /*margin-left: 1000px;*/
  height: 682px;
  width: 682px;
  border-radius: 341px;
  margin-right: -8rem;
  background: -webkit-linear-gradient(315deg, #dce5ff 0%, #eff3fe 100%) no-repeat;
  background: -o-linear-gradient(315deg, #dce5ff 0%, #eff3fe 100%) no-repeat;
  background: linear-gradient(135deg, #dce5ff 0%, #eff3fe 100%) no-repeat;
  background-size: 682px 682px;
  /*float: right;*/ }

.testimonials {
  margin-top: 5rem;
  /*border: 1px solid red;*/ }
  @media (max-width: 540px) {
    .testimonials {
      margin-top: 0; } }

.temp {
  /*position: relative;*/
  margin-top: -670px; }

.asSeen {
  padding-top: 5rem; }
  @media (max-width: 540px) {
    .asSeen {
      padding-top: 0; } }

.lightBgWrapper {
  background-color: #eff3fe; }

.whiteBgWrapper {
  background-color: #ffffff; }

.darkBlueBgWrapper {
  background-color: #2a2498; }
