.hero {
  /* position: absolute;*/
  /* top: -1rem;*/
  overflow: hidden;
  /* height: 100%;*/
  width: 100%;
  margin-top: 1rem !important;
  /* padding: 1rem 0;*/
  /* max-height: calc(100vh - 54px);*/
  /* h1 {*/
  /*   margin: 0 auto 1rem;*/
  /* }*/
  /* h1,*/
  /* h2 {*/
  /*   width: 100%;*/
  /* }*/ }
  @media (min-width: 1000px) {
    .hero {
      padding-left: 1rem; } }
  @media (max-width: 1280px) {
    .hero img {
      width: 580px; } }
  @media (max-width: 550px) {
    .hero img {
      width: 90%; } }

.hilightedMicro {
  color: #291d9b;
  font-family: 'GT America Standard Medium';
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 31px; }

.heroCTA {
  color: #6d7278;
  font-family: 'GT America Standard Regular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 31px;
  margin-right: 1rem; }
  @media (max-width: 990px) {
    .heroCTA {
      margin-right: 0; } }
  @media (max-width: 575px) {
    .heroCTA {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.22px;
      line-height: 26px;
      margin-left: 0;
      margin-right: 0; } }

.downloadBtn {
  -webkit-transition: ease-in-out 0.15s;
  -o-transition: ease-in-out 0.15s;
  transition: ease-in-out 0.15s;
  margin: 0 0.57rem;
  text-align: center;
  cursor: pointer; }
  .downloadBtn img {
    height: 50px;
    width: 50px; }
  .downloadBtn:focus .downloadBtn:active {
    outline: none; }
  .downloadBtn:hover {
    -webkit-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
            transform: translateY(-2px);
    opacity: 0.85; }

.topSection {
  background-color: #f3f3f3; }

.heroContent {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.margin-top-desktop {
  margin-top: 0em; }

.shapes {
  padding-left: 6.3em; }
  .shapes h3 {
    padding-top: 1rem !important;
    font-size: 18px;
    font-weight: 600px; }

.heroTitle {
  font-family: 'GT America Extended Regular';
  text-align: left;
  margin: 0 auto;
  margin-bottom: 35px;
  font-size: 42px;
  letter-spacing: 0;
  line-height: 42px; }
  .heroTitle .bold {
    font-family: 'GT America Extended Bold';
    color: #291d9b; }
  @media (max-width: 575px) {
    .heroTitle {
      margin-top: 2.5em;
      width: 100%;
      font-size: 32px;
      letter-spacing: 0.19px;
      line-height: 30px; }
      .heroTitle .bold {
        font-family: 'GT America Extended Bold';
        color: #291d9b; } }

.heroSubTitle {
  font-family: 'GT America Standard Regular';
  color: #000000;
  font-size: 22px;
  letter-spacing: 0;
  line-height: 31px;
  margin: 0.7em 0; }
  @media (max-width: 1280px) {
    .heroSubTitle {
      width: 320px;
      margin: 0.4rem auto;
      /* border: 1px solid red;*/
      /* text-align: center;*/ } }
  @media (max-width: 575px) {
    .heroSubTitle {
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0.22px;
      line-height: 26px;
      margin-left: 2rem;
      /* text-align: center;*/ } }
  @media (max-width: 376px) {
    .heroSubTitle {
      margin-left: 3rem; } }
  @media (max-width: 320px) {
    .heroSubTitle {
      margin-left: 2rem; } }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.submitBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0;
  padding: 3px 30px; }

.or {
  max-width: 463px;
  margin: 0 auto; }

.slackBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  line-height: 31px;
  text-align: center;
  margin: auto 0; }

.policy {
  margin-top: 0rem;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 23px; }

.heroContentCompany {
  position: relative;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.heroCompany {
  position: relative;
  overflow: hidden;
  /* height: 100%;*/
  /* min-height: calc(100vh - 54px);*/
  padding-bottom: 20px; }
  .heroCompany h1 {
    margin: 0 auto 1rem; }
  .heroCompany h1,
  .heroCompany h2 {
    width: 100%; }
  @media (max-width: 779px) {
    .heroCompany {
      /* min-height: 900px;*/ } }
  @media (max-width: 550px) {
    .heroCompany {
      /* min-height: 750px;*/ } }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.svg {
  width: 12px; }

.highlight {
  min-height: 660px;
  background: #2a2498; }
  @media (max-width: 550px) {
    .highlight {
      min-height: 400px; } }

.column {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .column h3 {
    margin-top: 1em;
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px; }
  @media (max-width: 550px) {
    .column h3 {
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 18px; } }

.last {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 3em; }
  .last h3 {
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 25px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px; }
  @media (max-width: 550px) {
    .last {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px; } }

.shape {
  width: 20px; }
  @media (max-width: 550px) {
    .shape {
      width: 14px; } }

.visual-one {
  position: absolute;
  right: 0;
  top: 20em; }
  @media (max-width: 550px) {
    .visual-one {
      top: 15em; } }

.visual-two {
  position: absolute;
  left: 0;
  top: 5em; }
  @media (max-width: 550px) {
    .visual-two {
      top: 3em; } }
