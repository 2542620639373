.indorse-info img {
  height: 16px;
  width: 16px;
  margin-bottom: 16px; }

.indorse-info-item {
  padding: 0 10px; }

.indorse-info-item p {
  margin-left: 10px; }
