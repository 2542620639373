/* TODO: will add global variable shadow color once merge  landing-page*/
.member-card {
  height: 14rem;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  display: block;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow-y: hidden; }
  .member-card:hover {
    text-decoration: none;
    -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
            box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
    color: #000; }
  @media (max-width: 575.98px) {
    .member-card {
      width: 71vw; } }

.member-card-small {
  height: 8rem !important; }

.member-card-body {
  padding: 1rem; }
  .member-card-body a {
    text-decoration: none; }
  .member-card-body ul li {
    margin-bottom: 0.15em; }
  .member-card-body ul h3,
  .member-card-body ul li {
    font-size: 1rem; }
    @media (max-width: 575.98px) {
      .member-card-body ul h3,
      .member-card-body ul li {
        font-size: 3.5vw; } }
  .member-card-body ul p {
    font-size: 0.85rem; }
    @media (max-width: 575.98px) {
      .member-card-body ul p {
        font-size: 3.5vw; } }

.avatar {
  width: 4rem;
  height: 4rem;
  line-height: 3.8rem; }

.responsive {
  padding: 0 1rem; }
