.container {
  max-width: 1270px;
  margin: 0 auto; }
  .container h2 {
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 990px) {
      .container h2 {
        font-size: 24px; } }
  .container h6 {
    font-family: 'GT America Standard Regular';
    font-size: 22px;
    letter-spacing: 0;
    line-height: 31px;
    text-align: center; }
    @media (max-width: 990px) {
      .container h6 {
        font-size: 18px; } }

.card {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 2rem; }

.card-small {
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1rem; }

.claims-new {
  background-color: #eff3fe; }
  @media (max-width: 575.98px) {
    .claims-new h1 {
      font-size: 1.2rem; } }

.help {
  font-size: 1.5rem;
  color: #000;
  vertical-align: text-top; }
  .help:hover {
    color: #0080d6; }

@media (max-width: 767.98px) {
  .hiddenSm {
    display: none !important; } }

@media (min-width: 768px) {
  .hiddenMd {
    display: none !important; } }

.githubSection {
  margin-top: 100px; }

.list-class {
  list-style: circle !important;
  margin-left: 10%; }

.submit-code {
  font-size: 20px;
  font-family: 'GT America Extended Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: lighter;
  cursor: pointer; }

@media (max-width: 560px) {
  .submit-code {
    font-size: 14px;
    font-family: 'GT America Extended Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-weight: lighter;
    cursor: pointer; }
  .instruction {
    margin-top: 5%; }
  .tada {
    font-size: 16px; } }

.coding-language {
  padding: 8px; }

.email {
  padding: 10px; }

.tada {
  font-size: 50px; }

.take-an-assignment {
  background-color: inherit !important;
  border: 1px solid;
  outline: none; }
  .take-an-assignment:hover {
    border: 1px solid;
    color: black;
    background-color: #f5f5f5 !important; }

.ordered-list {
  font-family: 'GT America Standard Regular';
  padding-left: 1rem; }
  .ordered-list > li {
    padding: 6px; }

.resouces p {
  color: #9b9b9b;
  font-family: 'GT America Standard Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px; }

.resouces h3 {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px; }

.resouces a {
  text-decoration: none; }
