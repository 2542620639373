.tableContainer {
  margin-top: 2rem; }
  .tableContainer .tableTitle {
    text-align: center; }
    .tableContainer .tableTitle h1 {
      margin: 0;
      color: #000;
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 32px; }
  .tableContainer .nameTD {
    max-width: 150px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .tableContainer .linkTD {
    max-width: 200px;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: blue; }
