.secTitle {
  color: #000000;
  font-size: 24px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 26px; }

.reportList table {
  border-collapse: collapse;
  width: 100%;
  padding: 0 0.6rem; }

.reportList th {
  background-color: black;
  text-align: left;
  color: white;
  padding: 1rem 1.3rem; }

.reportList td {
  text-align: left;
  padding: 0.8rem 1.3rem;
  font-size: 16px;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.reportList tr:nth-child(odd) {
  background-color: #ebf2f7; }
