.smallHeader {
  line-height: 22px;
  width: 100%; }

.magic-link {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01);
  font-weight: 900;
  border-radius: 10px;
  width: 99%;
  margin-bottom: 3%;
  padding: 15px !important; }

.magic-link-info-card {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01);
  font-weight: 900;
  border-radius: 10px;
  width: 99%;
  border: none;
  padding: 20px !important; }

.info-header {
  font-size: 1.2rem;
  line-height: 25px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.info-list {
  margin-left: 30px; }

.assessment-header {
  display: -ms-flexbox;
  display: flex; }
  .assessment-header h3 {
    max-width: 180px; }

.assessment-details {
  display: -ms-flexbox;
  display: flex; }

.assessment-buttons {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .assessment-buttons button {
    font-size: 12px; }

.scrollCard {
  overflow-y: auto;
  height: 600px; }
  .scrollCard > div:nth-of-type(even) {
    background-color: #f8f9fb; }

.scrollCard::-webkit-scrollbar {
  width: 6px; }

.scrollCard::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scrollCard::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }

.container {
  display: -ms-flexbox;
  display: flex; }

.selected {
  background-color: #2552cb;
  width: 5px;
  margin-right: 10px; }

.blue {
  color: #2552cb; }

.date {
  font-size: 10px; }
