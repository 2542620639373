.social-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  max-width: 186px;
  border-radius: 20px; }
  @media (max-width: 560px) {
    .social-btn {
      max-width: 100%; }
      .social-btn span {
        -ms-flex-pack: center;
            justify-content: center; } }

.google-btn {
  color: #ffffff;
  background-color: #db4437; }
  .google-btn:hover {
    background-color: #db5549; }
  .google-btn i {
    margin: 0 0.7em; }
  @media (max-width: 560px) {
    .google-btn {
      font-size: 16px; }
      .google-btn i {
        margin: 0 0.5em; } }
