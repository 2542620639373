.globalBg {
  background-color: #eff2f7;
  min-height: 100vh; }

.reportHeading {
  background-color: white;
  -webkit-box-shadow: 0 10px 20px 0 rgba(99, 99, 99, 0.1);
          box-shadow: 0 10px 20px 0 rgba(99, 99, 99, 0.1);
  text-align: center;
  padding: 1.5rem; }
  .reportHeading .bold-standard {
    font-size: 26px;
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 26px; }
  .reportHeading .standardReg {
    font-size: 26px;
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 26px; }
  .reportHeading .bold-expanded {
    font-size: 26px;
    font-family: 'GT America Expanded Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 26px; }

.container {
  max-width: 820px;
  margin: 2rem auto;
  padding: 0 1rem; }

.topCard {
  background: #fbfdff; }

.reportDetailsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* align-items: center;*/
  /* justify-content: start;*/
  -ms-flex-pack: space-after;
      justify-content: space-after;
  gap: 0.5rem 2rem; }

.title {
  /* font-size: 26px;*/
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* line-height: 26px;*/
  font-size: 18px;
  /* font-weight: bold;*/
  letter-spacing: 0;
  line-height: 22px; }

.heading {
  font-size: 22px;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 22px; }

.boldHeading {
  font-size: 22px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 22px; }

.majorTitle {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-bottom: 1rem; }

.minorTitle {
  /* font-size: 18px;*/
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* line-height: 18px;*/
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px; }

.secTitle {
  color: #000000;
  font-size: 20px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 20px; }

.bodyText {
  color: #000000;
  /* font-size: 16px;*/
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  /* line-height: 16px;*/
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin: 0; }

.card {
  border: 1px solid #f0f0f0;
  -webkit-box-shadow: 0 10px 20px 0 rgba(99, 99, 99, 0.1);
          box-shadow: 0 10px 20px 0 rgba(99, 99, 99, 0.1);
  background-color: #f7f7f757; }

.repoTag {
  background-color: rgba(13, 218, 204, 0.11);
  /* background-color:rgb(213, 255, 252);*/
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  color: #00b1a5;
  font-size: 14px; }

.contribTag {
  background-color: #f1eeff;
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  color: #6e55fd;
  font-size: 14px; }

.moreBtn {
  /* background-color: rgba(136, 136, 136, 0.788);*/
  padding: 0.3rem 0.6rem;
  border-radius: 3px;
  /* color: rgb(0, 177, 165);*/
  font-size: 14px;
  cursor: pointer; }
