.react-custom-select {
  z-index: 0;
  position: relative;
  color: #000;
  display: block;
  margin-bottom: 5px;
  border-bottom: 1px solid #000; }
  .react-custom-select.disabled {
    cursor: unset; }
  .react-custom-select.selector-shown {
    z-index: 1001; }
  .react-custom-select.invalid .selection-label {
    color: #ff0000; }
  .react-custom-select .selection-label {
    /*font-size: 0.65rem;*/
    font-size: 15px;
    margin-bottom: 0.5rem; }
  .react-custom-select .selection {
    position: relative;
    color: #000;
    min-width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-align: baseline;
        align-items: baseline;
    margin-bottom: 5px;
    cursor: pointer; }
    .react-custom-select .selection.placeholder {
      color: #7c7b7b; }
  .react-custom-select ul {
    position: absolute;
    left: 0;
    top: calc(100% + 15px);
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background: #fff; }
    .react-custom-select ul li {
      color: #7c7b7b;
      margin: 0;
      list-style: none;
      min-width: 100%;
      padding: 10px 15px;
      cursor: pointer;
      width: 100%;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .react-custom-select ul li:hover {
        color: #000;
        background: #f2f2f2; }
      .react-custom-select ul li.active {
        background: #000;
        color: #fff; }
