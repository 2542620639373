/*
	.classOne {
		background-color: #000;
	}
*/
.btnSetup {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px; }

.overlay {
  display: block;
  width: 100%;
  /*height: 450px;*/ }
  .overlay .text {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -40%) rotate(-45deg);
            transform: translate(-50%, -40%) rotate(-45deg);
    -ms-transform: translate(-50%, -40%) rotate(-45deg);
    font-size: 60px;
    line-height: 100px;
    color: #efefef;
    font-weight: 900; }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }

.legend-box {
  background-color: #f5f5f5;
  padding: 1rem 3rem;
  -ms-flex-align: center;
      align-items: center;
  margin: 0 2rem; }

.legend {
  -ms-flex-align: end;
      align-items: end;
  height: 10px;
  background: -webkit-gradient(linear, left top, right top, from(#eb3b57), color-stop(50%, #e3e3e3), to(#60d081));
  background: -webkit-linear-gradient(left, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
  background: -o-linear-gradient(left, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
  background: linear-gradient(90deg, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
  width: 440px; }

.legend-label {
  width: 480px; }

@media (max-width: 1200px) {
  .legend-box {
    background-color: #f5f5f5;
    padding: 1rem 0;
    -ms-flex-align: center;
        align-items: center;
    margin: 0 auto;
    width: 540px; }
  .legend {
    -ms-flex-align: end;
        align-items: end;
    height: 10px;
    background: -webkit-gradient(linear, left top, right top, from(#eb3b57), color-stop(50%, #e3e3e3), to(#60d081));
    background: -webkit-linear-gradient(left, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
    background: -o-linear-gradient(left, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
    background: linear-gradient(90deg, #eb3b57 0%, #e3e3e3 50%, #60d081 100%);
    width: 440px;
    margin: 0 auto; }
  .legend-label {
    width: 480px;
    margin: 0 auto; } }

.chart-title {
  color: #000000;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  letter-spacing: 0.1px;
  line-height: 24px;
  margin: 0; }

.scroll-card {
  width: 95%;
  overflow-y: auto;
  height: auto;
  padding-bottom: 1rem;
  overflow-x: auto; }

.scroll-card::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

.scroll-card::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scroll-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }

.graphGrid {
  /*margin-left: 1rem;*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem; }

.insights-box {
  height: 60px;
  border-radius: 0 0 5px 5px;
  background-color: rgba(0, 177, 164, 0.15); }
  .insights-box .primary .bulb {
    width: 60px;
    height: 60px;
    border-radius: 0 0 0 5px;
    background-color: #00b1a4;
    color: #ffffff; }

.reminder p {
  font-size: 20px;
  line-height: 30px; }

.bold-text {
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.actionable-insights .title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 28px;
  /*letter-spacing: 1px;*/
  line-height: 36px; }

.actionable-insights .sub-title {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 24px;
  /*letter-spacing: 1px;*/
  line-height: 32px; }

.actionable-insights .disclaimer {
  font-size: 14px;
  line-height: 26px;
  color: #454545;
  font-style: italic;
  text-align: left; }

.actionable-insights .sub-text {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px; }

.actionable-insights .table .text {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 1rem 0; }

.actionable-insights .table .header {
  background-color: black;
  color: white;
  font-size: 16px;
  font-weight: bold;
  padding: 1rem 0.5rem 1rem 1.5rem; }

.actionable-insights .table .row {
  padding: 1rem 0.5rem 1rem 1.5rem; }
  .actionable-insights .table .row .title {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px; }
  .actionable-insights .table .row .data {
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px; }
  .actionable-insights .table .row .blue-title {
    color: #2552cb; }
  .actionable-insights .table .row .green-title {
    color: #00b1a4; }

.actionable-insights .table .blue-row {
  border-left: 2px solid #2552cb;
  background-color: rgba(37, 82, 203, 0.1); }

.actionable-insights .table .green-row {
  border-left: 2px solid #00b1a4;
  background-color: rgba(0, 177, 164, 0.1); }

.chart-heading {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 30px;
  line-height: 30px; }

.survey-chart {
  height: 8px;
  background: #dfe2e7;
  width: 600px;
  padding-left: 16px;
  border-radius: 4px; }

.survey-legend {
  width: 660px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-flex-pack: justify;
      justify-content: space-between;
  justify-items: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.insights-and-resources {
  /*background-color: rgba(37, 82, 203, 0.11);*/ }
  .insights-and-resources .heading {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    line-height: 22px;
    /*margin: 0 !important;*/ }
  .insights-and-resources .subHeading {
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 16px; }

.resources-grid {
  /*min-height: 200px;*/
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  justify-items: center;
  -ms-flex-align: center;
      align-items: center; }
  .resources-grid .resource-box {
    background-color: rgba(0, 177, 164, 0.18);
    border: 2px solid rgba(0, 195, 180, 0.1);
    height: 100px;
    width: 100%;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    /*padding: 1rem;*/
    -ms-flex-align: center;
        align-items: center;
    vertical-align: middle;
    -webkit-transition: 0.2s ease-in-out;
    -o-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out; }
    .resources-grid .resource-box .heading {
      font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      place-self: center; }
  .resources-grid .resource-box:hover {
    cursor: pointer;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border: 2px solid #00b1a4;
    /*color: white;*/
    /*transform: scale(1.1);*/ }

.chartMobile .survey-chart {
  height: 8px;
  background: #dfe2e7;
  width: 300px;
  padding-left: 16px;
  border-radius: 4px; }

.chartMobile .survey-legend {
  /*position: relative;*/
  /*left: -1rem;*/
  width: 330px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  -ms-flex-pack: justify;
      justify-content: space-between;
  justify-items: center;
  -ms-flex-align: center;
      align-items: center;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .chartMobile .survey-legend span {
    font-size: 10px; }

.platformCard {
  margin: 1rem 1.5rem;
  text-align: center;
  -webkit-box-shadow: rgba(221, 230, 237, 0.5) 0px 16px 32px 0px;
          box-shadow: rgba(221, 230, 237, 0.5) 0px 16px 32px 0px;
  border-radius: 8px;
  width: 190px;
  padding: 2rem 0;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 2px solid black; }
  .platformCard h5 {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .platformCard:hover {
    -webkit-box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
            box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px; }

.insightsWrapper {
  padding: 3rem 0; }

.devOpsInsightsWrapper {
  margin-top: 2rem; }
  .devOpsInsightsWrapper .title {
    color: #000000;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 22px;
    line-height: 22px; }
    .devOpsInsightsWrapper .title .boldText {
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .devOpsInsightsWrapper .subTitle {
    color: #000000;
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 16px; }
  .devOpsInsightsWrapper .actionBtn {
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 16px; }

.tabTitle {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px; }
