/*
	.classOne {
		background-color: #000;
	}
*/
.card-container {
  margin: 0 auto; }

.scroll-card {
  width: 100%;
  overflow-y: auto;
  max-height: 700px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; }

.scroll-card::-webkit-scrollbar {
  width: 6px; }

.scroll-card::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scroll-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }

.test-card {
  background-color: #f1f4f8;
  min-height: 250px; }
  .test-card h3 {
    font-size: 18px;
    line-height: 24px; }
  .test-card p {
    display: block;
    /* or inline-block */
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    max-height: 3.6em;
    width: calc 80%; }
  .test-card a {
    margin: 0 auto; }
