input {
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 0;
  display: -ms-flexbox;
  display: flex;
  padding-right: 25px;
  border: 0;
  padding-bottom: 7px;
  border-bottom: 1px solid #7c7b7b;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  -ms-flex-order: 2;
      order: 2;
  position: relative; }

.react-autosuggest__container {
  position: relative !important; }
  .react-autosuggest__container .react-autosuggest__suggestions-list {
    position: absolute !important;
    z-index: 1004;
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    background: #fff; }
    .react-autosuggest__container .react-autosuggest__suggestions-list li {
      color: #7c7b7b;
      margin: 0;
      list-style: none;
      min-width: 180px;
      padding: 10px 15px;
      cursor: pointer;
      width: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box; }
      .react-autosuggest__container .react-autosuggest__suggestions-list li:hover {
        color: #000;
        background: #f2f2f2; }
      .react-autosuggest__container .react-autosuggest__suggestions-list li.active {
        background: #000;
        color: #fff; }
