.projects {
  margin: 0 auto;
  max-width: 1112px; }
  .projects .heading {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 990px) {
      .projects .heading {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center;
        width: 344px;
        margin: 0 auto; } }
  .projects .subTxt {
    color: #000000;
    font-family: 'GT America Standard Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 990px) {
      .projects .subTxt {
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center;
        width: 344px;
        margin: 1.5rem auto 0; } }
  .projects .projectCard {
    margin: 3rem 0;
    border-radius: 5px;
    background-color: #ffffff;
    height: 310px;
    width: 309px;
    -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
            box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
    text-align: left;
    padding: 2rem; }
    .projects .projectCard .imgBg {
      height: 140px;
      -webkit-transform: translateX(-2rem);
          -ms-transform: translateX(-2rem);
              transform: translateX(-2rem); }
    .projects .projectCard .title {
      margin-top: 0.5rem;
      font-family: 'GT America Extended Bold';
      letter-spacing: 0;
      text-align: left;
      color: #28129d;
      font-size: 50px;
      line-height: 24px; }
    .projects .projectCard .description {
      font-family: 'GT America Extended Bold';
      letter-spacing: 0;
      text-align: left;
      margin-top: 2rem;
      width: 154px;
      color: #2700a3;
      font-size: 20px;
      line-height: 24px; }
    .projects .projectCard .special {
      color: #00b2a5; }
