.tab-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  /*border-bottom: 2px solid #ebf2f7;*/
  background-color: #ebf2f7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem; }

.tab-item {
  /*width: 100%;*/
  border-bottom: 3px solid #ebf2f7;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .tab-item:hover {
    cursor: pointer; }
  .tab-item .hidden {
    height: 1.5rem;
    border-radius: 5px 5px 0 0; }
  .tab-item .visible {
    background-color: white; }
  .tab-item .tab-main {
    padding: 0.5rem 2rem;
    text-align: center;
    background-color: #ebf2f7;
    color: #b0bdc4;
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px; }

.tab-selected {
  /*width: 100%;*/
  border-bottom: 3px solid #0052cc;
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
  .tab-selected:hover {
    cursor: pointer; }
  .tab-selected .hidden {
    height: 1.5rem;
    border-radius: 5px 5px 0 0;
    background-color: white; }
  .tab-selected .tab-main {
    padding: 0.5rem 2rem;
    text-align: center;
    background-color: #ebf2f7;
    color: #0052cc;
    /* font-size: 24px;*/
    /*font-weight: 700;*/
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-transition: 0.01s ease-out;
    -o-transition: 0.01s ease-out;
    transition: 0.01s ease-out; }

.tab-content {
  background: white;
  min-height: 300px;
  /*border-radius: 0 0 5px 5px;*/
  /*box-shadow: 0 0 30px 0 rgba(76, 147, 230, 0.1);*/ }
