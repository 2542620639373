.json {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  background-color: #f3f3f3;
  padding: 10px; }

.code-container {
  max-height: 500px;
  overflow: auto; }

.code-container::-webkit-scrollbar {
  width: 6px; }

.code-container::-webkit-scrollbar-track {
  background-color: #dae0e6; }

.code-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }
