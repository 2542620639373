/* prettier-ignore */
@font-face {
  font-family: 'GT America Light';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Bold';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Medium';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Medium.otf") format("opentype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Regular';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.ttf") format("truetype"); }

body {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em; }

body,
input,
textarea,
select,
button {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000; }

h1,
h2,
h3,
h4,
h5,
h5,
h6,
p,
span,
a {
  text-rendering: optimizelegibility;
  font-weight: normal;
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0; }

h1.gray,
h2.gray,
h3.gray,
h4.gray,
h5.gray,
h6.gray,
p.gray,
span.gray {
  color: #7c7b7b; }

h1.uppercase,
h2.uppercase,
h3.uppercase,
h4.uppercase,
h5.uppercase,
h6.uppercase,
p.uppercase,
span.uppercase {
  text-transform: uppercase; }

h1.text-centered,
h2.text-centered,
h3.text-centered,
h4.text-centered,
h5.text-centered,
h6.text-centered,
p.text-centered,
span.text-centered {
  text-align: center; }

.test {
  color: pink; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
p,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

button.metamorph-btn {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.card {
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
  padding: 1rem 2rem; }

.emptyState {
  text-align: center;
  padding: 4rem 1rem;
  margin: 2rem;
  min-height: 85vh; }
  .emptyState .placeholderImg {
    margin: 2rem auto 0;
    /*height: 100%;*/
    width: 90%; }
  .emptyState .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    margin: 2rem 0 0; }
  .emptyState .subtitle {
    margin: 1rem auto 2rem;
    width: 511px;
    color: #202024;
    font-family: 'GT America Standard Regular', sans-serif;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center; }
  .emptyState .actionBtn {
    color: #ffffff;
    font-size: 16px;
    font-weight: 900;
    letter-spacing: 0;
    line-height: 24px;
    padding: 0.4rem 2.5rem 0.5rem;
    border-radius: 38px; }

.metericesGrid {
  margin: 0 auto;
  padding: 0 2rem 3rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 2rem 1rem;
  grid-auto-flow: dense; }

.filterCard {
  margin-bottom: 2rem;
  padding: 2rem;
  /*border-radius: 10px;*/
  background-color: #ffffff;
  /*box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);*/
  /*border-right: 5px solid #004dcf;*/ }
  .filterCard .templateTitle {
    margin: 0;
    height: 25px;
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px; }
  .filterCard .pickerInput {
    width: 150px;
    border: 2px solid #ebf2f7 !important;
    text-align: center;
    padding: 0.2rem 0.3rem;
    border-radius: 7px;
    font-size: 14px;
    margin: 0;
    height: 38px; }

.resetBtn {
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: #e9e9e9;
  font-size: 14px;
  cursor: pointer;
  color: black;
  -webkit-transition: all 0.02s ease-in;
  -o-transition: all 0.02s ease-in;
  transition: all 0.02s ease-in;
  outline: none; }
  .resetBtn:hover, .resetBtn:active {
    background-color: #303030;
    border: 1px solid #303030;
    color: white;
    outline: none; }

.step-title {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px; }
  .step-title span {
    font-size: 16px;
    font-weight: normal;
    font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #4b4f51; }

.finish-btn {
  padding: 8px 50px; }

.chartTitle {
  margin: 0;
  color: #000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px; }

.infoBox {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #0052cc;
  /*opacity: 0.6;*/
  border-radius: 10px;
  background-color: #cce0ff;
  margin: 0 2rem 1rem;
  padding: 1rem; }
  .infoBox .info {
    background-color: #0052cc;
    color: white;
    height: 22px;
    padding-top: 4px;
    width: 22px;
    border-radius: 11px;
    opacity: 1; }
  .infoBox .text {
    font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 18px; }
    .infoBox .text span {
      font-family: 'GT America Standard Medium', sans-serif;
      color: #0052cc;
      cursor: pointer; }
  .infoBox .title {
    color: #000;
    font-family: 'GT America Standard Medium', sans-serif;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    margin: 0; }
