.form-card {
  width: 99%; }

.form-header {
  /*font-size: 20px;*/
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #5243a9; }

.inline-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center; }
  .inline-textp {
    display: -ms-flexbox;
    display: flex; }

.inline-form {
  margin: -6px 6px 0 6px;
  width: 350px; }

.fields {
  margin: 0 auto;
  display: table; }
