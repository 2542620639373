/*
	.classOne {
		background-color: #000;
	}
*/
.scroll-card {
  width: 100%;
  min-height: 390px;
  position: relative; }

.title {
  height: 38px;
  width: 384px;
  color: #000000;
  font-size: 24px;
  letter-spacing: 1.88px;
  line-height: 38px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.sub-title {
  height: 25px;
  width: 419px;
  color: #000000;
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 25px; }

.modal-title {
  height: 25px;
  color: #000000;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 25px; }

.stepBtn {
  font-size: 16px;
  width: 150px;
  padding: 10px; }

.cardInput {
  padding: 10px; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.bold-text {
  font-family: 'GT America Standard Bold'; }

.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 20px;
  color: #e4584c; }

.plan-details {
  border-radius: 4px;
  background-color: #eef2f7;
  color: black;
  text-transform: capitalize;
  font-size: 13px;
  padding: 0 2em;
  margin-top: 1em; }
  .plan-details .planType {
    color: black;
    font-size: 15px;
    text-transform: capitalize;
    display: -ms-flexbox;
    display: flex;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
    .plan-details .planType span {
      font-family: 'GT America Light'; }
  .plan-details .seats {
    color: #0052cc;
    text-transform: initial;
    font-size: 15px;
    line-height: 24px;
    font-family: 'GT America Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.line {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 2px;
  width: 200px;
  border: 1px solid #e4eaf0;
  margin: 1em 0; }

.update-payment {
  position: absolute;
  bottom: 24px; }

.reminder p {
  font-size: 20px;
  line-height: 30px; }
