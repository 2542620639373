.topics {
  padding: 4rem 0; }
  .topics .header {
    margin-bottom: 4rem; }
    @media (max-width: 990px) {
      .topics .header {
        width: 344px;
        margin: 0 auto 2rem; } }
    .topics .header h1 {
      color: #000000;
      font-family: 'GT America Extended Bold';
      font-size: 32px;
      letter-spacing: 0;
      line-height: 38px;
      text-align: center; }
      @media (max-width: 990px) {
        .topics .header h1 {
          font-size: 22px;
          letter-spacing: 0;
          line-height: 28px;
          text-align: center; } }
    .topics .header p {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      text-align: center; }
      @media (max-width: 990px) {
        .topics .header p {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 26px;
          text-align: center; } }
  .topics .secondary {
    height: 407px;
    width: 458px;
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
            box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
    margin-right: 6rem;
    padding: 2rem; }
    @media (max-width: 990px) {
      .topics .secondary {
        width: 350px;
        height: auto;
        margin: 0 auto; } }
    .topics .secondary .pointers {
      margin: 2rem 0; }
      .topics .secondary .pointers p {
        color: #000000;
        font-family: 'GT America Extended Medium';
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px;
        margin-left: 1rem; }
        @media (max-width: 990px) {
          .topics .secondary .pointers p {
            font-size: 16px;
            letter-spacing: 0;
            line-height: 26px; } }
      .topics .secondary .pointers .svg {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 26px; }
