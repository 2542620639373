.border-black {
  padding: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
  height: 330px;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12); }
  .border-black:hover {
    -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
    background-color: white;
    border-style: none; }
  @media (max-width: 560px) {
    .border-black {
      max-width: 300px;
      margin: auto; } }

.small-title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-top: 1.5em;
  margin-bottom: 0; }

.cta-button {
  position: absolute;
  bottom: 30px;
  left: 0; }

.huge-margin-top {
  margin-top: 1rem !important; }

.more-padding {
  padding-top: 5em;
  padding-bottom: 5em; }

.feature-description {
  min-height: 4em !important;
  max-width: 212px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: auto; }

.blue-background {
  background-color: #eff3fe; }

.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 990px) {
    .title {
      font-size: 22px;
      letter-spacing: 0;
      line-height: 28px;
      text-align: center;
      width: 360px;
      margin: 0 auto; } }
