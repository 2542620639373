.title {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 5%;
  text-align: center; }

.center-text h2 {
  margin: 0 auto; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.position {
  color: #2552cb;
  font-size: 24px; }

.tooltip:hover {
  position: relative; }

.tooltip[aria-label]:hover:after {
  content: attr(aria-label);
  font-family: 'GT America Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  width: 180px;
  color: #d0d0d0;
  padding: 10px;
  position: absolute;
  left: -85px;
  top: 100%;
  z-index: 20;
  border-radius: 5px;
  background: black;
  font-size: 14px;
  line-height: 20px; }
