.mtb-m {
  margin: 4rem 0; }

.mb-m {
  margin-bottom: 1rem; }

.sign-up-social button {
  width: 100%; }

@media (min-width: 576px) {
  .sign-up-header {
    -ms-flex-order: 1;
        order: 1; }
  .sign-up-form {
    -ms-flex-order: 3;
        order: 3; }
  .sign-up-social {
    -ms-flex-order: 2;
        order: 2; } }

@media (min-width: 768px) {
  .sign-up-header {
    -ms-flex-order: 1;
        order: 1; }
  .sign-up-form {
    -ms-flex-order: 2;
        order: 2; }
  .sign-up-social {
    -ms-flex-order: 3;
        order: 3; } }

.left-section {
  min-height: 100vh;
  background-color: #2a2498; }
  .left-section .title {
    max-width: 458px;
    color: #ffffff;
    font-family: 'GT America Expanded Medium';
    font-size: 28px;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center; }
  .left-section .subtitle {
    max-width: 434px;
    color: #ffffff;
    font-family: 'GT America Standard Regular';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 30px;
    text-align: center; }
  .left-section .try-it-free {
    max-width: 203px;
    border-radius: 22px;
    background-color: #ffffff;
    color: #2a2498;
    font-family: 'GT America Standard Medium';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px; }
  .left-section .try-it-free:hover {
    background-color: #ffffff;
    color: #2a2498; }
  .left-section .free-trial-text {
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px; }
  .left-section .end {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 110px; }
  .left-section .end-logos {
    position: absolute;
    bottom: 50px;
    margin: 0 auto;
    max-width: 513px;
    left: 0;
    right: 0; }
  .left-section .companies-text {
    max-width: 224px;
    color: #ffffff;
    font-family: 'GT America Standard Medium';
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 35px; }

.right-section .title {
  color: #000000;
  font-family: 'GT America Expanded Medium';
  font-size: 25px;
  letter-spacing: 0;
  line-height: 31px;
  margin-bottom: 0; }

.right-section .line {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 1px;
  width: 200px;
  border: 2px solid #00b1a4; }

.right-section .or {
  /* max-width: 463px;*/
  margin: 0 auto; }

.right-section .dash {
  width: 275px;
  height: 3px; }

.right-section .signup-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  min-width: 156px;
  border-radius: 20px;
  color: #000000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1.5px solid #000000;
  background-color: #ffffff; }

@media (max-width: 560px) {
  .right-section .title {
    font-size: 16px; }
  .right-section .signup-btn {
    width: 100%;
    margin: 0.5em auto; } }

.join-btn {
  min-width: 120px;
  border-radius: 20px;
  background-color: #e0e0e0;
  font-family: 'GT America Standard Medium';
  text-align: center; }
  .join-btn:hover {
    background-color: #000000;
    color: white; }
  @media (max-width: 560px) {
    .join-btn {
      width: 100%; } }
