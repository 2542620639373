/*
	.classOne {
		background-color: #000;
	}
*/
.header {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.voteButton {
  cursor: pointer;
  background: none;
  border: none; }
  .voteButton i:active {
    -webkit-transform: scale(1.2);
        -ms-transform: scale(1.2);
            transform: scale(1.2); }

.pointerEventNone {
  pointer-events: none; }

.arrow {
  color: green; }

.tableClass > tr > td {
  vertical-align: baseline !important; }

/* .tableClass {*/
/*   border: 1px solid black;*/
/*   border-bottom: none;*/
/* }*/
.buttonSpan > span {
  font-size: 11px;
  padding: 1px; }
