/* section layout */
/* section layout */
/*desktop*/
/*mobile*/
/*desktop*/
/*top*/
.sec-pt-0 {
  padding-top: 0; }

.sec-pt-1 {
  padding-top: 2.5rem; }

.sec-pt-2 {
  padding-top: 5.5rem; }

.sec-pt-3 {
  padding-top: 12rem; }

/*bottom*/
.sec-pb-0 {
  padding-bottom: 0; }

.sec-pb-1 {
  padding-bottom: 2.5rem; }

.sec-pb-2 {
  padding-bottom: 5.5rem; }

.sec-pb-3 {
  padding-bottom: 12rem; }

.sec-pb-4 {
  padding-bottom: 18rem; }

/*top and bottom*/
.sec-py-0 {
  padding: 0; }

.sec-py-1 {
  padding: 2.5rem 0; }

.sec-py-2 {
  padding: 5.5rem 0; }

.sec-py-3 {
  padding: 12rem 0; }

@media (max-width: 1199.98px) {
  .sec-pt-m-0 {
    padding-top: 0; }
  .sec-pt-m-1 {
    padding-top: 1.562rem; }
  .sec-pt-m-2 {
    padding-top: 2.187rem; }
  .sec-pt-m-3 {
    padding-top: 3.125rem; }
  .sec-pt-m-4 {
    padding-top: 8rem; }
  /*bottom*/
  .sec-pb-m-0 {
    padding-bottom: 0; }
  .sec-pb-m-1 {
    padding-bottom: 1.562rem; }
  .sec-pb-m-2 {
    padding-bottom: 2.187rem; }
  .sec-pb-m-3 {
    padding-bottom: 3.125rem; }
  .sec-pb-m-4 {
    padding-bottom: 8rem; }
  /*top and bottom*/
  .sec-py-m-0 {
    padding: 0; }
  .sec-py-m-1 {
    padding: 1.562rem 0; }
  .sec-py-m-2 {
    padding: 2.187rem 0; }
  .sec-py-m-3 {
    padding: 3.125rem 0; }
  .sec-py-m-4 {
    padding: 8rem 0; } }

.home {
  overflow-x: hidden;
  /* zoom: 85%;*/
  /* layout*/
  /*typography*/ }
  .home p {
    font-size: 14px;
    font-weight: 300 !important;
    font-style: normal;
    font-stretch: normal; }
    @media (max-width: 767.98px) {
      .home p {
        font-size: 13px; } }
  .home .mt-l {
    margin-top: 7rem; }
  .home .mb-l {
    margin-bottom: 7rem; }
  .home .mt-xl {
    margin-top: 10rem; }
  .home .mb-xl {
    margin-bottom: 10rem; }
  .home .typography-p-large {
    font-size: 20px;
    line-height: 1.4em; }
  .home .typography-hl-extra {
    font-size: 52px;
    line-height: 1.1em; }

@media (max-width: 991.98px) {
  .home .mt-xl {
    margin-top: 0rem; }
  .home .mt-l {
    margin-top: 1rem; }
  .home .mb-l {
    margin-bottom: 1rem; }
  .home .mb-xl {
    margin-bottom: 2rem; }
  .home .my-l {
    margin: 1rem 0; }
  .home .typography-p-large {
    font-size: 20px;
    line-height: 1.4em; }
  .home .typography-hl-extra {
    font-size: 22px;
    line-height: 1.2em; } }

.border-bottom {
  border-bottom: solid 0.2px #f2f2f2 !important; }

.border-bottom-black {
  border-bottom: 0.2px solid #000;
  width: 100%; }

.pb-100 {
  padding-bottom: 7.1rem; }
  @media (max-width: 767.98px) {
    .pb-100 {
      padding-bottom: 3.3rem; } }

.border-shadow {
  -webkit-box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.2); }

/* TODO: use this from home-v2 once finalized*/
.yellow-bg-color {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01); }

.auto-greyscale-img-wrapper img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: 0.55; }
  .auto-greyscale-img-wrapper img:hover {
    -webkit-filter: none;
            filter: none;
    opacity: 1; }

@media (max-width: 575.98px) {
  .typography-p-large {
    font-size: 13px !important;
    line-height: 1.4em; } }
