.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }
  @media (min-width: 992px) {
    .hero {
      margin-bottom: -50px; } }

.lookBeyond {
  font-size: 25px; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.hero-content {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.heroCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.title-text {
  font-size: 39px !important;
  line-height: 55px !important; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }
  @media (max-width: 1199px) and (min-width: 576px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 1rem !important; } }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }
