.questionBox {
  font-size: 20px;
  margin: 2rem 0; }

.question {
  font-size: 20px;
  width: 100%;
  overflow-x: auto; }
  .question .questionNum {
    font-size: 14px; }

.question::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important; }

.question::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6 !important; }

.question::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
  background-color: #aaaeb3 !important; }

.countdown {
  font-size: 24px;
  line-height: 32px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: black; }
  .countdown .clock {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.htmlEditor {
  width: inherit;
  font-size: 16px !important; }
  .htmlEditor p {
    font-size: 20px !important;
    line-height: 34px !important;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    font-weight: 500 !important; }
  .htmlEditor * {
    font-size: 20px !important;
    line-height: 26px !important;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .htmlEditor pre {
    font-size: 14px !important;
    line-height: 18px !important; }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }

.test-main p {
  font-size: 18px;
  line-height: 24px;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.test-main h2 {
  font-size: 32px;
  font-weight: 900;
  line-height: 40px;
  color: #2552cb; }

.answer-box {
  /*border: 1px solid #737373;*/
  display: -ms-flexbox;
  display: flex;
  border-radius: 5px;
  margin: 1rem 0;
  /*padding-right: 4rem;*/
  -ms-flex-item-align: start;
      align-self: start; }

.section-title {
  color: #0052cc;
  font-size: 40px;
  letter-spacing: -0.74px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 50px;
  text-align: center; }

.bold-text {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

@media (max-width: 640px) {
  .question {
    font-size: 20px;
    width: 100%;
    overflow-x: auto; }
    .question .questionNum {
      font-size: 10px; }
  .section-title {
    color: #0052cc;
    font-size: 30px;
    letter-spacing: -0.74px;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 38px;
    text-align: center; }
  .countdown {
    font-size: 16px;
    line-height: 32px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    color: black; }
    .countdown .clock {
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
  .htmlEditor {
    width: inherit;
    font-size: 16px !important; }
    .htmlEditor p {
      font-size: 16px !important;
      line-height: 20px !important;
      font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
      font-weight: 500 !important; }
    .htmlEditor pre {
      font-size: 14px !important;
      line-height: 18px !important; }
  .test-main p {
    font-size: 14px;
    line-height: 22px;
    /*font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;*/ }
  .test-main h2 {
    font-size: 18px;
    font-weight: 900;
    line-height: 28px;
    color: #2552cb; } }
