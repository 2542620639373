.title {
  color: #000000;
  font-size: 30px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 30px; }

.secTitle {
  color: #000000;
  font-size: 20px;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 20px; }

.repoCard {
  text-align: center;
  -webkit-box-shadow: rgba(221, 230, 237, 0.3) 0px 16px 32px 0px;
          box-shadow: rgba(221, 230, 237, 0.3) 0px 16px 32px 0px;
  border-radius: 3px;
  width: 220px;
  padding: 0.5rem 1rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: 1px solid #d6d6d6; }
  .repoCard:hover {
    border: 1px solid black;
    -webkit-box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
            box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
    cursor: pointer; }

.dateBox {
  text-align: center;
  -webkit-box-shadow: rgba(221, 230, 237, 0.3) 0px 16px 32px 0px;
          box-shadow: rgba(221, 230, 237, 0.3) 0px 16px 32px 0px;
  border-radius: 3px;
  /*width: 220px;*/
  padding: 0.5rem 1rem;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: 1px solid #d6d6d6; }
  .dateBox:hover {
    border: 1px solid black;
    -webkit-box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
            box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;
    cursor: pointer; }

.all {
  width: 100%; }

.active {
  border: 1px solid black;
  background: black;
  color: white; }

.timeLineFilter {
  padding: 0.3rem 0.6rem;
  border: 1px solid #eaedff;
  border-radius: 3px;
  -webkit-transition: all 0.02s ease-in-out;
  -o-transition: all 0.02s ease-in-out;
  transition: all 0.02s ease-in-out;
  margin: 0.2rem 0.8rem 0.2rem 0;
  font-size: 14px;
  color: #6c5efb; }
  .timeLineFilter:hover {
    border: 1px solid #6c5efb;
    /*box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;*/
    cursor: pointer; }

.activeTimeLineFilter {
  background-color: #eaedff;
  border: 1px solid #eaedff;
  color: #6c5efb; }

.disabledTimeLineFilter {
  border: 1px solid #dadada;
  color: #a5a5a5;
  pointer-events: none; }
  .disabledTimeLineFilter:hover {
    border: 1px solid #dadada;
    pointer-events: none;
    /*box-shadow: rgba(188, 188, 190, 0.7) 0px 16px 32px 0px;*/
    cursor: none; }

.statBox .title {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px; }

.statBox .count {
  color: #0052cc;
  font-family: 'GT America Standard Bold';
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 28px; }
  .statBox .count .supp {
    color: #0052cc;
    font-family: 'GT America Standard Medium';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 28px; }

.sticky {
  position: fixed;
  /* top: 80px;*/
  /* bottom: 260px;*/
  min-width: 340px;
  max-width: 340px;
  -webkit-transition: ease-out 0.5s;
  -o-transition: ease-out 0.5s;
  transition: ease-out 0.5s; }

.filter-title {
  color: #000000;
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 20px; }

.arrowKey {
  background-color: #ebf2f7;
  padding: 0.34rem 1rem;
  border: none;
  /* border-bottom: 3px solid #0052cc00;*/
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: ease-in-out 0.1s;
  -o-transition: ease-in-out 0.1s;
  transition: ease-in-out 0.1s; }
  .arrowKey .button {
    cursor: pointer;
    font-size: 16px;
    line-height: 24px;
    background-color: white;
    -webkit-transition: ease-in-out 0.1s;
    -o-transition: ease-in-out 0.1s;
    transition: ease-in-out 0.1s;
    color: black;
    outline: none;
    border: none;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    -webkit-box-shadow: 0 2px 5px 0 #e2e2e2;
            box-shadow: 0 2px 5px 0 #e2e2e2; }
    .arrowKey .button:hover {
      cursor: pointer;
      -webkit-box-shadow: 0 5px 5px 0 #c0c0c0;
              box-shadow: 0 5px 5px 0 #c0c0c0; }
    .arrowKey .button:focus {
      outline: none; }
  .arrowKey .arrowKeyDisabled {
    background-color: #bcc0c5;
    pointer-events: none;
    color: white; }

.filterTitle {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px; }

.filterBtn {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center; }

.tabTitle {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px; }

.emptyTitle {
  color: #000000;
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center; }

.connectBtn {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px; }
