.blueBackground {
  padding: 70px 0;
  background-color: #2a2498; }

.title {
  color: #fff;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 575px) {
    .title {
      font-size: 24px;
      line-height: 30px; } }

.subtitle {
  color: #fff;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 10px auto 70px; }
  @media (max-width: 575px) {
    .subtitle {
      font-size: 18px;
      line-height: 28px;
      margin: 10px auto 40px; } }

.cards {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 90px; }
  @media (max-width: 991px) {
    .cards {
      -ms-flex-direction: column;
          flex-direction: column;
      margin-top: 20px; } }

.card {
  min-height: 377px;
  position: relative;
  width: 400px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 38px 20px;
  -webkit-box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 0 1px 0 rgba(10, 22, 70, 0.06), 0 32px 40px -2px rgba(10, 22, 70, 0.12); }
  @media (max-width: 991px) {
    .card {
      width: 80%;
      margin: 35px auto 0;
      min-height: 350px; } }

.new {
  padding: 6px 18px;
  width: 69px;
  border-radius: 14.5px;
  background-color: #00b1a4;
  position: absolute;
  top: -12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #ffffff;
  font-family: 'GT America Standard Bold';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center; }

.cardImage {
  width: 80px;
  margin: 0 auto 40px; }

.cardTitle {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 12px; }

.cardText {
  max-width: 343px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
  margin: 0 auto;
  min-height: 70px; }
