.notification-wrapper {
  width: 285px;
  z-index: 99; }
  .notification-wrapper.full-width {
    width: 60vw;
    /* todo: full width*/ }
    .notification-wrapper.full-width .notification {
      padding: 8px;
      text-align: center; }

.notification {
  position: relative;
  background-color: #fff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  -ms-flex-align: center;
      align-items: center;
  padding: 17px 14px;
  margin-bottom: 8px;
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2); }
  .notification.error {
    background-color: #ffafb0; }
  .notification.success {
    background-color: #7c7b7b;
    color: #fff; }
  .notification.round {
    padding: 12px 12px 12px 22px;
    border-radius: 150px; }
  .notification .notification-text {
    width: calc(100% - 40px); }
  .notification.hide-close-btn {
    padding: 22px; }
    .notification.hide-close-btn .notification-text {
      width: 100%;
      /*text-align: center;*/ }
