/*
	.classOne {
		background-color: #000;
	}
*/
.platform-box {
  /*border: 1px solid #ebebeb;*/
  border-radius: 4px;
  padding: 0 0 8px;
  min-width: 100px;
  width: 150px;
  overflow-x: hidden; }

.platform-box-selected {
  border: 1px solid #44b1a4 !important;
  border-radius: 4px;
  padding: 0 0 8px;
  min-width: 100px;
  width: 150px;
  overflow-x: hidden; }

.check {
  right: -10px;
  top: -10px; }
