.background {
  background-color: #f7faff;
  min-height: 46em; }

.orderSummary {
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 25px; }

.plan {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 22px; }

.planType {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 18px; }

.amount,
.charge {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px; }

.amountVal,
.chargeVal {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 18px; }

.totalAmount {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0.18px;
  line-height: 22px; }

.totalAmountVal {
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 18px; }

.line {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 2px;
  width: 279px;
  border: 1px solid #e4eaf0;
  margin: 1em auto; }

.plan-box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 2px;
  cursor: pointer; }
  .plan-box h2 {
    font-size: 24px;
    letter-spacing: 0;
    line-height: 24px;
    font-weight: 900; }
  .plan-box .price {
    font-size: 36px;
    letter-spacing: 0;
    line-height: 34px;
    font-weight: 900; }
    .plan-box .price span {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 34px;
      font-weight: unset; }
  .plan-box .billed {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center; }
  .plan-box.active {
    border: 2px solid #0052cc; }
    .plan-box.active h2 {
      color: #0052cc; }
    .plan-box.active .price {
      color: #0052cc; }

.order-card {
  max-width: 24.5em;
  margin: 0 auto; }

.cardInput {
  padding: 10px;
  margin-top: 2%; }

.cardHolderName input {
  font-family: 'GT America Standard Regular', sans-serif;
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 45px;
  /*width: 340px;*/
  width: 100%;
  border: 1px solid #d1dbe3;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0 1rem; }
  @media (max-width: 990px) {
    .cardHolderName input {
      margin: 1rem auto;
      width: 100%; } }

.email {
  padding: 8px; }

.nextBtn {
  margin: 30px 170px 170px; }

.stepBtn {
  /*color: #ffffff;*/
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 28px;
  padding: 0.4rem 2.5rem 0.5rem;
  border-radius: 38px; }

.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 6px;
  margin-bottom: 20px;
  color: #e4584c; }

.bold-text {
  font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.point-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-align: center;
      align-items: center; }

.point {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px; }
