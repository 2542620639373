.whiteBackground {
  padding: 70px 0;
  background-color: #ffffff; }

.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 575px) {
    .title {
      font-size: 24px;
      line-height: 30px; } }

.subtitle {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 10px auto 70px; }
  @media (max-width: 575px) {
    .subtitle {
      font-size: 18px;
      line-height: 28px;
      margin: 10px auto 0; } }

.growthContainer {
  background-image: url("https://indorse-staging-bucket.s3.amazonaws.com/Map.png");
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 580px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center; }
  @media (max-width: 575px) {
    .growthContainer {
      grid-template-columns: 1fr;
      /* background: none;*/
      background-position: center;
      justify-items: center;
      -ms-flex-align: center;
          align-items: center; } }

.info {
  margin-top: 40px; }
  .info .number {
    color: #2a2498;
    font-family: 'GT America Extended Bold';
    font-size: 60px;
    letter-spacing: 0;
    line-height: 80px; }
    .info .number span {
      font-size: 30px; }
  .info .text {
    color: #2a2498;
    font-family: 'GT America Extended Bold';
    font-size: 20px;
    letter-spacing: 0;
    line-height: 24px; }
  @media (max-width: 575px) {
    .info {
      margin-top: 0; }
      .info .number {
        font-size: 30px;
        line-height: 40px; }
        .info .number span {
          font-size: 20px; }
      .info .text {
        font-size: 16px; } }
