.dropzone {
  max-width: 22rem;
  height: 7.5rem;
  border: solid 1px; }

.dropzone-accept {
  border: solid 1px #00b1a4; }

.dropzone-reject {
  border: solid 1px #fb021f; }
