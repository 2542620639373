.table thead th {
  border-bottom: unset;
  text-align: center; }

.table thead tr {
  text-align-last: unset; }

.table th,
.table td {
  border-top: unset; }

@media (max-width: 575.98px) {
  .table .arrowRight {
    height: 1rem;
    width: 1rem;
    position: absolute;
    top: -1rem;
    right: 1rem;
    -webkit-animation: bounce 2s infinite;
            animation: bounce 2s infinite;
    background-image: url("../../../resources/common/right-arrow.svg");
    background-repeat: no-repeat; } }

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  40% {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px); }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); } }

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  40% {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px); }
  60% {
    -webkit-transform: translateX(-5px);
            transform: translateX(-5px); } }

.validatorBadgeCover {
  width: 75px;
  height: 75px;
  margin: 0 auto; }
