.circleBackground {
  fill: none;
  stroke: #fff1f1; }

.circleProgress {
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round; }

.circleText {
  font-size: 54px;
  font-weight: bold; }
