.header {
  background-color: white;
  /*padding: 0.5rem 1rem !important;*/
  padding: 0 !important;
  width: 100vw; }

.banner {
  position: absolute;
  z-index: 10000;
  width: 100%;
  background-color: #2a2498;
  height: 100px; }

.layout {
  position: relative;
  min-height: 100%;
  /* This allows inner content to be 100% */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: distribute;
      justify-content: space-around; }

.content {
  position: relative;
  /* padding-top: 100px; // Equal to height of Header NavBar*/
  height: 100%;
  width: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1; }

.flash-wrapper {
  position: fixed;
  width: 100%;
  z-index: 2000; }

.MQImage {
  -webkit-transform: translateY(24px);
      -ms-transform: translateY(24px);
          transform: translateY(24px); }

.bannerContainer {
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100px;
  background-color: #2a2498;
  border-bottom: 1px solid #ebedf0;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .bannerContainer .bannerText {
    font-family: 'GT America Extended Regular';
    font-size: 20px;
    color: #ffffff;
    margin: 0px 24px; }
  .bannerContainer .bannerButton {
    background-color: #fff;
    color: #201a8e;
    border-radius: 4px;
    font-family: GT America Standard Medium;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    border: none;
    padding: 10px 30px;
    cursor: pointer; }
    .bannerContainer .bannerButton a {
      text-decoration: none; }
    @media (max-width: 450px) {
      .bannerContainer .bannerButton {
        margin-top: 20px; } }
  @media (max-width: 450px) {
    .bannerContainer {
      -ms-flex-direction: column;
          flex-direction: column;
      line-height: 30px;
      height: unset;
      padding: 15px;
      text-align: center; } }
