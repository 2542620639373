/*
	.classOne {
		background-color: #000;
	}
*/
.questionBox {
  padding: 1rem;
  border: solid 1px #f3f3f3;
  margin-bottom: 1rem;
  font-size: 20px; }

.question {
  font-size: 20px;
  margin: 1rem 0;
  width: 100%;
  overflow-x: auto; }

.question::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important; }

.question::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6 !important; }

.question::-webkit-scrollbar-thumb {
  border-radius: 6px !important;
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3) !important;
  background-color: #aaaeb3 !important; }

.errorNotification {
  font-size: 16px;
  margin-top: 1rem;
  color: red; }

.htmlEditor {
  width: 100%; }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }
