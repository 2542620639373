.backgroundGrey {
  -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
  background-color: #fafafa;
  height: 95vh; }

.instructionImg {
  margin-top: 10vh; }

.borderImg {
  border: 0.5px solid #000;
  padding: 1px; }

.underline {
  text-decoration: underline; }
