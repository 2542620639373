.projects {
  margin: 2rem 0; }
  .projects .heading {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px;
    text-align: center; }
    @media (max-width: 990px) {
      .projects .heading {
        font-size: 22px;
        letter-spacing: 0;
        line-height: 28px;
        text-align: center; } }
  .projects .subTxt {
    color: #000000;
    font-family: 'GT America Standard Regular';
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center; }
    @media (max-width: 990px) {
      .projects .subTxt {
        width: 322px;
        margin: 0 auto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 26px;
        text-align: center; } }
  .projects .projectCard {
    margin: 3rem auto;
    height: 440px;
    width: 400px !important;
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
    text-align: center; }
    @media (max-width: 560px) {
      .projects .projectCard {
        max-width: 330px;
        -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
                box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2); } }
    .projects .projectCard .imgBg {
      /*height: 100px;*/
      width: 100%; }
    .projects .projectCard .title {
      margin-top: 2rem;
      color: #000000;
      font-family: 'GT America Extended Bold';
      font-size: 20px;
      letter-spacing: 0;
      line-height: 26px;
      text-align: center; }
    .projects .projectCard .btnStudent {
      /*height: 26px;*/
      padding: 0.3rem 0.8rem;
      /*width: 78px;*/
      border-radius: 4px;
      background-color: #f6ae26;
      color: #2a2498;
      font-family: 'GT America Standard Regular';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center;
      border: none; }
    .projects .projectCard .btnProf {
      /*height: 26px;*/
      padding: 0.3rem 0.8rem;
      /*width: 107px;*/
      border-radius: 4px;
      background-color: #37bcae;
      color: #ffffff;
      font-family: 'GT America Standard Regular';
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 15px;
      text-align: center; }
    .projects .projectCard .description {
      color: #000000;
      font-family: 'GT America Standard Regular';
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
      margin-top: 1rem;
      padding: 0 1.6rem; }
    .projects .projectCard .cta {
      margin: 1rem auto 1.5rem;
      border-radius: 4px;
      background-color: #000000;
      /*padding: 1rem 2rem;*/
      height: 45px;
      width: 160px;
      border: none;
      padding: 0.6rem 1.5rem;
      color: #ffffff;
      font-family: 'GT America Standard Medium';
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 24px;
      /*text-align: center;*/
      text-decoration: none; }
      .projects .projectCard .cta:hover {
        cursor: pointer; }
