.skill-info-badge {
  width: 7.5rem; }

.parentCell {
  display: table; }

.childCell {
  display: table-cell;
  vertical-align: middle; }

.wordWrap {
  word-break: break-all; }

.fixedWidth {
  width: 70px; }

.title {
  font-size: 25px;
  line-height: 27px; }

.subTitle {
  font-size: 18px;
  line-height: 20px; }

.custom-slider .slick-arrow .slick-next {
  width: 0 !important;
  right: 0 !important; }

.printWidth {
  width: 700px; }
  @media (max-width: 560px) {
    .printWidth {
      width: 475px; } }

.tableHeader {
  min-width: 2rem; }

.table-tr {
  border-bottom: 0px; }

.report {
  text-align: center;
  margin-top: -13%; }

.greyBackground {
  background-color: #f1f2f6; }

.whiteShadedBox {
  padding: 1rem 2rem;
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3); }

.intentionallyHide {
  color: white; }
