@media (min-width: 992px) {
  .benefit-section:last-of-type {
    padding-top: 0rem; } }

.hero {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .hero h1 {
    margin: 0 auto 1rem; }
  .hero h1,
  .hero h2 {
    width: 120%; }

.lookBeyond {
  font-size: 25px; }

.heroTitle {
  font-size: 40px;
  text-align: center; }

.heroSubTitle {
  font-size: 20px;
  text-align: center; }
  @media (max-width: 575px) {
    .heroSubTitle {
      font-size: 18px;
      padding: 10px 30px 30px; } }

.header-sub-title {
  line-height: 30px;
  font-weight: 900 !important;
  font-size: larger; }
  @media (max-width: 991.98px) {
    .header-sub-title {
      line-height: 20px; } }

.header-title {
  line-height: 48px; }
  @media (max-width: 991.98px) {
    .header-title {
      line-height: 30px; } }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: calc(100vh - 54px);
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.topSection {
  background-color: #f3f3f3; }

.hero-content {
  position: absolute;
  top: 0px;
  bottom: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.hero-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.heroCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.title-text {
  font-size: 39px !important;
  line-height: 55px !important; }
  @media (max-width: 575px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }
  @media (max-width: 1199px) and (min-width: 576px) {
    .title-text {
      font-size: 2rem !important;
      line-height: 2.5rem !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 1rem !important; } }

@media (max-width: 560px) {
  .counter {
    font-size: 3.5rem;
    margin-bottom: 30px;
    margin-top: 10%; }
  .stats {
    font-size: 16px; }
  .heroTitle {
    text-align: center;
    line-height: 50px; }
  .action {
    margin: 40px !important; }
  .productCard {
    min-height: 12rem;
    margin-bottom: 2rem;
    /*  width: 20rem !important;*/ } }

.productSection {
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background: #ffffff; }

.productCard {
  padding: 26px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 20rem;
  border-color: #666;
  margin: 0 2em;
  /* box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);*/ }

.productCard:hover {
  -webkit-box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
          box-shadow: 0 0 20px rgba(33, 33, 33, 0.2);
  cursor: pointer; }

.productTitle {
  font-size: 30px;
  text-align: center;
  line-height: 70px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.productDesc {
  font-size: 17px;
  text-align: center;
  line-height: 25px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.qualify {
  font-size: 1rem;
  margin-top: 5%;
  line-height: 25px; }

.bg-color {
  background: -webkit-gradient(linear, left top, right top, from(#fce312), color-stop(#fbea0d), to(#f9fd01));
  background: -webkit-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: -o-linear-gradient(left, #fce312, #fbea0d, #f9fd01);
  background: linear-gradient(90deg, #fce312, #fbea0d, #f9fd01); }

.topImg {
  margin-top: 5%; }

.talk {
  background-color: inherit !important;
  border: 1px solid; }
  .talk:hover {
    color: white;
    background-color: black !important;
    border: 1px solid black; }

.svg {
  height: 12px; }

.action {
  margin: 90px; }

.action-neg-margin {
  margin-left: -15px;
  /* to defy the margin defined by container which is exactly -15px;*/
  margin-right: -15px; }
