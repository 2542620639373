.container {
  max-width: 1270px;
  margin: 0 auto;
  /* border: 1px solid black;*/ }

.heroContent {
  position: absolute;
  top: 12.25rem;
  text-align: center;
  margin: 0 auto;
  /*width: inherit;*/
  width: 1270px; }
  @media (max-width: 990px) {
    .heroContent {
      position: relative;
      top: 0;
      width: 100%;
      margin-top: 3rem; } }

.customers {
  position: relative;
  top: -9rem;
  background-color: #ffffff;
  padding: 2rem 0 0; }
  @media (max-width: 990px) {
    .customers {
      position: relative;
      top: 0; } }

.assignments {
  margin-top: -3rem;
  position: relative;
  top: -5rem; }
  @media (max-width: 990px) {
    .assignments {
      margin-top: 0;
      position: relative;
      top: 0; } }

.help {
  /*background: #eff3fe;*/ }

.secondaryCta {
  background: #eff3fe; }

.whiteBg {
  height: 250px; }

.blueBg {
  height: 250px;
  background-color: #eff3fe; }

.report {
  /*position: relative;*/
  /*top: -5rem;*/
  padding-top: 4.5rem;
  background-color: #2a2498; }
  @media (max-width: 990px) {
    .report {
      padding-top: 0.5rem; } }

.connectForm {
  /* margin: 0*/ }
