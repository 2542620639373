.tab-header {
  display: -ms-flexbox;
  display: flex;
  /*align-items: flex-end;*/
  -ms-flex-align: stretch;
      align-items: stretch; }

.title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 2rem; }

.tab-item {
  width: 100%; }
  .tab-item:hover {
    cursor: pointer; }
  .tab-item .hidden {
    height: 1.1rem;
    border-radius: 5px 5px 0 0;
    width: 100%; }
  .tab-item .visible {
    background-color: white;
    width: 100%; }
  .tab-item .tab-main {
    width: 100%;
    height: 75%;
    max-height: 70px;
    padding: 1rem 0.5rem;
    text-align: center;
    background-color: #dbe0e8;
    color: black;
    font-family: 'GT America Expanded Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 24px; }
  @media (max-width: 1254px) {
    .tab-item .hidden {
      height: 1.1rem;
      border-radius: 5px 5px 0 0;
      width: 100%; }
    .tab-item .tab-main {
      width: 100%;
      padding: 0.6rem 0.3rem;
      text-align: center;
      background-color: #dbe0e8;
      color: black;
      font-family: 'GT America Expanded Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px; } }

.tab-selected {
  /*width: 100%;*/ }
  .tab-selected:hover {
    cursor: pointer; }
  .tab-selected .hidden {
    /*height: 1.5rem;*/
    border-radius: 5px 5px 0 0;
    background-color: white; }
  .tab-selected .tab-main {
    /*padding: 0 0.5rem;*/
    text-align: center;
    background-color: white;
    color: black;
    /* font-size: 24px;*/
    /*font-weight: 700;*/
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    -webkit-transition: 0.01s ease-out;
    -o-transition: 0.01s ease-out;
    transition: 0.01s ease-out; }

.tab-content {
  background: white;
  min-height: 300px;
  border-radius: 0 0 5px 5px;
  -webkit-box-shadow: 0 0 30px 0 rgba(76, 147, 230, 0.1);
          box-shadow: 0 0 30px 0 rgba(76, 147, 230, 0.1); }
