.slackBtn {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: auto 0;
  padding: 15px;
  border-radius: 30px; }
  .slackBtn .btn-text {
    font-size: 18px;
    font-weight: 700;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.slackBtnSmall {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin: auto 0;
  border-radius: 30px; }
  .slackBtnSmall .btn-text {
    font-size: 14px;
    font-weight: 700; }
