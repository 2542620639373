.min-width-select {
  min-width: 7vw; }

.editButton {
  margin-right: 0.5rem;
  padding: 8px; }

.deleteButton {
  padding: 10px 11px; }

.perPageContainer {
  padding-right: 1rem; }
