@media (min-width: 992px) {
  .steps .steps-list:nth-child(1):before, .steps .steps-list.fill:nth-child(1):before, .steps .steps-list:nth-child(2):before, .steps .steps-list.fill:nth-child(2):before, .steps .steps-list:nth-child(3):before, .steps .steps-list.fill:nth-child(3):before {
    content: '';
    position: absolute;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    top: 0;
    bottom: 0;
    left: 0; }
  .steps .steps-list {
    position: relative;
    width: 200px;
    height: 1rem;
    padding-left: 1.5rem; }
  .steps .steps-list:nth-child(1):before {
    background-image: url("../../../resources/common/ellipse.svg"); }
  .steps .steps-list:nth-child(1).fill:before {
    background-image: url("../../../resources/common/ellipse-fill.svg"); }
  .steps .steps-list:nth-child(2):before {
    background-image: url("../../../resources/common/square.svg"); }
  .steps .steps-list:nth-child(2).fill:before {
    background-image: url("../../../resources/common/square-fill.svg"); }
  .steps .steps-list:nth-child(3):before {
    background-image: url("../../../resources/common/tri.svg"); }
  .steps .steps-list:nth-child(3).fill:before {
    background-image: url("../../../resources/common/tri-fill.svg"); } }

@media (max-width: 1199.98px) {
  .steps .steps-list.step-first:before, .steps .steps-list.step-second:before, .steps .steps-list.step-third:before {
    content: '';
    position: absolute;
    background-size: 1rem;
    width: 1rem;
    height: 1rem;
    top: 0;
    bottom: 0;
    left: 0; }
  .steps .steps-list {
    position: relative;
    width: 200px;
    height: 1rem;
    padding-left: 1.5rem; }
    .steps .steps-list.step-first:before {
      background-image: url("../../../resources/common/ellipse-fill.svg"); }
    .steps .steps-list.step-second:before {
      background-image: url("../../../resources/common/square-fill.svg"); }
    .steps .steps-list.step-third:before {
      background-image: url("../../../resources/common/tri-fill.svg"); } }

@media (max-width: 575.98px) {
  .steps-container {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);
    padding-bottom: 1rem; } }
