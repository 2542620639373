.vertical-line {
  border-left: 1px solid rgba(0, 0, 0, 0.125);
  height: 200px;
  margin-top: 1%; }

.title {
  font-size: 24px;
  text-align: center;
  margin: 4%;
  line-height: normal; }

.vertical-align {
  margin: auto 0; }

.card-body {
  min-height: 52em; }
