.border-bottom-gray {
  border-bottom: 1px solid #bdc3c7; }

.all-border {
  border: 1px solid #bdc3c7; }

.small-height {
  height: 38px; }

.title {
  line-height: 20px; }
