/*
	.classOne {
		background-color: #000;
	}
*/
.sampleCard {
  border: 1px solid #ffffff !important;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
  -webkit-box-shadow: 0 10px 0 #342787, 0 10px 20px #dedede !important;
          box-shadow: 0 10px 0 #342787, 0 10px 20px #dedede !important; }
  .sampleCard:hover {
    /*box-shadow:*/
    /*        0 2.8px 2.2px rgba(0, 0, 0, 0.034),*/
    /*        0 6.7px 5.3px rgba(0, 0, 0, 0.048),*/
    /*        0 12.5px 10px rgba(0, 0, 0, 0.06) !important;*/
    -webkit-box-shadow: 0 10px 0 #44b1a4, 0 10px 20px #dedede !important;
            box-shadow: 0 10px 0 #44b1a4, 0 10px 20px #dedede !important; }

.doneCard {
  -webkit-box-shadow: 0 10px 0 #c0c0c0, 0 10px 20px #dedede !important;
          box-shadow: 0 10px 0 #c0c0c0, 0 10px 20px #dedede !important;
  -webkit-box-sizing: border-box !important;
          box-sizing: border-box !important;
  border: 1px solid #ffffff !important; }
  .doneCard:hover {
    -webkit-box-shadow: 0 10px 0 #33302f, 0 10px 20px #dedede !important;
            box-shadow: 0 10px 0 #33302f, 0 10px 20px #dedede !important; }

.blanketTestGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto;
  gap: 1rem 1rem;
  -ms-flex-pack: space-evenly;
      justify-content: space-evenly;
  -ms-flex-align: center;
      align-items: center; }

.content {
  background: white; }

.section-card {
  padding: 1rem 0.5rem; }
  .section-card h2 {
    font-size: 18px;
    line-height: 24px;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }
