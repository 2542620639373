.whiteSmokeBorder {
  border-color: whitesmoke; }

.disabledCursor {
  cursor: pointer; }

.noDecoration {
  text-decoration: none; }
  .noDecoration:hover {
    text-decoration: none; }

.hoverText:hover {
  color: black;
  cursor: pointer; }

@media screen and (max-width: 560px) {
  .rectangle:after {
    display: none; }
  .rectangle {
    padding: 0.3em; }
  .mobile_quizbot {
    margin: 0 auto;
    width: 50%;
    margin-bottom: 1em; } }

.rectangle {
  float: left;
  position: relative;
  height: 80px;
  border-right: none;
  background: #20b6c7 -webkit-gradient(linear, right top, left top, from(#20b6c7), to(#3ab6ab));
  background: #20b6c7 -webkit-linear-gradient(right, #20b6c7, #3ab6ab);
  background: #20b6c7 -o-linear-gradient(right, #20b6c7, #3ab6ab);
  background: #20b6c7 linear-gradient(to left, #20b6c7, #3ab6ab);
  color: white;
  font-size: 18px;
  line-height: 1.3rem; }

.rectangle:after {
  position: absolute;
  top: 17px;
  right: -24.5px;
  width: 48px;
  height: 46px;
  -webkit-transform: rotate(134deg) skewX(-10deg) skewY(-10deg);
      -ms-transform: rotate(134deg) skewX(-10deg) skewY(-10deg);
          transform: rotate(134deg) skewX(-10deg) skewY(-10deg);
  background: #20b6c7 -webkit-gradient(linear, right top, left top, from(#20b6c7));
  background: #20b6c7 -webkit-linear-gradient(right, #20b6c7);
  background: #20b6c7 -o-linear-gradient(right, #20b6c7);
  background: #20b6c7 linear-gradient(to left, #20b6c7);
  content: ''; }
