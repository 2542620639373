/* section layout */
.blue-background {
  padding: 1em 0 5em;
  background-color: #eff3fe;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05); }

.white-btn {
  background-color: white; }
  .white-btn:hover {
    background-color: #e1e1e1;
    color: black; }

.title {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 36px;
  letter-spacing: 0px;
  line-height: 48px;
  text-align: center;
  font-weight: 900;
  margin-bottom: 100px; }
  @media (max-width: 500px) {
    .title {
      width: 336px;
      margin-left: auto;
      margin-right: auto;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 34px; } }

.subtitle {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0.37px;
  line-height: 28px;
  text-align: center; }
  @media (max-width: 500px) {
    .subtitle {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin-left: auto;
      margin-right: auto; } }

.text {
  max-width: 838px;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.31px;
  line-height: 32px;
  text-align: center; }

.infobox {
  margin-top: 3em; }
  .infobox .info {
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0.31px;
    line-height: 32px; }

.accepted {
  margin-bottom: 10%; }

.divider {
  background: #00b1a4;
  width: 170px;
  height: 4px; }

.submitBtn {
  font-family: 'GT America Standard Medium';
  font-weight: 500;
  text-align: center;
  margin: auto 0;
  padding: 8px 30px;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 23px; }

.line {
  width: 275px;
  height: 3px; }

.sign-up {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 36px;
  letter-spacing: -0.1px;
  line-height: 48px;
  text-align: center;
  font-weight: 900; }
  @media (max-width: 500px) {
    .sign-up {
      font-size: 24px;
      letter-spacing: 0.24px;
      line-height: 44px; } }

.signup-container {
  margin-top: 100px; }

.policy {
  margin-top: 0rem; }

.column img {
  margin: 0 auto; }

.column h2 {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 24px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  font-weight: 900; }

.column p {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center; }

@media (max-width: 500px) {
  .column h2 {
    font-size: 18px; }
  .column p {
    font-size: 14px;
    line-height: 22px; } }
