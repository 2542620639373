/* TODO: will add global variable shadow color once merge  landing-page*/
.member-card {
  max-width: 390px;
  border-radius: 5px;
  background-color: #ffffff;
  /* box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);*/
  -webkit-box-shadow: 0 10px 20px 2px rgba(10, 22, 70, 0.12);
          box-shadow: 0 10px 20px 2px rgba(10, 22, 70, 0.12);
  min-height: 28rem;
  /* max-width: 21rem;*/
  display: block;
  opacity: 1;
  text-decoration: none;
  -webkit-transition: 0.2s ease;
  -o-transition: 0.2s ease;
  transition: 0.2s ease;
  overflow-y: hidden;
  color: #000; }
  .member-card:hover {
    text-decoration: none; }
  @media (max-width: 560px) {
    .member-card {
      max-width: 330px;
      -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2);
              box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.2); } }

@media (max-width: 560px) {
  .card-margin {
    margin: 0; } }

.companyName {
  max-width: 250px;
  margin: auto;
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center; }

.tag {
  border-radius: 5px;
  color: white; }

.description {
  width: 265px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 15px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center; }

.bottom-text {
  color: #b5b5b5;
  font-family: 'GT America Standard Medium';
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center; }

.smaller-h2 {
  font-size: 1.4em !important;
  line-height: 1.2em !important; }
  @media (max-width: 560px) {
    .smaller-h2 {
      font-size: 1.2em !important;
      line-height: 1em !important; } }

.bigger-h1 {
  font-size: 2.8em !important;
  line-height: 2em !important; }
  @media (max-width: 560px) {
    .bigger-h1 {
      line-height: 1.2em !important; } }

.member-card-small {
  height: 8rem !important; }

.member-card-body a {
  text-decoration: none; }

.member-card-body ul li {
  margin-bottom: 0.15em; }

.member-card-body ul h3,
.member-card-body ul li {
  font-size: 1rem; }
  @media (max-width: 575.98px) {
    .member-card-body ul h3,
    .member-card-body ul li {
      font-size: 3.5vw; } }

.member-card-body ul p {
  font-size: 0.85rem; }
  @media (max-width: 575.98px) {
    .member-card-body ul p {
      font-size: 3.5vw; } }

.avatar {
  width: 4rem;
  height: 4rem;
  line-height: 3.8rem; }

.responsive {
  padding: 0 1rem; }

.img-placeholder {
  /* max-height: 8em;*/
  overflow: hidden;
  height: 100px;
  width: 390px; }

.title {
  color: #000000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 560px) {
    .title {
      font-size: 24px;
      line-height: 28px; } }

.subtitle {
  max-width: 716px;
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center; }
  @media (max-width: 560px) {
    .subtitle {
      font-size: 16px;
      line-height: 26px; } }

.learnMoreBtn {
  border-radius: 4px;
  background-color: #000000;
  /*padding: 0.6rem 2rem;*/
  border: none;
  height: 45px;
  width: 160px;
  color: #ffffff;
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer; }
  .learnMoreBtn:focus {
    outline: none; }

.disabledBtn {
  background-color: gray;
  cursor: default; }
