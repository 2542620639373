.page {
  height: 100% !important; }

.page-row {
  height: 100% !important; }

.startedButton > span {
  margin: 0 !important;
  margin-left: 29% !important;
  width: 100% !important; }
