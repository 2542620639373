.whitepaper-headline {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  height: 190px; }
  .whitepaper-headline svg {
    height: 100%; }

@media (max-width: 575.98px) {
  .whitepaper-headline {
    padding: 10%; } }
