.form-item .text-area-label {
  margin-bottom: 15px; }

.form-item .text-area {
  border: 1px solid #7c7b7b;
  resize: none;
  padding: 15px 20px;
  line-height: 20px; }

.form-item .text-area-label.is-invalid + label {
  color: #ff0000; }
