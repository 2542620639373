/*
	.classOne {
		background-color: #000;
	}
*/
.submitBtn {
  color: #ffffff;
  font-size: 16px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0.4rem 2.5rem 0.5rem;
  border-radius: 38px; }

.submitBtnMobile {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  margin: auto 0; }

.pageTitle {
  color: #2a2498;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  margin-bottom: 12px; }

.pageSubTitle {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 22px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 46px; }

.hero-company {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 60em;
  padding-bottom: 20px; }
  .hero-company h1 {
    margin: 0 auto 1rem; }
  .hero-company h1,
  .hero-company h2 {
    width: 120%;
    margin-left: -10%; }

.hero-content-company {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.join-text {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 32px; }
