/* prettier-ignore */
@font-face {
  font-family: 'GT America Light';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Light.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Bold';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Bold.ttf") format("truetype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Medium';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Medium.otf") format("opentype"); }

/* prettier-ignore */
@font-face {
  font-family: 'GT America Standard Regular';
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.eot") format("embedded-opentype"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff2") format("woff2"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.woff") format("woff"), url("ui/common/metamorph/styles/GT-America/GT-America-Standard-Regular.ttf") format("truetype"); }

body {
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em; }

body,
input,
textarea,
select,
button {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000; }

h1,
h2,
h3,
h4,
h5,
h5,
h6,
p,
span,
a {
  text-rendering: optimizelegibility;
  font-weight: normal;
  text-align: inherit;
  color: inherit;
  margin: 0;
  padding: 0; }

h1.gray,
h2.gray,
h3.gray,
h4.gray,
h5.gray,
h6.gray,
p.gray,
span.gray {
  color: #7c7b7b; }

h1.uppercase,
h2.uppercase,
h3.uppercase,
h4.uppercase,
h5.uppercase,
h6.uppercase,
p.uppercase,
span.uppercase {
  text-transform: uppercase; }

h1.text-centered,
h2.text-centered,
h3.text-centered,
h4.text-centered,
h5.text-centered,
h6.text-centered,
p.text-centered,
span.text-centered {
  text-align: center; }

.test {
  color: pink; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
p,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ul,
ol,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block; }

button.metamorph-btn {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.step-title,
.template-title {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 12px; }
  .step-title span,
  .template-title span {
    font-size: 16px;
    font-weight: normal;
    font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #4b4f51; }

.template-title {
  margin-bottom: 12px; }

.step-subtitle {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6d7278;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 12px; }

.step-connect {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*margin-bottom: 40px;*/
  color: #000000;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 20px; }
  .step-connect span {
    font-family: "GT America Standard Bold", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.icon-grid {
  display: -ms-flexbox;
  display: flex;
  grid-gap: 10px; }

.preset-desc {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 1rem; }

.close {
  color: #6d7278; }

.sidebar {
  min-height: 650px;
  height: 100%;
  max-height: 650px;
  overflow: auto;
  width: 200px;
  border-right: 2px solid #f1f6f9; }
  .sidebar .sidebar-item {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    margin-bottom: 40px;
    padding-right: 30px;
    cursor: pointer; }
    .sidebar .sidebar-item.create-new {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      color: #0052cc;
      font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 20px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px; }
      .sidebar .sidebar-item.create-new .create-text {
        text-decoration: underline; }
    .sidebar .sidebar-item span {
      display: block;
      font-size: 16px;
      font-weight: normal;
      font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #4b4f51; }
    .sidebar .sidebar-item.active {
      color: #0052cc;
      border-right: 6px solid #0052cc;
      width: 100%; }

.blue-card {
  height: 100%;
  min-height: 650px;
  width: 100%;
  background-color: #f1f6f9;
  padding: 32px 48px;
  border-radius: 10px; }
  .blue-card.no-top-radius {
    border-radius: 0 0 10px 10px; }
  .blue-card .card-title {
    font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 6px; }
  .blue-card .card-desc {
    font-size: 16px;
    font-weight: normal;
    font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #4b4f51;
    margin-bottom: 10px; }
  .blue-card .card-subtitle {
    font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #6d7278;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px; }
    .blue-card .card-subtitle span {
      font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: 30px 20px;
  max-height: 430px;
  overflow: auto; }

.tada-img {
  /*height: 213px;*/
  width: 260px;
  margin: 2rem auto 0; }

.custom-tabs {
  font-family: "GT America Standard Regular", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.pill-grid {
  display: -ms-flexbox;
  display: flex;
  /* grid-template-columns: repeat(3, auto);*/
  /* grid-gap: 10px;*/
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  /* justify-content: space-between;*/ }

.nav-buttons {
  margin-top: 50px; }
  .nav-buttons .nav-btn {
    padding: 8px 50px; }

.use-btn {
  min-height: 35px;
  width: 192px;
  border-radius: 17.5px;
  background-color: #000000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }

.save-btn {
  min-height: 35px;
  width: 192px;
  border-radius: 17.5px;
  background-color: #000000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-top: 40px;
  /* position: absolute;*/
  /* bottom: 4em;*/
  /* left: 0;*/
  /* right: 0;*/
  /* margin: auto;*/ }

.services-card {
  background-color: #f1f6f9;
  padding: 32px;
  border-radius: 10px;
  min-width: 250px;
  margin: 0px 25px 20px 0;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  .services-card a {
    text-decoration: none; }
  .services-card .services {
    display: -ms-flexbox;
    display: flex; }
  .services-card .service {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-direction: column;
        flex-direction: column;
    cursor: pointer;
    position: relative; }
    .services-card .service.connected {
      border: 2px solid #00b1a4; }
    .services-card .service:hover {
      opacity: 0.8; }
    .services-card .service .check {
      position: absolute;
      top: 0.5em;
      left: 0.5em; }
  .services-card .service-title {
    display: block;
    width: 100%;
    font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 20px; }
  .services-card .service-name {
    color: #000000;
    font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 25px;
    text-align: center;
    margin-top: 10px; }

.graph-title {
  color: #000000;
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 10px; }

.confirm-title {
  font-family: "GT America Standard Medium", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 6px; }

.pat-text {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  margin-top: 1em;
  cursor: pointer; }

.pat-title h2 {
  font-family: 'GT America Extended Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.pat-desc {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px; }

.tada {
  font-family: 'GT America Extended Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #2a2498;
  font-size: 60px; }

.confirmBtn {
  color: #ffffff;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 24px;
  padding: 0.4rem 2.5rem 0.5rem;
  border-radius: 38px; }

.editTemplateInput {
  width: 100%; }
