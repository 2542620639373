.whiteBackground {
  padding: 70px 0;
  background-color: #fff; }

.email input {
  font-family: 'GT America Standard Regular';
  font-size: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 45px;
  width: 340px;
  border: 1px solid #d1dbe3;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 0 1rem; }
  @media (max-width: 990px) {
    .email input {
      margin: 1rem auto;
      width: 100%; } }

.title {
  color: #000;
  font-family: 'GT America Extended Bold';
  font-size: 32px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center; }
  @media (max-width: 575px) {
    .title {
      font-size: 24px;
      line-height: 30px; } }

.subtitle {
  color: #000;
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin: 10px auto 70px; }
  @media (max-width: 575px) {
    .subtitle {
      font-size: 18px;
      line-height: 28px;
      margin: 10px auto 40px; } }

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  margin-bottom: 5em; }
  .grid .image {
    width: 430px;
    margin: auto; }
  .grid .heading {
    color: #000000;
    font-family: 'GT America Extended Bold';
    font-size: 32px;
    letter-spacing: 0;
    line-height: 38px; }
  .grid .emailInput {
    width: 246px;
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-box-shadow: 0 10px 40px 0 rgba(98, 98, 98, 0.17);
            box-shadow: 0 10px 40px 0 rgba(98, 98, 98, 0.17);
    border: none;
    padding: 14px 20px;
    font-family: 'GT America Standard Regular'; }
  .grid .text {
    max-width: 397px;
    color: #000000;
    font-family: 'GT America Light';
    font-size: 18px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 25px; }
  .grid .flex {
    display: -ms-flexbox;
    display: flex; }
  @media (max-width: 575px) {
    .grid {
      grid-template-columns: 1fr;
      margin-bottom: 0; }
      .grid .image {
        width: 80%;
        margin: auto;
        grid-row: 1; }
      .grid .heading {
        text-align: center;
        margin: 20px 0;
        font-size: 24px; }
      .grid .text {
        text-align: center;
        margin: 20px;
        font-size: 16px; }
      .grid .emailInput {
        width: 100%;
        margin-bottom: 20px; }
      .grid .flex {
        -ms-flex-direction: column;
            flex-direction: column;
        -ms-flex-align: center;
            align-items: center; }
      .grid .joinContainer {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
            justify-content: center; }
        .grid .joinContainer button {
          width: 100%; } }
