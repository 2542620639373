/* section layout */
.blue-background {
  padding: 1em 0 5em;
  background-color: #eff3fe;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05); }

.white-btn {
  background-color: white; }
  .white-btn:hover {
    background-color: #e1e1e1;
    color: black; }

.selectBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  padding: 4px 35px;
  line-height: 31px; }

.title {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  text-align: center;
  font-weight: 900;
  font-size: 38px;
  letter-spacing: 0;
  line-height: 57px; }
  @media (max-width: 767.98px) {
    .title {
      max-width: 378px;
      font-size: 22px;
      letter-spacing: 0;
      line-height: 34px;
      margin-left: auto;
      margin-right: auto; } }

.subtitle {
  font-family: 'GT America Standard Regular';
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 60px; }
  @media (max-width: 767.98px) {
    .subtitle {
      max-width: 322px;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 26px;
      margin: 0 auto;
      padding: 2em 0; } }

.infobox {
  margin-top: 3em; }
  .infobox .info {
    max-width: 576px;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 32px; }
  @media (max-width: 767.98px) {
    .infobox .info {
      max-width: 333px;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0;
      line-height: 26px;
      margin: 0 auto; } }
