.progressContainer {
  position: relative; }
  .progressContainer .circle {
    background: ghostwhite;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    position: absolute;
    z-index: 999;
    padding: 8px 0px 8px 12px;
    -webkit-box-shadow: 1px 1px 1px black;
            box-shadow: 1px 1px 1px black;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
