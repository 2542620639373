.wizard-padding {
  padding-top: 11rem;
  padding-bottom: 6rem; }

/* TODO - add global colors*/
.gray {
  color: #7c7b7b; }

.base {
  color: #000;
  -webkit-box-shadow: 0px 2px 1px 1px #7c7b7b;
  /* Android 2.3+, iOS 4.0.2-4.2, Safari 3-4 */
  box-shadow: 0px 2px 1px 1px #7c7b7b; }
