/*
	.classOne {
		background-color: #000;
	}
*/
.csa-card {
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  -o-transition: box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  text-align: center;
  height: 400px !important; }

.csa-card:hover {
  -webkit-box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
          box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  cursor: pointer; }

.csa-card-header {
  background: #ffffff !important;
  border-bottom: none;
  border-top: 1px solid #f7f7f7; }

.csa-card-body {
  overflow-y: auto !important; }

.csa-card-body::-webkit-scrollbar {
  width: 6px !important; }

.csa-card-body::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6 !important; }

.csa-card-body::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #aaaeb3 !important; }

.csa-card-footer {
  background: #ffffff !important;
  border-top: none; }

.icon {
  display: block;
  margin: auto; }

.hoverText:hover {
  color: black;
  cursor: pointer; }

.skillTitle {
  font-size: 12px;
  text-align: center; }

.skillBox {
  border: 1px solid #e9e9e9;
  padding: 8px;
  margin: 8px;
  text-align: center;
  border-radius: 5px; }

.CSAtitle {
  font-size: 20px; }

.skillList {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto; }
