/*
	.classOne {
		background-color: #000;
	}
*/
.text-large {
  font-size: 15px; }

.poor-great {
  width: 150px; }
