.social-btn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  text-align: center;
  max-width: 186px;
  border-radius: 20px; }
  @media (max-width: 560px) {
    .social-btn {
      max-width: 100%; }
      .social-btn span {
        -ms-flex-pack: center;
            justify-content: center; } }

.slack-btn {
  color: #000000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1.5px solid #000000;
  border-radius: 20px;
  background-color: #ffffff; }
  .slack-btn img {
    margin: 0 0.7em; }
  @media (max-width: 560px) {
    .slack-btn {
      font-size: 16px; }
      .slack-btn img {
        margin: 0 0.5em; } }
