.intro-photos {
  width: 100%;
  height: auto; }

.left-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: justify;
      justify-content: space-between; }

.right-col {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: end;
      justify-content: flex-end; }

@media (max-width: 767.98px) {
  .copy {
    margin: 0 0 2rem auto; }
  .logoIcon svg {
    height: 0.6em !important; } }
