.table {
  margin-top: 1%; }

.card {
  border-radius: 10px;
  width: 99%;
  margin-bottom: 3%;
  padding: 15px !important; }

.cardOverflow {
  overflow-x: auto;
  max-width: 71.5em; }

.title {
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  text-align: center; }

.email {
  font-family: 'GT America Standard Medium', sans-serif; }

.header {
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 3% 1% 1% 0%;
  font-size: 18px; }
