.editor {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
  height: 70vh;
  background: #fff; }

.editorCard {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 250px;
  /*height: 300px;*/
  background: #fff;
  /*border: 1px solid #e6e9ed;*/ }

.editorHd {
  padding: 8px;
  border: 1px solid #e6e9ed; }

.viewContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.editorCardBd {
  min-height: 500px; }

.editorBd {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%; }

.editorSidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
  border: 1px solid #e6e9ed;
  min-height: 3rem; }

.editorContent {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  border: 1px solid #e6e9ed;
  min-height: 3rem; }

.detailsPanel {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -webkit-box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.1);
          box-shadow: 2px 0px 30px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  min-height: 3rem; }

.editorSidebar {
  /*background: #fafafa;*/ }
  .editorSidebar:first-child {
    border-right: 1px solid #e6e9ed; }
  .editorSidebar:last-child {
    border-left: 1px solid #e6e9ed; }

.flow {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  height: inherit; }

.flowCard {
  -ms-flex: 1 1 0%;
      flex: 1 1 0%;
  height: 0;
  width: 0; }

.minimapCard {
  cursor: pointer; }

.saveBtn {
  margin: 1rem auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse; }
