.title {
  font-size: 20px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.card-profile {
  padding: 15px;
  margin: 0 auto; }
  .card-profile h1 {
    margin-bottom: 0; }

.features {
  margin: 0 auto; }

.info {
  display: -ms-flexbox;
  display: flex; }
  .info .button {
    margin: auto;
    margin-right: 0; }
