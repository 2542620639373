/*
	.classOne {
		background-color: #000;
	}
*/
.modal {
  min-width: 45em; }
  @media (max-width: 560px) {
    .modal {
      min-width: 0; } }
