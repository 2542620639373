.metricCard {
  position: relative;
  border-radius: 10px;
  background-color: #ffffff;
  -webkit-box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
          box-shadow: 0 0 30px 0 rgba(20, 46, 110, 0.1);
  width: 100%;
  overflow: hidden; }
  .metricCard .heading {
    padding: 0.7rem 1rem 0.7rem 2rem;
    /*background-color: rgba(0, 82, 204, 0.1);*/
    border-bottom: 1px solid #f1f1f1; }
    .metricCard .heading .title {
      margin: 0;
      color: #000;
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 26px; }
    .metricCard .heading .reloadBtn {
      padding: 4px 16px;
      font-size: 12px;
      line-height: 16px;
      border: 1px solid #6e6e6e;
      background-color: #fff;
      border-radius: 50px;
      color: #5d5d5d;
      -webkit-transition: all 0.03s ease-in-out;
      -o-transition: all 0.03s ease-in-out;
      transition: all 0.03s ease-in-out; }
      .metricCard .heading .reloadBtn:active, .metricCard .heading .reloadBtn:focus {
        outline: none; }
      .metricCard .heading .reloadBtn:hover {
        cursor: pointer;
        border: 1px solid #4f4f4f;
        background-color: #030303;
        color: white; }
      .metricCard .heading .reloadBtn:active {
        -webkit-transform: scale(0.99);
            -ms-transform: scale(0.99);
                transform: scale(0.99); }
  .metricCard .chartContainer {
    padding: 2rem; }
  .metricCard .noDataContainer {
    padding: 2rem;
    min-height: 220px;
    color: #7a7a7a;
    width: 100%; }
    .metricCard .noDataContainer .title {
      font-family: 'GT America Standard Medium', sans-serif;
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 23px;
      margin: 0 auto;
      text-align: center; }
    .metricCard .noDataContainer .subTitle {
      color: #9aaaaf;
      font-family: 'GT America Standard Regular', sans-serif;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      margin: 0 auto; }
  .metricCard .errorContainer {
    min-height: 220px;
    color: #7a7a7a; }
  .metricCard .errorFlex {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: center;
        align-items: center;
    height: 80%; }

.largeCard {
  grid-column: span 12;
  /*@media (min-width: 1440px) {*/
  /*  grid-column: span 6;*/
  /*}*/ }
  @media (min-width: 1025px) {
    .largeCard {
      grid-column: span 8; } }
  @media (min-width: 1640px) {
    .largeCard {
      grid-column: span 8; } }
  @media (min-width: 2560px) {
    .largeCard {
      grid-column: span 4; } }

.mediumCard {
  grid-column: span 12; }
  @media (min-width: 1025px) {
    .mediumCard {
      grid-column: span 6; } }
  @media (min-width: 1640px) {
    .mediumCard {
      grid-column: span 4; } }
  @media (min-width: 2560px) {
    .mediumCard {
      grid-column: span 3; } }

.smallCard {
  grid-column: span 12; }
  @media (min-width: 769px) {
    .smallCard {
      grid-column: span 6; } }
  @media (min-width: 1025px) {
    .smallCard {
      grid-column: span 4; } }
  @media (min-width: 1640px) {
    .smallCard {
      grid-column: span 3; } }
  @media (min-width: 2560px) {
    .smallCard {
      grid-column: span 2; } }

.title {
  margin: 0;
  color: #000;
  font-family: 'GT America Standard Medium', sans-serif;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px; }
