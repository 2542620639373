/*
	.classOne {
		background-color: #000;
	}
*/
.header {
  font-size: 24px;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.disqualified {
  background-color: cornsilk; }

.isIndorsed {
  background-color: #bee5eb; }

.isFlagged {
  background-color: #fa8072; }

.noBorder tr th {
  border-top: none; }
