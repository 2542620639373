.step {
  border: 1px solid #2552cb;
  border-radius: 5px;
  background-color: #f2f6fd;
  color: #2552cb;
  padding: 20px 10px;
  width: 375px;
  margin: auto;
  font-size: 18px; }
  .step .header {
    display: -ms-flexbox;
    display: flex; }
    .step .header .number {
      font-size: 16px;
      border: 1px solid #2552cb;
      border-radius: 50%;
      margin: 0 20px;
      padding: 10px 15px; }
    .step .header p {
      margin-top: 10px; }
  .step .details {
    font-size: 16px;
    line-height: 24px; }
  .step:hover {
    cursor: pointer; }
  .step span {
    margin: auto 0 auto 60px; }

.getStarted {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  margin: auto 0; }

.pageTitle {
  font-size: 42px;
  font-weight: 900;
  letter-spacing: -0.93px;
  line-height: 63px;
  text-align: center; }

.pageSubTitle {
  color: #464646;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  padding: 0 12rem; }
