.mobile-feature-boxes {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #97add0;
  border-radius: 4px; }
  .mobile-feature-boxes p {
    font-size: 16px;
    font-family: 'GT America Standard Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    /*padding: 0.3rem 0;*/ }
  .mobile-feature-boxes .heading {
    font-size: 24px !important;
    font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
    /*padding: 0.3rem 0;*/ }
  .mobile-feature-boxes .mute {
    color: #888888; }

.mobile-premium-box {
  border: 2px solid #2a2498;
  color: #2a2498 !important; }
  .mobile-premium-box .header {
    background: #2a2498;
    /*border-radius: 4px 4px 0 0;*/
    color: white; }
  .mobile-premium-box .mute {
    color: rgba(42, 36, 152, 0.53); }

.pricingTableDesktop .bold,
.pricingTableMobile .bold {
  font-family: 'GT America Extended Bold', 'Helvetica Neue', Helvetica, Arial, serif;
  color: #2a2498;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  margin-bottom: 8px; }
  .pricingTableDesktop .bold.premium,
  .pricingTableMobile .bold.premium {
    padding-top: 57px; }

.pricingTableDesktop .bold-black,
.pricingTableMobile .bold-black {
  font-family: 'GT America Extended Bold', 'Helvetica Neue', Helvetica, Arial, serif;
  color: #000;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 24px;
  text-align: center;
  padding-top: 33px;
  margin-bottom: 22px; }

.pricingTableDesktop .border-bottom,
.pricingTableMobile .border-bottom {
  border-bottom: 1px solid #dbe4f3; }

.pricingTableDesktop .emptyCell,
.pricingTableMobile .emptyCell {
  height: 30px; }

.pricingTableDesktop .abandonedCell,
.pricingTableMobile .abandonedCell {
  height: 100px; }

.pricingTableDesktop .halfAbandonedCell,
.pricingTableMobile .halfAbandonedCell {
  height: 50px; }

.pricingTableDesktop .quarterAbandonedCell,
.pricingTableMobile .quarterAbandonedCell {
  height: 25px; }

.pricingTableDesktop .perMonth,
.pricingTableMobile .perMonth {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, serif;
  font-size: 14px;
  color: #2a2498; }

.pricingTableDesktop .perMonthBlack,
.pricingTableMobile .perMonthBlack {
  font-family: 'GT America Standard Regular', 'Helvetica Neue', Helvetica, Arial, serif;
  font-size: 14px;
  color: #000; }

.pricingTableDesktop .standardPriceCellMobile,
.pricingTableMobile .standardPriceCellMobile {
  /* height: 260px !important;*/
  padding-bottom: 1rem; }

.pricingTableDesktop .standardPriceCell,
.pricingTableMobile .standardPriceCell {
  position: relative;
  height: 300px; }
  .pricingTableDesktop .standardPriceCell .promo,
  .pricingTableMobile .standardPriceCell .promo {
    position: absolute;
    z-index: 200;
    width: 70px;
    height: 70px;
    background-color: #00b1a4;
    top: -35px;
    right: -35px;
    border-radius: 35px;
    color: white;
    font-size: 14px;
    padding-top: 18px;
    text-align: center; }
  .pricingTableDesktop .standardPriceCell .superScript,
  .pricingTableMobile .standardPriceCell .superScript {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 1.5rem;
    color: #2a2498; }
  .pricingTableDesktop .standardPriceCell .priceText,
  .pricingTableMobile .standardPriceCell .priceText {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 4rem;
    line-height: 3rem;
    height: 3.6rem;
    color: #2a2498; }
  .pricingTableDesktop .standardPriceCell .strikeThrough,
  .pricingTableMobile .standardPriceCell .strikeThrough {
    position: relative; }
    .pricingTableDesktop .standardPriceCell .strikeThrough .dash,
    .pricingTableMobile .standardPriceCell .strikeThrough .dash {
      position: absolute;
      z-index: 200;
      width: 65px;
      height: 2px;
      left: -35%;
      top: 35%;
      background: #ed6630;
      -webkit-transform: rotate(-40.57deg);
          -ms-transform: rotate(-40.57deg);
              transform: rotate(-40.57deg); }
    .pricingTableDesktop .standardPriceCell .strikeThrough .superScript,
    .pricingTableMobile .standardPriceCell .strikeThrough .superScript {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 1rem;
      color: #7e7e80; }
    .pricingTableDesktop .standardPriceCell .strikeThrough .priceText,
    .pricingTableMobile .standardPriceCell .strikeThrough .priceText {
      position: relative;
      font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
      font-size: 3rem;
      line-height: 3rem;
      height: 4rem;
      color: #7e7e80; }

@media (max-width: 575px) {
  .pricingTableDesktop .standardPriceCell,
  .pricingTableMobile .standardPriceCell {
    height: auto; } }

.pricingTableDesktop .growthPriceCell,
.pricingTableMobile .growthPriceCell {
  position: relative;
  height: 300px; }
  .pricingTableDesktop .growthPriceCell .superScript,
  .pricingTableMobile .growthPriceCell .superScript {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 1rem;
    color: #000; }
  .pricingTableDesktop .growthPriceCell .priceText,
  .pricingTableMobile .growthPriceCell .priceText {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 4rem;
    line-height: 3rem;
    height: 3.6rem;
    color: #000; }

@media (max-width: 575px) {
  .pricingTableDesktop .growthPriceCell,
  .pricingTableDesktop .enterprisePriceCell,
  .pricingTableMobile .growthPriceCell,
  .pricingTableMobile .enterprisePriceCell {
    height: auto; } }

.pricingTableDesktop .enterpriseTitle,
.pricingTableMobile .enterpriseTitle {
  font-family: 'GT America Extended Bold';
  font-size: 18px; }
  @media (max-width: 575px) {
    .pricingTableDesktop .enterpriseTitle,
    .pricingTableMobile .enterpriseTitle {
      font-size: 25px; } }

.pricingTableDesktop .enterprisePriceCellMobile,
.pricingTableMobile .enterprisePriceCellMobile {
  /* height: 150px;*/
  text-align: center;
  padding-top: 2.4rem;
  padding-bottom: 1rem; }

.pricingTableDesktop .enterpriseBox,
.pricingTableMobile .enterpriseBox {
  border-top: 1px solid #dbe4f3;
  border-right: 1px solid #dbe4f3;
  border-bottom: 1px solid #dbe4f3; }

.pricingTableDesktop .enterpriseBoxMobile,
.pricingTableMobile .enterpriseBoxMobile {
  border: 2px solid #97add0;
  border-radius: 2px;
  padding-bottom: 2em; }

.pricingTableDesktop .growthPriceCell,
.pricingTableDesktop .enterprisePriceCell,
.pricingTableMobile .growthPriceCell,
.pricingTableMobile .enterprisePriceCell {
  height: 274px;
  text-align: center;
  padding-top: 0rem; }

.pricingTableDesktop .growthTitle,
.pricingTableMobile .growthTitle {
  font-family: 'GT America Extended Bold';
  font-size: 18px; }
  @media (max-width: 575px) {
    .pricingTableDesktop .growthTitle,
    .pricingTableMobile .growthTitle {
      font-size: 25px; } }

.pricingTableDesktop .growthPriceCellMobile,
.pricingTableMobile .growthPriceCellMobile {
  text-align: center;
  /* height: 260px !important;*/
  padding-bottom: 1rem; }
  .pricingTableDesktop .growthPriceCellMobile .superScript,
  .pricingTableMobile .growthPriceCellMobile .superScript {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 1rem;
    color: #000; }
  .pricingTableDesktop .growthPriceCellMobile .priceText,
  .pricingTableMobile .growthPriceCellMobile .priceText {
    font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, serif;
    font-size: 3rem;
    line-height: 2rem;
    height: 3rem;
    color: #000; }

.pricingTableDesktop .growthBox,
.pricingTableMobile .growthBox {
  border-top: 1px solid #dbe4f3;
  border-left: 1px solid #dbe4f3;
  border-bottom: 1px solid #dbe4f3; }

.pricingTableDesktop .growthBoxMobile,
.pricingTableMobile .growthBoxMobile {
  border: 2px solid #8c8f94;
  border-radius: 2px;
  padding-bottom: 2em; }

.pricingTableDesktop .standardCell,
.pricingTableMobile .standardCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid white;
  font-family: 'GT America Standard Medium', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif;
  color: #2a2498; }

.pricingTableDesktop .standardCellMobile,
.pricingTableMobile .standardCellMobile {
  height: 50px !important;
  border: none !important; }

.pricingTableDesktop .toggleCell,
.pricingTableMobile .toggleCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  font-family: 'GT America Standard Medium', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif;
  margin-top: 40px; }

.pricingTableDesktop .featureCell,
.pricingTableMobile .featureCell {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  height: 60px;
  font-size: 14px;
  font-weight: 500;
  background-color: #f7faff;
  border-top: 1px solid #dbe4f3;
  font-family: 'GT America Standard Medium', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif; }

.pricingTableDesktop .text-center,
.pricingTableMobile .text-center {
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTableDesktop .padded,
.pricingTableMobile .padded {
  padding-left: 2.5rem; }

.pricingTableDesktop .coloredCell,
.pricingTableMobile .coloredCell {
  background-color: #edf4ff; }

.pricingTable {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-height: calc(100vh - 54px); }
  .pricingTable h1 {
    margin: 0 auto 1rem; }
  .pricingTable h1,
  .pricingTable h2 {
    width: 100%; }

.lookBeyond {
  font-size: 20px; }

.image-style {
  -webkit-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16);
          box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.16); }

.topSection {
  background-color: #f3f3f3; }

.pricingTable-content {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-content-company-mobile {
  position: absolute;
  top: 0px;
  bottom: 0;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-form-container {
  margin-top: -54px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center; }

.pricingTable-scroll-indicator {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
          transform: translateX(-50%); }

.pricingTableCol {
  padding: 2rem 0 0 3rem; }

.typed-text {
  color: #838383; }

.subtitle-text {
  font-size: 20px !important;
  line-height: 26px !important; }
  @media (max-width: 575px) {
    .subtitle-text {
      font-size: 18px !important;
      line-height: 28px !important; } }

.title-left-margin {
  margin-left: 3rem !important; }
  @media (max-width: 575px) {
    .title-left-margin {
      margin-left: 0.25rem !important; } }

.margin-top-desktop {
  margin-top: 11em; }

.shapes {
  padding-left: 6.3em; }
  .shapes h3 {
    padding-top: 1rem !important;
    font-size: 18px;
    font-weight: 600; }

.pricingTableTitle {
  font-family: 'GT America Extended Regular';
  font-size: 32px;
  letter-spacing: 0px;
  line-height: 52px;
  margin-bottom: 0; }
  .pricingTableTitle .bold {
    font-family: 'GT America Extended Bold';
    color: #2a2498; }
  @media (max-width: 575px) {
    .pricingTableTitle {
      margin-top: 2.5em;
      width: 100%;
      font-size: 26px;
      letter-spacing: 0.19px;
      line-height: 37px; } }

.pricingTableSubTitle {
  font-family: 'GT America Standard Regular';
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 24px;
  text-align: center;
  max-width: 700px;
  margin: auto;
  margin-bottom: 1.5em; }
  @media (max-width: 575px) {
    .pricingTableSubTitle {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 22px;
      text-align: center;
      padding-left: 2%; } }

.signUpText {
  font-size: 28px;
  padding: 0.5em 1em 0 0;
  font-family: 'GT America Standard Bold';
  font-weight: bold;
  letter-spacing: 0.28px;
  line-height: 44px; }

.select-a-plan {
  font-family: 'GT America Expanded Regular';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px; }

.submitBtn {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  margin: auto 0;
  letter-spacing: 0.36px;
  line-height: 23px; }

.blueBackground {
  /*background-color: #eff3fe;*/
  background: -webkit-gradient(linear, left top, left bottom, from(#eff3fe), to(#ffffff));
  background: -webkit-linear-gradient(top, #eff3fe 0%, #ffffff 100%);
  background: -o-linear-gradient(top, #eff3fe 0%, #ffffff 100%);
  background: linear-gradient(180deg, #eff3fe 0%, #ffffff 100%); }

.pricingTable-company {
  padding-bottom: 20px; }
  .pricingTable-company h1 {
    margin: 0 auto 1rem; }
  .pricingTable-company h1,
  .pricingTable-company h2 {
    width: 120%;
    margin-left: -10%; }

.contactSales {
  font-family: 'GT America Light';
  font-size: 13px !important;
  text-decoration: underline;
  color: #2a2498;
  cursor: pointer; }

.table-styles .first-row {
  border-bottom: unset;
  height: auto; }

.table-styles tbody tr {
  height: 4.4em;
  border-bottom: unset; }

.table-styles td {
  font-family: 'GT America Standard Medium';
  vertical-align: middle;
  padding-right: 0; }

.table-styles td:first-child {
  padding-left: 2em;
  min-width: 400px; }

.table-styles .recommended {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 3px solid #2a2498;
  border-radius: 1px;
  background-color: #2a2498;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px; }

.table-styles td:nth-child(2),
.table-styles td:nth-child(4) {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 199px;
  border-left: 1px solid #97add0;
  border-right: 1px solid #97add0;
  text-align: center;
  font-size: 14px; }

.table-styles tr:first-child td:nth-child(2),
.table-styles tr:first-child td:nth-child(4) {
  border-top: 1px solid #97add0; }

.table-styles tr:last-child td:nth-child(2),
.table-styles tr:last-child td:nth-child(4) {
  border-bottom: 1px solid #97add0; }

.table-styles td:nth-child(3) {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 241px;
  border-radius: 1px;
  background-color: #ffffff;
  border-left: 3px solid #2a2498;
  border-right: 3px solid #2a2498;
  color: #2a2498;
  text-align: center; }

.table-styles tr:first-child td:nth-child(3) {
  border-top: 3px solid #2a2498; }

.table-styles tr:last-child td:nth-child(3) {
  border-bottom: 3px solid #2a2498; }

.table-styles tr:nth-child(even):nth-child(n + 3) {
  background-color: #edf4ff; }

.table-styles tr:nth-child(n + 3) td:nth-child(1),
.table-styles tr:nth-child(n + 3) td:nth-child(2),
.table-styles tr:nth-child(n + 3) td:nth-child(4) {
  border-bottom: 1px solid #dbe4f3; }

.table-styles .growth,
.table-styles .enterprise {
  font-family: 'GT America Expanded Regular';
  font-size: 18px !important;
  line-height: 24px;
  letter-spacing: 0; }

.table-styles .premium {
  font-family: 'GT America Expanded Bold';
  color: #2a2498;
  font-size: 25px;
  line-height: 24px;
  letter-spacing: 0; }

.table-styles .growth-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 21px;
  background-color: unset;
  color: #000000;
  font-weight: 900; }
  .table-styles .growth-free-trial:hover {
    background-color: #2a2498;
    border: none; }

.table-styles .premium-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-radius: 21px;
  color: #ffffff;
  background-color: #2a2498;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0.28px;
  line-height: 23px; }
  .table-styles .premium-free-trial:hover {
    background-color: #19146e; }

.table-styles .enterprise-free-trial {
  font-family: 'GT America Standard Medium';
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 2px solid #000000;
  border-radius: 21px;
  background-color: unset;
  color: #000000;
  font-weight: 900; }
  .table-styles .enterprise-free-trial:hover {
    background-color: #2a2498;
    border: none; }

.table-styles .growth-price {
  font-family: 'GT America Standard Bold';
  font-size: 26px !important; }
  .table-styles .growth-price span {
    font-family: 'GT America Standard Regular';
    font-size: 14px; }

.table-styles .premium-price {
  font-family: 'GT America Standard Bold';
  font-size: 34px; }
  .table-styles .premium-price span {
    font-family: 'GT America Standard Regular';
    font-size: 14px; }

.black-text {
  color: black !important; }

.check-icon {
  font-size: 1.3rem;
  color: #00b2a4; }
  @media (max-width: 600px) {
    .check-icon {
      font-size: 16px; } }

.times-icon {
  font-size: 1.3rem;
  color: #ed6630; }

.metamorph-pricing-heading {
  color: #28129d;
  font-family: 'GT America Standard Bold';
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 31px;
  text-transform: uppercase; }

.pricingTableSubTitleTwo {
  font-family: 'GT America Standard Regular';
  margin-left: -4%;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 31px;
  text-align: center; }
  @media (max-width: 575px) {
    .pricingTableSubTitleTwo {
      width: 100%;
      font-size: 16px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 22px;
      margin-left: 0px;
      text-align: start;
      padding-left: 2%; } }

.pricingTableButtonGetStarted {
  font-family: 'GT America Standard Medium';
  font-size: 18px;
  width: 168px;
  height: 42px;
  border-radius: 4px; }

.pricingTableButtonContactSales {
  font-family: 'GT America Standard Medium';
  font-size: 14px;
  height: 38px;
  border-radius: 4px;
  background-color: none; }

.lineThrough {
  text-decoration: line-through; }

.subTitleContainerMobile {
  display: -ms-flexbox;
  display: flex; }

.noCard {
  color: #000000;
  font-family: 'GT America Standard Regular';
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  text-align: center;
  margin-top: 6px; }

.description {
  width: 160px;
  font-family: 'GT America Standard Regular';
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  margin: 0 auto 20px; }

.grey {
  color: #6d7278; }

.blue {
  color: #28129d; }

.toggle-switch {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center; }
  .toggle-switch label {
    font-family: 'GT America Standard Medium' !important;
    font-size: 14px !important; }
