.card-wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 15px 15px;
  width: 100%; }
  .card-wrapper .card {
    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    border: none; }

.card-title {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 20px 25px; }
  .card-title.no-avatar {
    margin: 10px 25px; }
  .card-title .avatar {
    margin-right: 25px; }
  .card-title h2,
  .card-title .h2 {
    margin: 10px 20px 12px 0; }

.card-image-wrapper {
  margin-bottom: 25px; }
  .card-image-wrapper .card-image {
    width: 100%;
    height: auto; }

.card-footer {
  display: grid;
  grid-template-columns: 50% 15% 35%;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2; }
  .card-footer .label {
    justify-self: start; }
  .card-footer .icons {
    justify-self: end; }
