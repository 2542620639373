/*
	.classOne {
		background-color: #000;
	}
*/
.heading {
  color: #000000;
  font-size: 24px;
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 26px;
  /*font-weight: 600;*/ }

.quill-editor div {
  border: none !important;
  padding-top: 0px; }

.table-headers {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.bold-text {
  font-family: 'GT America Standard Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif; }

.tab-item:hover {
  cursor: pointer; }

.tab-item .hidden {
  height: 0.8rem;
  border-radius: 5px 5px 0 0; }

.tab-item .visible {
  background-color: white; }

.tab-item .tab-main {
  padding: 1rem;
  text-align: center;
  background-color: #dbe0e8;
  color: black;
  font-family: 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px; }
  .tab-item .tab-main:hover {
    cursor: pointer;
    background-color: #d0d3d9; }

.arrow {
  width: auto;
  margin-top: 0.8rem;
  background-color: #dbe0e8; }
  .arrow:hover {
    cursor: pointer;
    background-color: #d0d3d9; }

.tab-selected:hover {
  cursor: pointer; }

.tab-selected .hidden {
  height: 0.8rem;
  border-radius: 5px 5px 0 0;
  background-color: white; }

.tab-selected .tab-main {
  padding: 1rem;
  text-align: center;
  background-color: white;
  color: black;
  -webkit-transition: 0.01s ease-out;
  -o-transition: 0.01s ease-out;
  transition: 0.01s ease-out; }
  .tab-selected .tab-main:hover {
    cursor: pointer;
    background-color: white; }

.info-tab {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border: 1px solid #5243aa;
  border-radius: 10px;
  background-color: rgba(220, 217, 238, 0.6); }
  .info-tab .info {
    background-color: #5243aa;
    color: white;
    height: 22px;
    padding-top: 4px;
    width: 22px;
    border-radius: 11px;
    opacity: 1; }
  .info-tab .content p,
  .info-tab .content b {
    opacity: 1;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 28px;
    color: black; }

.engagement-stat h1 {
  font-family: 'GT America Expanded Bold', 'GT America Expanded Light', 'Helvetica Neue', Helvetica, Arial, serif;
  font-size: 32px;
  font-weight: 900;
  color: #2552cb; }

.engagement-stat h3 {
  font-family: 'GT America Expanded Medium', 'Helvetica Neue', Helvetica, Arial, serif;
  font-size: 16px;
  font-weight: 500; }

.bottom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem; }
