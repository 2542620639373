.home {
  display: none; }

.assessment-header {
  display: -ms-flexbox;
  display: flex; }

.assessment-details {
  display: -ms-flexbox;
  display: flex; }

.scrollCard {
  overflow-y: auto;
  height: 500px; }
  .scrollCard > div:nth-of-type(even) {
    background-color: #f8f9fb; }
