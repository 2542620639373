@media (max-width: 991.98px) {
  .bgImageSmall {
    background-image: url("./bg-small.png");
    background-size: 100% auto;
    height: 100vh; }
  .bgImageBig {
    background-image: url("./bg-big.png");
    background-size: 100% auto;
    height: 100vh; }
  .logo {
    padding-top: 1rem !important;
    padding-left: 0rem !important; }
  .bgMobile {
    background-image: url("./mobile_bg1.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    height: 70vh; } }

.page-content {
  min-height: 100vh; }

.bgImageSmall {
  background-image: url("./bg-small.png");
  background-size: 100% auto;
  height: 100%;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3); }

.bgImageBig {
  background-image: url("./bg-big.png");
  background-size: 100% auto;
  height: 100%;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3); }

.bgMobile {
  background-image: url("./mobile_bg1.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 70vh;
  -webkit-box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3);
          box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.3); }

.logo {
  padding-top: 20px;
  padding-left: 20px; }

.flash-wrapper {
  position: fixed;
  width: 100%;
  z-index: 1011; }
