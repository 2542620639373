/*
	.classOne {
		background-color: #000;
	}
*/
/*.table {*/
/*  overflow-x: auto;*/
/*  background: none;*/
/**/
/*  tr {*/
/*    background: none !important;*/
/*    border-bottom: 1px solid #f5f5f5;*/
/*  }*/
/*}*/
.scroll-card {
  width: 100%;
  overflow-y: auto;
  max-height: 700px;
  /*display: grid;*/
  /*grid-template-columns: 1fr 1fr 1fr;*/ }

.scroll-card::-webkit-scrollbar {
  width: 6px; }

.scroll-card::-webkit-scrollbar-track {
  /*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
  /*border-radius: 10px;*/
  background-color: #dae0e6; }

.scroll-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #aaaeb3; }
