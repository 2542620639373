/* TODO: might want to create new table component  just for the new styling int he future*/
.table thead th {
  border-bottom: unset;
  text-align: center; }

.table thead tr {
  text-align-last: unset; }

.table th,
.table td {
  border-top: unset; }
